import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';

@Component({
  selector: 'ui-carousel-items',
  standalone: true,
  templateUrl: './carousel-items.component.html',
  styleUrl: './carousel-items.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselItemsComponent implements OnChanges, OnInit, OnDestroy {
  private items = inject(ElementRef<CarouselItemsComponent>);
  private mutation = new MutationObserver(() => this.updateCollection());

  @Input() width!: number;
  @Input() index = 0;

  @Output() upliftCollection = new EventEmitter<Element[]>();

  get collection(): Element[] {
    return Array.from(this.items.nativeElement.children);
  }

  ngOnInit(): void {
    this.mutation.observe(this.items.nativeElement, { childList: true });
  }

  ngOnChanges(): void {
    this.updateCollection();
  }

  ngOnDestroy(): void {
    this.mutation.disconnect();
  }

  private updateCollection(): void {
    this.collection.forEach((item) => {
      const transition = 'transition: transform 300ms ease-in-out';
      const width = this.width + 'px';
      const x = this.width * this.index + 'px';
      item.setAttribute('style', `width: ${width}; transform: translateX(-${x}); ${transition}`);
    });

    this.upliftCollection.emit(this.collection);
  }
}
