import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultOptions } from 'apollo-client';
import { BaseApolloService } from './services/base-apollo.service';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({
      uri: `${environment.api.middleware}/graphql`,
      method: 'POST',
    }),
    cache: new InMemoryCache(),
    /* [TODO] Solve bug se-38
    but we need to investigate
    how to use an optimize
    cache policy in the future
    if needed */
    defaultOptions,
    connectToDevTools: environment.apolloDevTools,
  };
}

export const services = [BaseApolloService, Apollo];

@NgModule({
  providers: [
    ...services,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
