import { APP_LANGUAGES, DEFAULT_LANGUAGE } from './language.config';
import { LanguageCode, LanguageIso } from './language.model';

type LanguageType = LanguageCode | LanguageIso | string | null | undefined;

const findLanguage = (arg: LanguageType) =>
  APP_LANGUAGES.find((lang) => lang.iso === arg || lang.code === arg) ?? DEFAULT_LANGUAGE;

export const toCode = (arg: LanguageType): LanguageCode => findLanguage(arg).code;
export const toIso = (arg: LanguageType): LanguageIso => findLanguage(arg).iso;
