import { Injectable } from '@angular/core';
import { companyApiActions } from '@company/store/actions';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class CompanyNotificationEffects {
  readonly showUpdateCompanySuccessNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          companyApiActions.updateCompanySuccess,
          companyApiActions.createCompanyLocationSuccess,
          companyApiActions.updateCompanyLocationSuccess
        ),
        tap(() => this.notificationService.show('COMPANY_PAGE.PROFILE.MESSAGE.UPDATE'))
      );
    },
    { dispatch: false }
  );

  readonly showUpdateCompanyFailureNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          companyApiActions.updateCompanyFailure,
          companyApiActions.createCompanyLocationFailure,
          companyApiActions.updateCompanyLocationFailure
        ),
        tap(() =>
          this.notificationService.show('COMPANY_PAGE.PROFILE.MESSAGE.UPDATE_FAILED', {
            state: ActionState.Error,
          })
        )
      );
    },
    { dispatch: false }
  );

  readonly showDeleteCompanyLocationFailureNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          companyApiActions.deleteLocationFailure
        ),
        tap(() =>
          this.notificationService.show('COMPANY_PAGE.PROFILE.MESSAGE.DELETE_LOCATION_FAILURE', {
            state: ActionState.Error,
          })
        )
      );
    },
    { dispatch: false }
  );

  readonly showDeleteCompanyLocationSuccessNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          companyApiActions.deleteLocationSuccess
        ),
        tap(() =>
          this.notificationService.show('COMPANY_PAGE.PROFILE.MESSAGE.DELETE_LOCATION_SUCCESS')
        )
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: SnackbarService
  ) {}
}
