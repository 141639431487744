import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonColor, ButtonSize, ButtonWrapper, FeedbackModule } from '../../elements';
import { ModalConfig } from './modal.config';

@Component({
  selector: 'mds-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FeedbackModule, MatDialogModule, NgIf],
})
export class ModalComponent implements OnInit {
  config: ModalConfig;

  primaryConfig!: ButtonWrapper;
  secondaryConfig!: ButtonWrapper;
  tertiaryConfig!: ButtonWrapper;

  @Output() handleTertiaryClick = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) private modalData: ModalConfig
  ) {
    this.config = { ...this.modalData };
  }

  ngOnInit(): void {
    if (this.config) {
      this.setModal();
    }
  }

  cancel() {
    this.dialogRef.close({ confirm: false, dismiss: true });
  }

  confirm() {
    this.dialogRef.close({ confirm: true });
  }

  private setModal(): void {
    if (this.config.primaryLabel) {
      this.primaryConfig = {
        config: {
          size: ButtonSize.Medium,
          color: ButtonColor.Primary,
        },
        content: {
          label: this.config.primaryLabel,
        },
      };
    }

    if (this.config.secondaryLabel) {
      this.secondaryConfig = {
        config: {
          size: ButtonSize.Medium,
          color: ButtonColor.Secondary,
        },
        content: {
          label: this.config.secondaryLabel,
        },
      };
    }

    if (this.config.tertiaryLabel) {
      this.tertiaryConfig = {
        config: {
          size: ButtonSize.Medium,
          color: ButtonColor.Tertiary,
        },
        content: {
          label: this.config.tertiaryLabel,
        },
      };
    }
  }
}
