import { CompanyProfile } from '@company/models/company-profile.model';
import { ApiLink } from '@mkp/shared/data-access';
import { createAction, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models';

export const ActionTypes = {
  RESET_COMPANY_PROFILE: '[Company Profile] Reset Company Profile',
  COMPANY_GET_PROFILE_LIST: '[Company Profile] Companies Profile List',
  COMPANY_GET_PROFILE_LIST_SUCCEEDED: '[Company Profile] Companies Profile List Succeed',
  COMPANY_GET_PROFILE_LIST_FAILED: '[Company Profile] Companies Profile List Failed',
  COMPANY_LIST_MENU_ACTIVATE: '[Company List] Activate a company',
  COMPANY_LIST_MENU_ACTIVATE_SUCCEEDED: '[Company List] Company activation succeeded',
  COMPANY_LIST_MENU_ACTIVATE_FAILED: '[Company List] Company activation failed',
  COMPANY_LIST_MENU_DELETE: '[Company List] Delete a company',
  COMPANY_LIST_MENU_DELETE_SUCCEEDED: '[Company List] Company deletion succeeded',
  COMPANY_LIST_MENU_DELETE_FAILED: '[Company List] Company deletion failed',
};

// Reset Company Profile List
export const ResetCompanyProfile = createAction(ActionTypes.RESET_COMPANY_PROFILE);

// Get Company Profile List
export const CompanyGetProfileList = createAction(
  ActionTypes.COMPANY_GET_PROFILE_LIST,
  props<{ query?: QueryOptions }>()
);

export const CompanyGetProfileListSuccess = createAction(
  ActionTypes.COMPANY_GET_PROFILE_LIST_SUCCEEDED,
  props<{
    companyProfiles: CompanyProfile[];
    _links: Record<string, ApiLink>;
    filter: string;
    totalCount: number;
  }>()
);

export const CompanyGetProfileListFailure = createAction(
  ActionTypes.COMPANY_GET_PROFILE_LIST_FAILED,
  props<{ error: { message: string } }>()
);

// Company activation
export const CompanyActivate = createAction(
  ActionTypes.COMPANY_LIST_MENU_ACTIVATE,
  props<{ company: CompanyProfile }>()
);

export const CompanyActivateSuccess = createAction(
  ActionTypes.COMPANY_LIST_MENU_ACTIVATE_SUCCEEDED,
  props<{ companyId: string; legalEntityVersion: string }>()
);

export const CompanyActivateFailure = createAction(
  ActionTypes.COMPANY_LIST_MENU_ACTIVATE_FAILED,
  props<{ error: { message: string } }>()
);

// Company deletion
export const CompanyDelete = createAction(
  ActionTypes.COMPANY_LIST_MENU_DELETE,
  props<{ company: CompanyProfile }>()
);

export const CompanyDeleteSuccess = createAction(
  ActionTypes.COMPANY_LIST_MENU_DELETE_SUCCEEDED,
  props<{ companyId: string }>()
);

export const CompanyDeleteFailure = createAction(
  ActionTypes.COMPANY_LIST_MENU_DELETE_FAILED,
  props<{ error: { message: string } }>()
);
