import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { OperationVariables, WatchQueryFetchPolicy } from 'apollo-client';
import { FetchResult } from 'apollo-link';

@Injectable()
export class BaseApolloService {
  constructor(private apollo: Apollo) {}

  public query(query: DocumentNode, variables?: unknown): Observable<unknown> {
    return this.apollo.query({
      query,
      variables,
    });
  }

  public watchQuery(
    query: DocumentNode,
    variables?: OperationVariables,
    fetchPolicy: WatchQueryFetchPolicy = 'no-cache',
    errorPolicy: 'all' | 'none' | 'ignore' = 'none'
  ): Observable<unknown> {
    return this.apollo.watchQuery({
      query,
      variables,
      fetchPolicy,
      errorPolicy,
    }).valueChanges;
  }

  public mutate<T, V>(mutation: DocumentNode, variables?: V): Observable<FetchResult<T>> {
    return this.apollo.mutate<T, V>({
      mutation,
      variables,
    });
  }
}
