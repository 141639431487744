import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ButtonSvgIcon, StripHtmlPipe } from '@mkp/shared/ui-library';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'mkp-product-tooltip',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, TranslateModule, StripHtmlPipe],
  templateUrl: './product-tooltip.component.html',
  styleUrl: './product-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductTooltipComponent {
  @Input() text!: string;
  @Input() icon = ButtonSvgIcon.Information;

  iconName = 'info-icon';

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(this.iconName, sanitizer.bypassSecurityTrustResourceUrl(this.icon));
  }
}
