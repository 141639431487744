import { layoutActions } from '@mkp/layout/actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { TopHeader } from './layout.models';

export interface LayoutState {
  hasNavigation: boolean;
  isNavigationCollapsed: boolean | undefined;
  hasBorders: boolean;
  hasFooter: boolean;
  normalDocumentFlow: boolean;
  topHeader: TopHeader;
}
export const getInitialState: LayoutState = {
  hasNavigation: true,
  isNavigationCollapsed: undefined,
  hasBorders: true,
  normalDocumentFlow: false,
  hasFooter: true,
  topHeader: {
    hasTopHeader: true,
    isLight: false,
    leftBoxContent: [],
    rightBoxContent: [],
    name: undefined,
  },
};

const reducer = createReducer(
  getInitialState,
  on(layoutActions.resetLayout, () => getInitialState),
  on(
    layoutActions.updateLayoutElements,
    (
      state,
      {
        hasNavigation,
        hasBorders,
        hasTopHeader,
        isNavigationCollapsed,
        normalDocumentFlow,
        hasFooter,
      }
    ) => ({
      ...state,
      hasNavigation,
      hasBorders,
      isNavigationCollapsed,
      normalDocumentFlow,
      hasFooter,
      topHeader: {
        ...state.topHeader,
        hasTopHeader,
      },
    })
  ),
  on(layoutActions.showNavigation, (state) => ({
    ...state,
    hasNavigation: true,
  })),
  on(layoutActions.hideNavigation, (state) => ({
    ...state,
    hasNavigation: false,
  })),
  on(layoutActions.collapseNavigation, (state, { isCollapsed }) => ({
    ...state,
    isNavigationCollapsed: isCollapsed,
  })),
  on(layoutActions.toggleNavigation, (state) => ({
    ...state,
    isNavigationCollapsed: !state.isNavigationCollapsed,
  })),
  on(layoutActions.updateHeader, (state, { type: _type, ...props }) => ({
    ...state,
    topHeader: {
      ...props,
    },
  }))
);

export const layoutFeature = createFeature({ name: 'layoutState', reducer });
