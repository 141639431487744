import { FormControl } from '@angular/forms';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type FormGroupType<T> = {
  [key in keyof T]: FormControl<T[key]>;
};

export type FormGroupTypeOptional<T> = {
  [key in keyof T]: FormControl<T[key] | null>;
};

// typed Object.entries, use only if you're sure the object won't have excess properties
// read https://stackoverflow.com/a/60142095/7798324
export const objectEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;
export const objectKeys = <T extends object>(obj: T) => Object.keys(obj) as unknown as (keyof T)[];
