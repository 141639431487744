import { inject, Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterTraceService {
  private router = inject(Router);
  private currentGroupId: number | null = null;
  private eventStack: Event[] = [];

  constructor() {
    this.router.events.pipe(takeUntilDestroyed(), tap(this.handleEvent.bind(this))).subscribe();
  }

  private handleEvent(event: Event) {
    if (event instanceof NavigationStart) {
      this.currentGroupId = event.id;
      this.eventStack = [];
    }

    if ('id' in event && this.currentGroupId === event.id) {
      this.eventStack.push(event);

      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.flushEvents();
      }
    }
  }

  private flushEvents() {
    console.groupCollapsed(...this.getGroupName());
    this.eventStack.forEach(console.log);
    this.eventStack = [];
    console.groupEnd();
  }

  private getGroupName(): string[] {
    const lastEvent = this.eventStack[this.eventStack.length - 1];
    const url = 'url' in lastEvent && lastEvent.url;
    const reason = 'reason' in lastEvent && lastEvent.reason;

    return [
      `NavigationEvents to ${url} ${reason ? '%c(' + reason + ')' : ''}`,
      reason ? 'background: #fff3cd; color: black; padding: 2px 5px; border-radius: 3px' : '',
    ];
  }
}
