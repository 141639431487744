import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';

@Component({
  selector: 'ui-carousel-controls',
  standalone: true,
  imports: [NgIf, NgFor, AssetComponent, DataTestDirective],
  templateUrl: './carousel-controls.component.html',
  styleUrl: './carousel-controls.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselControlsComponent {
  @Input() index = 0;
  @Input() collection = [] as Element[];
  @Input() arrows = true;
  @Input() indicators = true;

  @Output() slideTo = new EventEmitter<number>();
}
