import { accountMembershipFeature as fromAccountMembership } from './account-membership.reducer';
import { User } from '@user/shared/models';
import { createSelector } from '@ngrx/store';
import { LoadingState } from '@mkp/shared/util-state';

export const {
  selectAll: selectAccountMemberships,
  selectEntities,
  selectTotalCount,
  selectLinks,
  selectFilter,
  selectLoaded,
  selectFeatureState,
  selectSelectedEntity: selectSelectedAccountMembership,
} = fromAccountMembership.selectors;

export const selectAccountMembershipByUserId = (userIdToFind: User['id']) =>
  createSelector(selectAccountMemberships, (memberships) =>
    memberships.find(({ userId }) => userId === userIdToFind)
  );

export const selectActiveAccountMembershipCount = createSelector(
  selectFeatureState,
  (state) => state.totalCount
);

export const selectHasActiveAccountMembership = createSelector(
  selectActiveAccountMembershipCount,
  (activeAccountMembershipCount) => !!activeAccountMembershipCount
);

export const selectAccountMembershipIsLoading = createSelector(
  selectFeatureState,
  (state) => state.fetchState === LoadingState.LOADING
);
