import { CompanyDto } from '@mkp/company/data-access';
import { CompanyApiActions } from '@mkp/company/state/actions';
import { RoutingFactoryActions } from '@mkp/layout/shell-user-layout/actions';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { FeatureDashboardActions } from '@mkp/user/feature-dashboard/actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { legalEntityDetailsActions } from '@mkp/legal-entity/actions';

export interface CompanyState extends EntityState<CompanyDto> {
  selectedId: string | null;
  fetchState: FetchState;
}

const adapter = createEntityAdapter<CompanyDto>();

export const initialState: CompanyState = adapter.getInitialState({
  selectedId: null,
  fetchState: LoadingState.INIT,
});

const reducer = createReducer(
  initialState,
  on(
    RoutingFactoryActions.enter,
    FeatureDashboardActions.enter,
    CompanyApiActions.loadCompanies,
    legalEntityDetailsActions.pageEntered,
    (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
    })
  ),
  on(CompanyApiActions.loadCompaniesSuccess, (state, { companies, selectedId }) =>
    adapter.setAll(companies, {
      ...state,
      selectedId: selectedId || null,
      fetchState: LoadingState.LOADED,
    })
  ),
  on(legalEntityDetailsActions.companiesLoadSuccess, (state, { companies }) =>
    adapter.setAll(companies, {
      ...state,
      selectedId: companies[0]?.id || null,
      fetchState: LoadingState.LOADED,
    })
  ),
  on(
    CompanyApiActions.loadCompaniesFailed,
    legalEntityDetailsActions.companiesLoadFailure,
    (_, { error }) => ({
      ...initialState,
      error,
    })
  )
);

const feature = createFeature({ name: 'company', reducer });
const { selectAll, selectTotal } = adapter.getSelectors(feature.selectCompanyState);

export const companyFeature = { ...feature, selectAll, selectTotal };
