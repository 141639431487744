import { Action } from '@ngrx/store';
import { Benefit } from '@shared/models/benefit.model';

/***** models  ****/

export const ActionTypes = {
  GET_BENEFITS_LIST: '[Benefits] Benefits List',
  GET_BENEFITS_LIST_SUCCEED: '[Benefits] Benefits List Succeed',
  GET_BENEFITS_LIST_FAILED: '[Benefits] Benefits List Failed',
} as const;

/************* Get Benefits List ***************/

export class GetBenefitsList implements Action {
  readonly type = ActionTypes.GET_BENEFITS_LIST;
}

export class GetBenefitsListSuccess implements Action {
  readonly type = ActionTypes.GET_BENEFITS_LIST_SUCCEED;
  constructor(public payload: Benefit[]) {}
}

export class GetBenefitsListFailure implements Action {
  readonly type = ActionTypes.GET_BENEFITS_LIST_FAILED;
  constructor(public payload: Record<string, unknown>) {}
}

export type Actions = GetBenefitsList | GetBenefitsListSuccess | GetBenefitsListFailure;
