import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CreditRedemptionStateModule } from '@mkp/publication/feature-credit-status/state';
import { LabelComponent } from '@mkp/shared/ui-library';
import { TranslateModule } from '@ngx-translate/core';
import { CreditRedemptionStatusComponent } from './credit-redeem-status/credit-redeem-status.component';

@NgModule({
  imports: [
    CommonModule,
    LabelComponent,
    CreditRedemptionStateModule,
    TranslateModule,
    CreditRedemptionStatusComponent,
  ],
  exports: [CreditRedemptionStatusComponent],
})
export class PublicationFeatureCreditStatusModule {}
