<ng-content />

<ng-container *ngIf="products; else skeleton">
  <div class="wrapper">
    <mkp-product-comparison-table-column-header
      *ngFor="let item of [].constructor(2); let i = index"
      [products]="products"
      [isProductLoading]="isProductLoading"
      [isReadMode]="isReadMode"
      [defaultSelectedProductCode]="defaultSelectedProductCodes[i]"
      (handleSelectedProduct)="handleSelectedProduct.emit($event)"
      (handleChosenProduct)="chosenProduct($event, i)"
    />
  </div>

  <span class="title-lead feature-title">{{ 'PRODUCT_OFFERING.FEATURES' | translate }}</span>

  <ng-container *ngIf="selectedProducts?.length">
    <!-- calendar feature -->
    <mkp-product-comparison-table-features
      [products]="selectedProducts"
      [feature]="calendarFeature"
    />

    <!-- platforms -->
    <mkp-product-comparison-table-features
      *ngFor="let platform of platforms"
      [products]="selectedProducts"
      [feature]="platform"
    />

    <!-- benefits -->
    <mkp-product-comparison-table-features
      *ngFor="let benefit of benefits"
      [products]="selectedProducts"
      [feature]="benefit"
    />
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <mkp-product-table-skeleton *ngFor="let item of [].constructor(4)" />
</ng-template>
