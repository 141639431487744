import { Injectable } from '@angular/core';
import { CartDto, CartItemDto } from '@cart/models/cart.dto';
import { Cart, CartItem } from '@cart/models/cart.model';
import { PRODUCT_TRANSLATIONS, ProductCode } from '@mkp/shared/util-product';

@Injectable({ providedIn: 'root' })
export class CartMapper {
  mapCart = (cartDto: CartDto): Cart => ({
    ...cartDto,
    items: cartDto?.items?.map(this.mapItem) ?? [],
  });

  private mapItem = (cartItemDto: CartItemDto): CartItem => ({
    ...cartItemDto,
    longname: PRODUCT_TRANSLATIONS[cartItemDto.productCode as ProductCode]?.longname ?? '',
  });
}
