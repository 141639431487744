import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AppVersion } from '@app/shared/models/app-version.model';
import { environment } from '@env/environment';
import { Environment } from '@env/environment.model';
import {
  LabelColor,
  LabelComponent,
  LabelConfig,
  SnackbarModule,
  SnackbarService,
} from '@mkp/shared/ui-library';

@Component({
  standalone: true,
  selector: 'mkp-version',
  templateUrl: './version.component.html',
  styleUrl: './version.component.scss',
  imports: [CommonModule, LabelComponent, ClipboardModule, SnackbarModule],
})
export class VersionComponent {
  private readonly snackbarService = inject(SnackbarService);
  readonly version = getVersion(environment.version);
  readonly environmentName = environment.name;
  readonly labelConfig: LabelConfig = { color: getLabelColor(environment.name) };

  onCopied() {
    this.snackbarService.show('Copied to clipboard');
  }
}

const getLabelColor = (environmentName: Environment['name']): LabelColor =>
  environmentName === 'dev' ? 'orange' : 'red';
const getVersion = (environmentVersion: Environment['version']): AppVersion | 'local' =>
  environmentVersion === 'v0.0.0' ? 'local' : environment.version;
