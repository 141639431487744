import { Injectable, TrackByFunction } from '@angular/core';

// Helper function to calculate the difference in months between two dates
export const monthDiff = (date1: Date, date2: Date): number => {
  const years = date2.getFullYear() - date1.getFullYear();
  const months = date2.getMonth() - date1.getMonth();
  const yearMonthDiff = years * 12 + months;
  return Math.abs(yearMonthDiff);
};

@Injectable({
  providedIn: 'root',
})
export class UtilsHelpers {
  /* Return all string after a specific ccaractere */
  stringAfter(str: string, chart: string) {
    /* transofrm in strin */
    str = str + '';
    return str.substring(0, str.indexOf(chart));
  }

  // Create a string of a random alphanumeric characters, of a given length
  createRandomString(strLength = 20) {
    const possibleCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789*#[]%()$ABCDEFGHIJKLMNOPQRST';

    let str = '';
    for (let i = 0; i < strLength; i++) {
      const randomCharacter = possibleCharacters.charAt(
        Math.floor(Math.random() * possibleCharacters.length)
      );

      str += randomCharacter;
    }

    return str as string;
  }

  public trackByFn<T extends { id: string | number }>(
    index: number,
    item: T
  ): ReturnType<TrackByFunction<T>> {
    return item?.id ?? index;
  }

  removeKeys(object: any, keys: string[]): any {
    return Object.keys(object).reduce(
      (acc, key) => (keys.includes(key) ? acc : { ...acc, [key]: object[key] }),
      {}
    );
  }
}
