import { EventActionType, EventFunnelType } from '@mkp/tracking/feature-tealium';

type Events = Map<number, EventActionType[]>;

/**
 * funnelStep from https://jobcloud.atlassian.net/l/cp/1fHZmKAM
 */
const funnelReference: { funnel: EventFunnelType[]; events: Events }[] = [
  {
    funnel: ['user_login'],
    events: new Map([
      [1, ['user_login_start']],
      [2, ['user_login']],
    ]),
  },
  {
    funnel: ['user_registration'],
    events: new Map([
      [1, ['user_registration_start', 'user_registration', 'marketing_consent']],
      [2, ['email_verification_sent', 'email_verification_re-sent']],
      [3, ['email_open', 'email_clicked']],
      [4, ['user_signup', 'user_company_claim_start', 'email_verified']],
    ]),
  },
  {
    funnel: ['job_ad_create', 'ecommerce', 'ecommerce_upsell'], // what's ecommerce ??
    events: new Map([
      [0, ['job_ad_create_start']],
      [1, ['job_ad_create_step', 'job_ad_create_cancel']],
      [2, ['job_ad_create_step']],
      [3, ['job_ad_create_step', 'ecommerce_product_list_view', 'ecommerce_cart_add']],
      [
        4,
        [
          'job_ad_publish',
          'ecommerce_purchase',
          'ecommerce_credit_used',
          'ecommerce_cart_remove',
          'ecommerce_checkout_step',
        ],
      ],
    ]),
  },
  {
    funnel: ['ecommerce_credit_purchase'],
    events: new Map([
      [1, ['ecommerce_product_list_view', 'ecommerce_cart_add']],
      [2, ['ecommerce_purchase']],
      [4, ['ecommerce_credit_used']],
    ]),
  },
  {
    funnel: ['job_ad_edit'],
    events: new Map([
      [1, ['job_ad_list_view', 'job_ad_update_start', 'job_ad_deactivated', 'job_ad_reactivated']],
      [2, ['job_ad_detail_view', 'job_ad_update']],
    ]),
  },
];

export const getFunnelStep: ({
  funnel,
  action,
}: {
  funnel: EventFunnelType;
  action: EventActionType;
}) => number | undefined = ({ funnel, action }) => {
  const events: Events | undefined = funnelReference.find(({ funnel: f }) =>
    f.includes(funnel)
  )?.events;
  if (events) {
    for (const [step, actions] of events) {
      if (actions.includes(action)) {
        return step;
      }
    }
  }
  return undefined;
};
