import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { SendVerificationEmailBody } from '../dtos/send-verification-email.dto';
import { SendVerificationEmailMapper } from '../mappers/send-verification-email';

@Injectable({
  providedIn: 'root',
})
export class SendVerificationEmailApi {
  constructor(
    private http: HttpClient,
    private mapper: SendVerificationEmailMapper
  ) {}

  sendVerificationEmail(userId: string): Observable<void> {
    const body: SendVerificationEmailBody = this.mapper.toBody(userId);
    return this.http.post<void>(
      `${environment.api.core}/user/${userId}/send-verification-email`,
      body
    );
  }
}
