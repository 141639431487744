import * as fromCompany from '@company/store/reducers/company-profile.reducer';
import { selectAccountById } from '@mkp/account/state';
import { UUID } from '@mkp/shared/data-access';
import { createFetchStateSelectors } from '@mkp/shared/util-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Location } from '@shared/models';
import { selectRouteParam } from '@store/selectors';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { CompanyState, featureKey } from '../reducers';

const { selectLoading, selectLoaded } = createFetchStateSelectors<fromCompany.CompanyState>();
const selectCompanyState = createFeatureSelector<CompanyState>(featureKey);
export const selectEntities = createSelector(selectCompanyState, ({ profiles }) => profiles);

export const selectTotalCount = createSelector(selectCompanyState, (state) => state.totalCount);
export const selectLinks = createSelector(selectCompanyState, (state) => state._links);
export const selectFilter = createSelector(selectCompanyState, (state) => state.filter);

export const selectCompanyById = (id: string) =>
  createSelector(selectEntities, (companyEntities) => companyEntities[id]);
export const selectCompanyFromRoute = createSelector(
  selectEntities,
  selectRouteParam('companyId'),
  (entities, companyId) => (companyId ? entities[companyId] : undefined)
);
export const selectCompanies = createSelector(selectEntities, (entities) =>
  Object.values(entities)
);
export const selectCompaniesLoaded = createSelector(selectCompanyState, (state) =>
  selectLoaded(state)
);
export const selectCompaniesLoading = createSelector(selectCompanyState, (state) =>
  selectLoading(state)
);
export const selectSelectedCompany = createSelector(
  selectCompanyState,
  ({ profiles, selectedId }) => (selectedId ? profiles[selectedId] : undefined)
);
export const selectSelectedCompanyId = createSelector(
  selectCompanyState,
  ({ selectedId }) => selectedId
);
export const selectCompanyLocationsFromRoute = createSelector(
  selectCompanyFromRoute,
  (company): Location[] => company?.locations ?? []
);
const selectCompaniesByAccountId = (accountId: string) =>
  createSelector(selectAccountById(accountId), selectCompanies, (account, companies) =>
    companies.filter(({ legalEntityId }) => account?.legalEntity.id === legalEntityId)
  );
export const selectFirstCompanyByAccountId = (accountId: string) =>
  createSelector(selectCompaniesByAccountId(accountId), (companies) => companies[0]);

export const selectCompanyVideosOfFirstCompany = createSelector(
  selectCompanies,
  (companies) => companies[0]?.videos ?? []
);

export const selectDefaultCompanyLogoUrl = createSelector(
  selectCompanyState,
  ({ profiles, selectedId }) => (selectedId ? profiles[selectedId]?.logoMediaApiId : undefined)
);
export const selectBenefitsByCompanyId = (id: UUID) =>
  createSelector(selectCompanyById(id), (company): UUID[] => (company ? company['benefits'] : []));

export const selectCompanyByVacancy = (vacancy: Vacancy) =>
  createSelector(selectCompanies, (companies) => {
    return companies.find((company) =>
      company.locations?.find((location) => location.id === vacancy.locationId)
    );
  });
