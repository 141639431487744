import { createActionGroup, props } from '@ngrx/store';
import { LegalEntity } from '@mkp/legal-entity/data-access';
import { Account } from '@mkp/account/data-access';
import { CompanyDto } from '@mkp/company/data-access';

export const legalEntityDetailsActions = createActionGroup({
  source: 'Legal Entity Details',
  events: {
    'Page Entered': props<{ legalEntityId: string }>(),
    'Legal Entity Set Selected': props<{ legalEntity: LegalEntity }>(),
    'Legal Entity Load Success': props<{ legalEntity: LegalEntity }>(),
    'Legal Entity Load Failure': props<{ error: { message: string } }>(),
    'Load Accounts': props<{ legalEntity: LegalEntity }>(),
    'Accounts Load Success': props<{ accounts: Account[] }>(),
    'Accounts Load Failure': props<{ error: { message: string } }>(),
    'Companies Load Success': props<{ companies: CompanyDto[] }>(),
    'Companies Load Failure': props<{ error: { message: string } }>(),
    'Membership Per Account Count Load Success': props<{
      membershipPerAccountCount: Record<Account['id'], number>;
    }>(),
    'Membership Per Account Count Load Failure': props<{ error: { message: string } }>(),
  },
});
