import { inject, Injectable } from '@angular/core';
import {
  Product,
  PRODUCT_PLATFORM_GROUP_LOGOS,
  PRODUCT_PLATFORM_GROUP_TRANSLATIONS,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ScrollDirection,
} from '@mkp/shared/util-product';
import { Store } from '@ngrx/store';
import { productOptionsPageActions } from '@product-options/store/actions';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AbstractProductDisplayService {
  readonly store = inject(Store);
  readonly logos = PRODUCT_PLATFORM_GROUP_LOGOS;
  readonly translations = PRODUCT_PLATFORM_GROUP_TRANSLATIONS;

  abstract readonly activePlatformGroup$: Observable<ProductPlatformGroup>;
  abstract readonly benefits$: Observable<ProductOfferingBenefit[]>;
  abstract readonly displayedProducts$: Observable<Product[]>;
  abstract readonly features$: Observable<ProductOfferingFeature[]>;
  abstract readonly products$: Observable<Product[]>;
  abstract readonly platforms$: Observable<ProductOfferingPlatform[]>;
  abstract readonly switchedProducts$: Observable<Product[]>;
  abstract readonly freeProduct$: Observable<Product | null | undefined>;

  handleSwitchPlatformGroup(platformGroup: ProductPlatformGroup): void {
    this.store.dispatch(productOptionsPageActions.switchPlatformGroup({ platformGroup }));
  }

  handleProductsScroll(direction: ScrollDirection): void {
    this.store.dispatch(productOptionsPageActions.scrollProducts({ direction }));
  }
}
