import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { AuthFacade } from '@mkp/auth/util';
import { SplashscreenService } from '@core/services';

@Component({
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  private readonly auth = inject(AuthFacade);
  private readonly splashscreen = inject(SplashscreenService);

  ngOnInit() {
    this.splashscreen.show();
    this.auth.logout();
  }
}
