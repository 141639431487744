import { Injectable } from '@angular/core';
import { AccountResource } from '@mkp/account/data-access';
import { UUID } from '@mkp/shared/data-access';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { VacancyService } from '@vacancy/services';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VacancyResource {
  constructor(
    private readonly vacancyApi: VacancyService,
    private readonly accountResource: AccountResource
  ) {}

  getVacancy(vacancyId: UUID): Observable<Vacancy> {
    const vacancy$ = this.vacancyApi
      .read(vacancyId)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    const accountDto$ = vacancy$.pipe(
      mergeMap(({ accountId }) => this.accountResource.getById(accountId))
    );

    return forkJoin([vacancy$, accountDto$]).pipe(
      map(([vacancy, accountDto]) => ({ ...vacancy, companyName: accountDto.name }))
    );
  }

  deleteVacancy(vacancyId: UUID): Observable<string> {
    return this.vacancyApi.delete(vacancyId).pipe(map(() => vacancyId));
  }
}
