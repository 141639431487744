import { state, style, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
} from '@angular/core';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { GdprLinkPipe } from '../gdpr-link/gdpr-link.pipe';
import { ngIfTopUpAnimationSGdprBanner } from '@mkp/shared/ui-animations';

enum Status {
  Show = 'show',
  Hide = 'hide',
}

@Component({
  selector: 'ui-gdpr-banner',
  standalone: true,
  templateUrl: './gdpr-banner.component.html',
  styleUrl: './gdpr-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslateModule, GdprLinkPipe, DataTestDirective],
  animations: [
    trigger('show', [
      state(
        Status.Show,
        style({
          display: 'flex',
        })
      ),
      state(
        Status.Hide,
        style({
          display: 'none',
        })
      ),
    ]),
    ngIfTopUpAnimationSGdprBanner,
  ],
})
export class GdprBannerComponent implements OnInit {
  @Output() closeBanner = new EventEmitter<void>();
  @Output() showBanner = new EventEmitter<void>();

  public status = Status.Show;
  readonly gdprBtnConfig: Button = {
    color: ButtonColor.Primary,
    size: ButtonSize.Small,
  };

  ngOnInit() {
    this.showBanner.emit();
  }

  close() {
    this.closeBanner.emit();
    this.status = Status.Hide;
  }

  @HostBinding('@topUpAnimation') get inOutAnimation() {
    return;
  }
}
