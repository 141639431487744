import { Injectable } from '@angular/core';
import { SendVerificationEmailBody } from '../dtos/send-verification-email.dto';

@Injectable({ providedIn: 'root' })
export class SendVerificationEmailMapper {
  toBody = (userId: string): SendVerificationEmailBody => ({
    id: userId,
    Accept: 'application/json',
  });
}
