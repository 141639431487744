import { ProductCode } from './product.model';

export const isProductCode = (value: unknown): value is ProductCode =>
  Object.values(ProductCode).includes(value as ProductCode);

export const JOBS_PAID_PRODUCT_CODES: ProductCode[] = [
  ProductCode.JOBSOFFER22XS,
  ProductCode.JOBSOFFER22S,
  ProductCode.JOBSOFFER22M,
  ProductCode.JOBSOFFER22L,
  ProductCode.JOBSOFFER22XL,
];
export const JOBS_PRODUCT_CODES: ProductCode[] = [
  ProductCode.JOBSOFFER22FREE,
  ...JOBS_PAID_PRODUCT_CODES,
];

export const JS24_PAID_PRODUCT_CODES: ProductCode[] = [
  ProductCode.JS24SOLO,
  ProductCode.JS24PLUS,
  ProductCode.JS24BASIC,
  ProductCode.JS24ADVANCED,
];
export const JS24_PRODUCT_CODES: ProductCode[] = [ProductCode.JS24FREE, ...JS24_PAID_PRODUCT_CODES];

export const JOBUP_PAID_PRODUCT_CODES: ProductCode[] = [
  ProductCode.JOBUP24LITE,
  ProductCode.JOBUP24BASIC,
  ProductCode.JOBUP24ADVANCED,
  ProductCode.JOBUP24ULTIMATE,
  ProductCode.JOBUP24ULTIMATEPLUS,
];

export const JOBUP_PRODUCT_CODES: ProductCode[] = [
  ProductCode.JOBUP24FREE,
  ...JOBUP_PAID_PRODUCT_CODES,
];

export const PAID_PRODUCT_CODES: ProductCode[] = [
  ...JOBS_PAID_PRODUCT_CODES,
  ...JS24_PAID_PRODUCT_CODES,
  ...JOBUP_PAID_PRODUCT_CODES,
];

export type ProductGroup = 'jobs' | 'jobs24' | 'jobup';

// maps groups of products and defines sorting order
export const PRODUCT_GROUP_MAP: { [key in ProductGroup]: Array<ProductCode[keyof ProductCode]> } = {
  jobs24: ['JS24FREE', 'JS24SOLO', 'JS24PLUS', 'JS24BASIC', 'JS24ADVANCED'],
  jobs: ['JOBSOFFER22FREE', 'JOBSOFFER22XS', 'JOBSOFFER22S'],
  jobup: [
    'JOBUP24LITE',
    'JOBUP24BASIC',
    'JOBUP24ADVANCED',
    'JOBUP24ULTIMATE',
    'JOBUP24ULTIMATEPLUS',
  ],
};

// we show a link for jobs.ch if the product is one of the following
export const hasJobsLink = (productCode?: ProductCode): boolean => {
  return Boolean(
    productCode &&
      [
        ProductCode.JS24PLUS,
        ProductCode.JS24ADVANCED,
        ProductCode.JOBSOFFER22XS,
        ProductCode.JOBSOFFER22S,
        ProductCode.JOBSOFFER22M,
        ProductCode.JOBSOFFER22L,
        ProductCode.JOBSOFFER22XL,
        ProductCode.JOBSSTANDARD,
        ProductCode.JOBSOFFER22FREE,
        ProductCode.JOBUP24ADVANCED,
        ProductCode.JOBUP24ULTIMATE,
        ProductCode.JOBUP24ULTIMATEPLUS,
      ].includes(productCode)
  );
};

// we show a link for jobscout24.ch if the product is one of the following
export const hasJs24Link = (productCode?: ProductCode): boolean => {
  return Boolean(
    productCode &&
      [
        ProductCode.JS24FREE,
        ProductCode.JS24SOLO,
        ProductCode.JS24PLUS,
        ProductCode.JS24BASIC,
        ProductCode.JS24ADVANCED,
        ProductCode.JOBSOFFER22M,
        ProductCode.JOBSOFFER22L,
        ProductCode.JOBSOFFER22XL,
        ProductCode.JOBSSTANDARD,
        ProductCode.JOBUP24ADVANCED,
        ProductCode.JOBUP24ULTIMATE,
        ProductCode.JOBUP24ULTIMATEPLUS,
      ].includes(productCode)
  );
};

export const hasJobupLink = (productCode?: ProductCode): boolean => {
  return Boolean(
    productCode &&
      [
        ProductCode.JOBUP24FREE,
        ProductCode.JOBUP24LITE,
        ProductCode.JOBUP24BASIC,
        ProductCode.JOBUP24ADVANCED,
        ProductCode.JOBUP24ULTIMATE,
        ProductCode.JOBUP24ULTIMATEPLUS,
      ].includes(productCode)
  );
};
