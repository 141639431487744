export interface Metadata {
  id: number | string;
  language?: string;
  text: string;
}

export enum MetadataType {
  EducationLevel = 'educationLvl',
  SeniorityLevel = 'seniorityLvl',
  ContractType = 'contractType',
  Language = 'language',
  LanguageLevel = 'languageLvl',
}
export interface MetadataDictionary {
  [k: string]: Metadata[];
}

export const isMetadata = (object: unknown): object is Metadata =>
  (object as Metadata)?.id !== undefined && (object as Metadata)?.text !== undefined;

export const sortMetadataDictionary = (entities: MetadataDictionary): MetadataDictionary => {
  return Object.entries(entities).reduce((acc, [type, metadatas]) => {
    const sortFunction = getSortingFunction(type as MetadataType);

    return {
      ...acc,
      [type]: sortFunction ? [...metadatas].sort(sortFunction) : metadatas,
    };
  }, {} as MetadataDictionary);
};

type MetadataSortFunction = (l1: Metadata, l2: Metadata) => number;
const sortLanguage: MetadataSortFunction = (l1, l2) =>
  getLanguageOrder(l2) - getLanguageOrder(l1) || l1.text.localeCompare(l2.text);
// JMP-4462: display a few languages before the others (left side displayed first, right side displayed last)
const languageOrders = ['de', 'en', 'fr', 'it'].reverse();
const getLanguageOrder = (language: Metadata): number => {
  return languageOrders.findIndex((languageOrder) => languageOrder === language.id) ?? 0;
};
const sortLanguageLevel: MetadataSortFunction = (l1, l2) => Number(l2.id) - Number(l1.id);
const getSortingFunction = (type: MetadataType): MetadataSortFunction | null => {
  switch (type) {
    case MetadataType.Language: {
      return sortLanguage;
    }
    case MetadataType.LanguageLevel: {
      return sortLanguageLevel;
    }
    default: {
      return null;
    }
  }
};
