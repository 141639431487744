import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionState } from '../../../elements';
import { createFeedbackClass } from '../feedback.config';

@Component({
  selector: 'ui-feedback-progress-bar',
  templateUrl: './feedback-progress-bar.component.html',
  styleUrl: './feedback-progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackProgressBarComponent {
  @Input()
  get state(): ActionState {
    return this._state;
  }
  set state(state: ActionState) {
    this.setState(state);

    this._state = state;
  }
  @Input() showAnimation!: boolean;
  @Input() duration!: number;
  @Input() color: string | undefined;

  feedbackTypeClass!: string;

  private _state!: ActionState;

  private setState(state: ActionState) {
    this.feedbackTypeClass = createFeedbackClass(state);
  }
}
