import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductOfferingFeatureCell } from '@mkp/shared/util-product';
import { ButtonSvgIcon } from '@mkp/shared/ui-library';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mkp-product-feature-cell',
  standalone: true,
  imports: [NgIf, MatIconModule, TranslateModule],
  templateUrl: './product-feature-cell.component.html',
  styleUrl: './product-feature-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFeatureCellComponent {
  @Input() feature!: ProductOfferingFeatureCell;

  readonly checkIconUrl = ButtonSvgIcon.Check;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('check', sanitizer.bypassSecurityTrustResourceUrl(this.checkIconUrl));
  }
}
