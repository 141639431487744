import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { SelectConfig } from './select.config';

@Component({
  selector: 'mds-select',
  templateUrl: 'select.template.html',
  styleUrls: ['../input-wrapper/input-wrapper.template.scss', './select.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input() config!: SelectConfig;

  private formControl?: AbstractControl | null;

  constructor(@Optional() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  onChange!: (_: unknown) => void;
  onTouched!: () => void;

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (value !== this.ngControl?.control?.value) {
      this.ngControl?.control?.patchValue(value);
    }
  }

  ngOnInit(): void {
    this.formControl = this.ngControl?.control;
  }
}
