import { Directive, inject } from '@angular/core';
import { ProductPlatformGroup, ScrollDirection } from '@mkp/shared/util-product';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import { AbstractProductDisplayService } from './abstract-product-display.service';

/**
 * This is an abstract directive made to reduce boilerplate code
 * for those component that need to display the product comparison table.
 * Different implementations of the AbstractProductDisplayService should be injected
 * in those components in order to provide different data
 * */
@Directive({ standalone: true })
export abstract class AbstractProductDisplayDirective {
  protected readonly displayService = inject(AbstractProductDisplayService);
  protected readonly isMobile$ = inject(BreakpointObserverService).isMobile$;
  protected readonly displayedProducts$ = this.displayService.displayedProducts$;
  protected readonly activePlatformGroup$ = this.displayService.activePlatformGroup$;
  protected readonly platforms$ = this.displayService.platforms$;
  protected readonly benefits$ = this.displayService.benefits$;
  protected readonly switchedProducts$ = this.displayService.switchedProducts$;
  protected readonly products$ = this.displayService.products$;
  protected readonly logos = this.displayService.logos;
  protected readonly freeProduct$ = this.displayService.freeProduct$;

  handleSwitchPlatformGroup(event: ProductPlatformGroup): void {
    this.displayService.handleSwitchPlatformGroup(event);
  }

  handleProductsScroll(direction: ScrollDirection): void {
    this.displayService.handleProductsScroll(direction);
  }
}
