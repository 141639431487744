<span
  *ngFor="let item of items"
  class="loader"
  aria-busy="true"
  aria-valuemin="0"
  aria-valuemax="100"
  role="progressbar"
  tabindex="0"
  [class.circle]="appearance === 'circle'"
  [class.progress]="animation === 'progress'"
  [class.progress-dark]="animation === 'progress-dark'"
  [class.pulse]="animation === 'pulse'"
  [ngStyle]="theme"
></span>
