import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { featureMapping } from '@mkp/shared/util-product';
import { ProductOfferingBenefit } from '@mkp/shared/util-product';
import { TranslateModule } from '@ngx-translate/core';
import { ProductTooltipComponent } from '@mkp/product/feature-product-shared';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mkp-product-table-row-header-feature',
  templateUrl: './product-table-row-header-feature.component.html',
  styleUrl: './product-table-row-header-feature.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ProductTooltipComponent, TranslateModule],
})
export class ProductTableRowHeaderFeatureComponent {
  @Input()
  set benefit(benefit: ProductOfferingBenefit) {
    const { title, description } = featureMapping[benefit];
    if (title) {
      this.translation = title;
    }
    if (description) {
      this.tooltipText = description;
    }
  }
  @Input() showTooltip = false;

  translation: string | undefined;
  tooltipText: string | undefined;
}
