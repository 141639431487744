<div [@show]="status" class="row gdpr-banner-container">
  <div
    class="gdpr-banner-text"
    [innerHTML]="'GDPR.BANNER' | translate | gdprLink"
    mkpDataTest="gdpr-link"
  ></div>
  <ui-button [config]="gdprBtnConfig" (click)="close()" mkpDataTest="gdpr-btn">
    {{ 'GDPR.BTN-OK' | translate }}
  </ui-button>
</div>
