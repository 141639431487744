import { Injectable } from '@angular/core';
import { CartCheckout } from '@cart/models/cart-checkout.model';
import { Cart } from '@cart/models/cart.model';
import { CreateCartInput } from '@cart/models/create-cart-input.model';
import {
  MUTATION_CART_ADD_COUPON,
  MUTATION_CART_CHECKOUT,
  MUTATION_CART_CREATE,
  MUTATION_CART_REMOVE_COUPON,
  MutationCartAddCouponInput,
  MutationCartAddCouponOutput,
  MutationCartCheckoutInput,
  MutationCartCheckoutOutput,
  MutationCartCreateInput,
  MutationCartCreateOutput,
  MutationCartGetInput,
  MutationCartGetOutput,
  MutationCartRemoveCouponInput,
  MutationCartRemoveCouponOutput,
  QUERY_CART_GET,
} from '@cart/schemas/schema.cart';
import { BaseApolloService } from '@shared/modules/graphql/services/base-apollo.service';
import { ExecutionResult } from 'apollo-link';
import { Observable, filter, map } from 'rxjs';
import { CartMapper } from './cart.mapper';

@Injectable({ providedIn: 'root' })
export class CartService {
  constructor(
    private readonly apolloService: BaseApolloService,
    private readonly cartMapper: CartMapper
  ) {}

  createCart(cartInput: CreateCartInput): Observable<Cart> {
    return this.apolloService
      .mutate<MutationCartCreateOutput, MutationCartCreateInput>(MUTATION_CART_CREATE, {
        cartInput,
      })
      .pipe(
        filter(isDataDefined),
        map(({ data: { cartCreate } }) => cartCreate),
        map((cartDto) => this.cartMapper.mapCart(cartDto))
      );
  }

  getCart(cartGetInput: string): Observable<Cart> {
    return this.apolloService
      .mutate<MutationCartGetOutput, MutationCartGetInput>(QUERY_CART_GET, { cartGetInput })
      .pipe(
        filter(isDataDefined),
        map(({ data: { cart } }) => cart),
        map((cartDto) => this.cartMapper.mapCart(cartDto))
      );
  }

  checkout(cartCheckout: CartCheckout): Observable<Cart> {
    return this.apolloService
      .mutate<MutationCartCheckoutOutput, MutationCartCheckoutInput>(MUTATION_CART_CHECKOUT, {
        cartCheckout,
      })
      .pipe(
        filter(isDataDefined),
        map(({ data: { cartCheckout } }) => cartCheckout),
        map((cartDto) => this.cartMapper.mapCart(cartDto))
      );
  }

  addCouponToCart(cartTokenValue: string, coupon: string): Observable<Cart> {
    return this.apolloService
      .mutate<MutationCartAddCouponOutput, MutationCartAddCouponInput>(MUTATION_CART_ADD_COUPON, {
        cartTokenInput: cartTokenValue,
        couponInput: coupon,
      })
      .pipe(
        filter(isDataDefined),
        map(({ data: { cartAddCoupon } }) => cartAddCoupon),
        map((cartDto) => this.cartMapper.mapCart(cartDto))
      );
  }

  removeCouponFromCart(cartTokenValue: string): Observable<Cart> {
    return this.apolloService
      .mutate<MutationCartRemoveCouponOutput, MutationCartRemoveCouponInput>(
        MUTATION_CART_REMOVE_COUPON,
        {
          cartTokenInput: cartTokenValue,
        }
      )
      .pipe(
        filter(isDataDefined),
        map(({ data: { cartRemoveCoupon } }) => cartRemoveCoupon),
        map((cartDto) => this.cartMapper.mapCart(cartDto))
      );
  }
}

const isDataDefined = <T>(
  mutationResult: ExecutionResult<T>
): mutationResult is ExecutionResult<T> & { data: T } => mutationResult.data !== undefined;
