import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Styles } from './asset.model';
import { ASSET_STYLES } from './asset.styles';
import { AssetService } from './services/asset.service';

@Component({
  selector: 'ui-asset',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styleUrl: './asset.component.scss',
})
export class AssetComponent {
  private readonly renderer = inject(Renderer2);
  private readonly assetService = inject(AssetService);
  private readonly destroy = inject(DestroyRef);
  public readonly elementRef = inject(ElementRef<HTMLElement>);

  // check for asset in the cache, if it does not exist get it from network and write it in cache
  @Input({ required: true }) set name(name: string) {
    this.assetService
      .getAssetWithCache(name)
      .pipe(
        // shouldn't be needed because every observables complete, but just to make sure we don't leak
        takeUntilDestroyed(this.destroy)
      )
      .subscribe((svg) => {
        this.setSvg(svg);
        this.setStyles(name);
      });
  }

  // parent component can override styles on the <svg> element using this input
  @Input() styles: Styles = {};

  private setSvg(svg: string): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', svg);
  }

  private setStyles(name: string): void {
    const svg = this.elementRef.nativeElement.querySelector('svg');
    const styles = {
      ...(ASSET_STYLES.find((asset) => asset.name === name)?.styles || {}),
      ...this.styles,
    };
    if (svg) {
      this.renderer.setAttribute(svg, 'fill', 'currentColor');
      Object.entries(styles).forEach(([key, value]) => {
        this.renderer.setStyle(svg, key, value);
      });
    }
  }
}
