import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppConfig, LocalStorageKeys } from '../../config/app.config';

@Injectable({ providedIn: 'root' })
export class OnboardingGuard {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return localStorage.getItem(LocalStorageKeys.onboardingUser) != null
      ? of(this.router.parseUrl(AppConfig.routes.onboarding))
      : of(true);
  }
}
