import { Injectable } from '@angular/core';
import { productOptionsPageActions } from '@app/features/product-options/store/actions';
import { createCartSuccess } from '@cart/store/actions/cart-api.actions';
import { selectCart } from '@cart/store/selectors';
import { AppConfig } from '@config/app.config';
import { productSummaryPageActions } from '@features/product-summary/store/actions';
import { productCheckoutSuccess } from '@features/product-summary/store/actions/product-summary-api.actions';
import { selectSelectedAccount } from '@mkp/account/state';
import { selectMetadatasEntities } from '@mkp/metadata/state';
import { NotificationGatewayActions } from '@mkp/notification/state/actions';
import { ProductPlatformGroup } from '@mkp/shared/util-product';
import {
  EcommerceEventNameType,
  EcommercePlatformType,
  EventFunnelType,
} from '@mkp/tracking/feature-tealium';
import { TrackingService, TrackingUtilsService } from '@mkp/tracking/feature-tracking-old';
import { TrackingActions } from '@mkp/tracking/state/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectDisplayedProducts } from '@product-options/store/selectors/product-options.selectors';
import { selectRouterState } from '@store/selectors/router.selectors';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { getVacancyById, selectVacancyFromRoute } from '@vacancy/store/selectors/vacancy.selectors';
import { Observable, exhaustMap, filter, map, mergeMap, take } from 'rxjs';

@Injectable()
export class VacancyTrackingEffects {
  /**
   * @description ecom product scroll event
   * This effect detects when a user clicks the scroll button on the job publication page to see other products
   */
  readonly ecommerceProductScrollEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productOptionsPageActions.scrollProducts),
      concatLatestFrom(() => [
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      exhaustMap(([{ direction }, user, account]) =>
        this.trackingService.productScrollData({ direction, user, account })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description ecom Purchase event
   * This effect detects when a user purchases a new product, and dispatches that event
   */
  readonly ecommercePurchaseEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productCheckoutSuccess),
      filter(Boolean),
      concatLatestFrom(({ cartCheckout }) => [
        this.store.select(selectMetadatasEntities),
        this.getFunnelType(),
        this.store.select(getVacancyById(cartCheckout.vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([cartState, metadata, funnelType, vacancy, user, account]) => {
        const eventName: EcommerceEventNameType =
          funnelType === 'ecommerce_upsell' ? 'ecommerce_upsell' : 'ecommerce_purchase';

        return [
          this.trackingService.ecommerceEventData({
            user,
            account,
            eventName,
            ecommerceData: this.trackingUtilsService.cartToEcomData(
              cartState.cart,
              funnelType,
              'purchase'
            ),
            jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
            promotionData: this.trackingUtilsService.cartToPromotionData(cartState.cart),
          }),
          this.trackingService.jobCreatedData({
            jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
            user,
            account,
          }),
        ];
      }),
      mergeMap((trackingData) => trackingData.map(TrackingActions.sendPureFrontendEvent))
    )
  );

  /**
   * @description ecom Unverified purchase event
   * This effect detects when an unverified user purchases a new product, and dispatches that event
   */
  readonly ecommerceUnverifiedPurchaseEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationGatewayActions.unverifiedCheckoutSuccess),
      concatLatestFrom(() => [
        this.store.select(selectMetadatasEntities),
        this.getFunnelType(),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      exhaustMap(([{ cart, vacancy }, metadata, funnelType, user, account]) =>
        this.trackingService.unverifiedUserJobCreatedData({
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          ecommerceDataFull: this.trackingUtilsService.cartToEcomData(cart, funnelType, undefined),
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description ecom Cart Add event
   * This effect detects when a user add a product to cart, and dispatches that event
   */
  readonly ecommerceCartAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCartSuccess),
      concatLatestFrom((cartState) => [
        this.store.select(selectMetadatasEntities),
        this.getFunnelType(),
        this.store.select(getVacancyById(cartState.vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([cartState, metadata, funnelType, vacancy, user, account]) =>
        this.trackingService.ecommerceEventData({
          ecommerceData: this.trackingUtilsService.cartToEcomData(
            cartState.cart,
            funnelType,
            'add'
          ),
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          promotionData: this.trackingUtilsService.cartToPromotionData(cartState.cart),
          eventName: 'ecommerce_cart_add',
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description ecom Cart Remove event
   * This effect detects when a user wants to change a product, and dispatches that event
   */
  readonly ecommerceCartRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productSummaryPageActions.changeProduct),
      concatLatestFrom((state) => [
        this.store.select(selectMetadatasEntities),
        this.getFunnelType(),
        this.store.select(selectCart).pipe(filter(Boolean)),
        this.store.select(getVacancyById(state.vacancyId)).pipe(filter(Boolean)),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      map(([, metadata, funnelType, cart, vacancy, user, account]) =>
        this.trackingService.ecommerceEventData({
          ecommerceData: this.trackingUtilsService.cartToEcomData(cart, funnelType, 'remove'),
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          promotionData: this.trackingUtilsService.cartToPromotionData(cart),
          eventName: 'ecommerce_cart_remove',
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  /**
   * @description ecom Product List View event
   * This effect detects when a user land to product options list, and dispatches that event
   */
  readonly ecommerceProductListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        productOptionsPageActions.loadProductList,
        productOptionsPageActions.switchPlatformGroup
      ),
      map(({ platformGroup }) => platformGroup),
      filter(Boolean),
      map((platformGroup) => getPlatform(platformGroup)),
      concatLatestFrom(() => [
        this.store.select(selectVacancyFromRoute).pipe(filter(Boolean)),
        this.store.select(selectMetadatasEntities),
        this.getFunnelType(),
        this.store.select(selectLoggedInUser),
        this.store.select(selectSelectedAccount),
      ]),
      exhaustMap(([platform, vacancy, metadata, funnelType, user, account]) =>
        // wait for products to arrive in the store
        this.store.select(selectDisplayedProducts(vacancy.id)).pipe(
          filter((products) => products?.length > 0),
          map(
            (products) =>
              [platform, vacancy, products, metadata, funnelType, user, account] as const
          ),
          take(1)
        )
      ),
      map(([platform, vacancy, products, metadata, funnelType, user, account]) =>
        this.trackingService.ecommerceEventData({
          ecommerceData: this.trackingUtilsService.getECommerceCategory(
            products,
            funnelType,
            platform,
            'detail'
          ),
          jobData: this.trackingUtilsService.vacancyToJobAdTracking(vacancy, metadata),
          promotionData: {},
          eventName: 'ecommerce_product_list_view',
          user,
          account,
        })
      ),
      map(TrackingActions.sendPureFrontendEvent)
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly trackingService: TrackingService,
    private readonly trackingUtilsService: TrackingUtilsService
  ) {}

  private getFunnelType(): Observable<EventFunnelType> {
    return this.store.select(selectRouterState).pipe(
      map((routerState): EventFunnelType => {
        return routerState.state.url.includes(AppConfig.routes.upsell)
          ? 'ecommerce_upsell'
          : 'ecommerce';
      })
    );
  }
}

const getPlatform = (platformGroup: ProductPlatformGroup): EcommercePlatformType => {
  switch (platformGroup) {
    case ProductPlatformGroup.JobScout24:
      return 'jobs24';
    case ProductPlatformGroup.JobUp:
      return 'jobup';
    case ProductPlatformGroup.Jobs:
    default:
      return 'jobs';
  }
};
