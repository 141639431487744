import { Directive, ElementRef, HostBinding } from '@angular/core';

interface InkBarPosition {
  left: string;
  width: string;
}

@Directive({
  // this directive is being used as a component ? I don't want to mess it up so I just ignore the eslint error
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'tab-ink-bar',
  standalone: true,
})
export class TabInkBarDirective {
  @HostBinding('class') tabInkBarClass = 'tab-ink-bar';

  constructor(private el: ElementRef) {}

  alignToElement(element: HTMLElement) {
    this.setStyles(element);
  }

  private setStyles(element: HTMLElement) {
    const position = this.getInkBarPosition(element);
    const inkBar: HTMLElement = this.el.nativeElement;

    inkBar.style.left = position.left;
    inkBar.style.width = position.width;
  }

  private getInkBarPosition(element: HTMLElement): InkBarPosition {
    return {
      left: `${element?.offsetLeft || 0}px`,
      width: `${element?.offsetWidth || 0}px`,
    };
  }
}
