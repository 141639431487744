import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ProductTableSkeletonComponent } from '@mkp/product/feature-product-shared';
import {
  Product,
  ProductCode,
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  isPaid,
} from '@mkp/shared/util-product';
import { TranslateModule } from '@ngx-translate/core';
import { ProductComparisonTableColumnHeaderComponent } from './product-comparison-table-column-header/product-comparison-table-column-header.component';
import { ProductComparisonTableFeaturesComponent } from './product-comparison-table-features/product-comparison-table-features.component';

@Component({
  selector: 'mkp-product-comparison-table',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    TranslateModule,
    ProductComparisonTableColumnHeaderComponent,
    ProductComparisonTableFeaturesComponent,
    ProductTableSkeletonComponent,
  ],
  templateUrl: './product-comparison-table.component.html',
  styleUrl: './product-comparison-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComparisonTableComponent implements OnChanges {
  @Input({ required: true }) products!: Product[];
  @Input({ required: true }) platforms!: ProductOfferingPlatform[];
  @Input({ required: true }) benefits!: ProductOfferingBenefit[];
  @Input() isReadMode = false;
  @Input() isProductLoading: ProductCode | false = false;

  @Output() handleSelectedProduct = new EventEmitter<ProductCode>();

  @HostBinding('class.unselectable') get isUnselectable() {
    return this.products.length <= 2;
  }

  selectedProducts: Product[] = [];
  calendarFeature = ProductOfferingBenefit.Calendar;
  defaultSelectedProductCodes: ProductCode[] = [];

  ngOnChanges({ products }: SimpleChanges) {
    if (products && products.currentValue) {
      this.defaultSelectedProductCodes = this.products.reduce((codes, product) => {
        if (isPaid(product)) codes = [...codes, product.code];

        return codes;
      }, [] as ProductCode[]);
    }
  }

  // the newly selected products on the input selection list
  chosenProduct(product: Product, i: number) {
    this.selectedProducts[i] = { ...product };
    this.selectedProducts = [...this.selectedProducts];
  }
}
