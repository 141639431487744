import { gql } from '@apollo/client/core';
import { AddressFragments } from './address-schema';
import { CompanyApiLinkFragments } from './api-link-schema';
import { LocationFragments } from './location-schema';

export const CompanyProfileFragments = {
  companyProfile: gql`
    fragment companyProfileFields on CompanyProfile {
      id
      legalEntityId
      legalEntityState
      legalEntityVersion
      employeeCountFrom
      employeeCountTo
      name
      logoMediaApiId
      headerMediaApiId
      description {
        de
        en
        fr
        description_language
      }
      benefits
      videos {
        weight
        description
        url
      }
      links {
        type
        url
      }
      companySize
      _version
    }
  `,
};

export const QUERY_GET_COMPANY_PROFILE = gql`
  query queryGetCompanyProfile($id: String!) {
    companyProfileById(id: $id) {
      ...companyProfileFields
      legalEntityName
      legalEntityAddress {
        ...addressFields
      }
      locations {
        ...locationFields
        address {
          ...addressFields
        }
      }
      _links {
        ...companyProfileLinksFields
      }
    }
  }
  ${CompanyProfileFragments.companyProfile}
  ${AddressFragments.address}
  ${LocationFragments.location}
  ${CompanyApiLinkFragments.companyProfileLinks}
`;

export const MUTATION_UPDATE_COMPANY_PROFILE = gql`
  mutation mutationUpdateCompanyProfile($id: String!, $companyProfile: CompanyProfileInput) {
    updateCompanyProfile(id: $id, companyProfile: $companyProfile) {
      ...companyProfileFields
      legalEntityName
      legalEntityAddress {
        ...addressFields
      }
      locations {
        ...locationFields
        address {
          ...addressFields
        }
      }
    }
  }
  ${CompanyProfileFragments.companyProfile}
  ${AddressFragments.address}
  ${LocationFragments.location}
`;
