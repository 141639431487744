import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResourceService } from '@shared/services/resource.service';
import { VacancySerializer } from '@vacancy/interfaces/vacancy-serializer.interface';
import { Vacancy } from '@vacancy/models/vacancy.model';

@Injectable({ providedIn: 'root' })
export class VacancySanitizerProxyService extends ResourceService<Vacancy> {
  constructor(http: HttpClient) {
    super(http, `${environment.api.middleware}/api`, 'vacancy', VacancySerializer);
  }
}
