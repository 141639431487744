import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { selectProducts } from '@app/features/product-options/store/selectors/product-options.selectors';
import { LOCAL_STORAGE_CART_TOKEN_KEY } from '@cart/cart.constants';
import { Cart } from '@cart/models/cart.model';
import { CartService } from '@cart/services';
import { cartApiActions } from '@cart/store/actions';
import { AppConfig } from '@config/app.config';
import { getMessageError, MessageError } from '@core/models';
import { selectCart } from '@features/cart/store/selectors';
import { productSummaryPageActions } from '@features/product-summary/store/actions';
import { Account } from '@mkp/account/data-access';
import { selectSelectedAccount } from '@mkp/account/state';
import { SnackbarService } from '@mkp/shared/ui-library';
import { isFreeTrialProduct, Product } from '@mkp/shared/util-product';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { productOptionsPageActions } from '@product-options/store/actions';
import { State } from '@store/reducers';
import { selectVacancyIdFromRoute } from '@vacancy/store/selectors/vacancy.selectors';
import { of, take } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class CartEffects {
  readonly createCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartApiActions.createCart),
      // make sure productOptions have time to load
      switchMap(({ productCode, coupon }) =>
        this.store.select(selectProducts).pipe(
          filter((products) => !!products?.length),
          map((productOptions) => [productCode, productOptions, coupon] as const),
          take(1)
        )
      ),
      concatLatestFrom(() => [
        this.store.select(selectSelectedAccount),
        this.store.select(selectVacancyIdFromRoute),
      ]),
      filter(areAccountAndVacancyIdDefined),
      switchMap(([[productCode, products, voucher], { id: accountId }, vacancyId]) => {
        const selectedProduct = products?.find(({ code }) => code === productCode);
        return this.cartService
          .createCart({
            withProducts: true,
            products: [productCode],
            account: accountId,
            coupon: voucher ?? setVoucher(selectedProduct),
          })
          .pipe(
            tap((res: Cart) =>
              localStorage.setItem(LOCAL_STORAGE_CART_TOKEN_KEY, res.cartTokenValue)
            ),
            map((cart) => cartApiActions.createCartSuccess({ cart, vacancyId })),
            catchError((error: unknown) =>
              of(cartApiActions.createCartFailure({ error: getCartError(error) }))
            )
          );
      })
    );
  });

  readonly createCartFromUpsellLanding$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(productOptionsPageActions.enterPage),
        concatLatestFrom(() => [
          this.store.select(selectSelectedAccount),
          this.store.select(selectVacancyIdFromRoute),
        ]),
        filter(areAccountAndVacancyIdDefined),
        switchMap(([{ productCode: product, voucher: coupon }, { id: account }, vacancyId]) => {
          return this.cartService
            .createCart({
              withProducts: true,
              products: [product],
              account,
              coupon,
            })
            .pipe(
              tap((res: Cart) =>
                localStorage.setItem(LOCAL_STORAGE_CART_TOKEN_KEY, res.cartTokenValue)
              ),
              map((cart) => cartApiActions.createCartSuccess({ cart, vacancyId })),
              catchError((error: unknown) =>
                of(cartApiActions.createCartFailure({ error: getCartError(error) }))
              )
            );
        })
      );
    },
    { useEffectsErrorHandler: false }
  );

  readonly addCouponToCart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(productSummaryPageActions.applyCoupon),
        concatLatestFrom(() => this.store.select(selectCart)),
        filter(isCartDefined),
        switchMap(([{ coupon }, { cartTokenValue }]) => {
          return this.cartService.addCouponToCart(cartTokenValue, coupon).pipe(
            map((cart) => cartApiActions.addCouponToCartSuccess({ cart })),
            catchError((error: unknown) =>
              of(cartApiActions.addCouponToCartFailure({ error: getCartError(error) }))
            )
          );
        })
      );
    },
    { useEffectsErrorHandler: false }
  );

  readonly removeCouponFromCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productSummaryPageActions.removeCoupon), // change action
      concatLatestFrom(() => this.store.select(selectCart)),
      filter(isCartDefined),
      switchMap(([_, { cartTokenValue }]) => {
        return this.cartService.removeCouponFromCart(cartTokenValue).pipe(
          map((cart) => cartApiActions.removeCouponFromCartSuccess({ cart })),
          catchError((error: unknown) =>
            of(cartApiActions.removeCouponFromCartFailure({ error: getCartError(error) }))
          )
        );
      })
    );
  });

  readonly createCartFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(cartApiActions.createCartFailure),
        tap(() => {
          this.snackBarNotificationsService.show('CART.CREATE_CART.MUTATION_ERROR_MSG');
        })
      );
    },
    { dispatch: false }
  );

  readonly fetchCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartApiActions.fetchCart),
      switchMap((data) => {
        return this.cartService.getCart(data.cartGetInput).pipe(
          map((cart) => cartApiActions.fetchCartSuccess({ cart })),
          catchError((error: unknown) => {
            localStorage.removeItem(LOCAL_STORAGE_CART_TOKEN_KEY);
            this.router.navigate([AppConfig.routes.error404]);
            return of(cartApiActions.fetchCartFailure({ error: getCartError(error) }));
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private cartService: CartService,
    private snackBarNotificationsService: SnackbarService,
    private router: Router
  ) {}
}

const getCartError = (error: unknown): MessageError => getMessageError(error, 'CartEffects');
const areAccountAndVacancyIdDefined = <T>(
  inputArray: [T, Account | undefined, string | undefined]
): inputArray is [T, Account, string] => Boolean(inputArray[1]) && Boolean(inputArray[2]);
const isCartDefined = <T>(inputArray: [T, Cart | null]): inputArray is [T, Cart] =>
  inputArray[1]?.cartTokenValue !== undefined;
const setVoucher = (selectedProduct: Product | undefined): string | undefined => {
  return selectedProduct && isFreeTrialProduct(selectedProduct)
    ? selectedProduct.coupon
    : undefined;
};
