import { combineLatest, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { selectActiveAccountMembershipCount } from '@mkp/account-membership/state';
import { AppConfig } from '@config/app.config';
import { selectSelectedAccount } from '@mkp/account/state';
import { isNotNull } from '@core/guards/guard-helpers';

export function hasMultipleWorkspacesGuard(): Observable<boolean | UrlTree> {
  const store = inject(Store);
  const router = inject(Router);

  return combineLatest([
    store.select(selectIsBoUser).pipe(filter((isBoUser) => !isBoUser)),
    store.select(selectActiveAccountMembershipCount).pipe(
      switchMap((count) =>
        count === 1
          ? store.select(selectSelectedAccount).pipe(
              filter(Boolean),
              map(() => count)
            )
          : of(count)
      )
    ),
  ]).pipe(
    map(([_, count]) => count),
    filter(isNotNull),
    take(1),
    map((count) => count > 1 || router.createUrlTree([AppConfig.routes.dashboard]))
  );
}
