import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as accountMembershipEffects from './account-membership.effects';
import { accountMembershipFeature } from './account-membership.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(accountMembershipFeature),
    EffectsModule.forFeature([accountMembershipEffects]),
  ],
})
export class AccountMembershipStateModule {}
