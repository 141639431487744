import { inject } from '@angular/core';
import { CanLoadFn, CanMatchFn, Route, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { Store } from '@ngrx/store';
import { Observable, map, take, filter } from 'rxjs';
import { selectActiveAccountMembershipCount } from '@mkp/account-membership/state';
import { concatLatestFrom } from '@ngrx/effects';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { isNotNull } from './guard-helpers';

export const inactiveUserGuard: (route: Route) => Observable<true | UrlTree> = ((route) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectActiveAccountMembershipCount).pipe(
    filter(isNotNull),
    map((membershipCount) => membershipCount > 0),
    concatLatestFrom(() => store.select(selectIsBoUser).pipe(filter(isNotNull))),
    take(1),
    map(([hasAccountMembership, isBoUser]) => {
      // if user is inactive : go on with routing, otherwise redirect the app
      return (
        isNotBoUserOrHasNoAccountMembership(isBoUser, hasAccountMembership) ||
        router.parseUrl(getActiveRedirectRoute(route.path ?? '', isBoUser))
      );
    })
  );
}) satisfies CanMatchFn | CanLoadFn; // make sure it can be used as `canMatch` and `canLoad`

const isNotBoUserOrHasNoAccountMembership = (isBoUser: boolean, hasAccountMembership: boolean) => {
  return isBoUser ? false : !hasAccountMembership;
};

// logged-in user trying to reach /unsubscribe are redirected to their profile to manage their marketing-consent
const getActiveRedirectRoute = (route: string, isBoUser: boolean): string => {
  return isBoUser
    ? `/${AppConfig.routes.dashboard}`
    : route.includes(AppConfig.routes.unsubscribe)
      ? `/${AppConfig.routes.account}`
      : `/${AppConfig.routes.dashboard}`;
};
