import { HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '@env/environment';
import { DEPRECATED_PRODUCT_CODES, Product, ProductDto } from '@mkp/shared/util-product';
import { map, Observable } from 'rxjs';
import { ProductMapper } from '..';

export const TAXON_CODE = new InjectionToken('Taxonomy Code', {
  factory: () => 'JCAI',
});

const API_URL = new InjectionToken('API Base Url', {
  factory: () => environment.api.ecom,
});

export interface ProductApiEnvelope {
  page: number;
  pages: number;
  limit: number;
  total: number;
  items: ProductDto[];
}

@Injectable({ providedIn: 'root' })
export class ProductResource {
  private readonly http = inject(HttpClient);
  private readonly taxonCode = inject(TAXON_CODE);
  private readonly apiUrl = inject(API_URL);

  get = (productCode: string, accountId?: string): Observable<Product> =>
    this.http
      .get<ProductDto>(`${this.apiUrl}/products/by-code/${productCode}?accountId=${accountId}`)
      .pipe(map((productDto) => ProductMapper.toViewModel(productDto) as Product));

  getAll = (accountId: string | undefined): Observable<Product[]> =>
    this.http
      .get<ProductApiEnvelope>(
        `${this.apiUrl}/taxon-products/by-code/${this.taxonCode}?limit=99` +
          (accountId ? `&accountId=${accountId}` : '')
      )
      .pipe(
        this.filterDeprecatedProductsOperator(),
        map((products) => products.map(ProductMapper.toViewModel).filter(Boolean) as Product[])
      );

  /**
   * For some reason, the API still return deprecated JOBSLIMITED product, so we need to filter it out
   * @topic CDP-54
   */
  private filterDeprecatedProductsOperator(): (
    source: Observable<ProductApiEnvelope>
  ) => Observable<ProductDto[]> {
    return (source) =>
      source.pipe(
        map((envelop) =>
          envelop.items.filter((product) => !DEPRECATED_PRODUCT_CODES.includes(product.code))
        )
      );
  }
}
