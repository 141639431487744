<ui-feedback-header
  [title]="config?.title"
  [state]="config?.state"
  [showCloseBtn]="config?.dismissible"
  (handleAction)="cancel()"
/>

<ui-feedback-body *ngIf="config?.content as content" [content]="content" />

<ui-feedback-footer
  [primaryConfig]="primaryConfig"
  [primaryDataTest]="config.primaryDataTest"
  [secondaryConfig]="secondaryConfig"
  [secondaryDataTest]="config.secondaryDataTest"
  [tertiaryConfig]="tertiaryConfig"
  [tertiaryDataTest]="config.tertiaryDataTest"
  (handlePrimaryClick)="confirm()"
  (handleSecondaryClick)="cancel()"
  (handleTertiaryClick)="handleTertiaryClick.emit()"
/>
