import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createFetchStateSelectors } from '@mkp/shared/util-state';

import { CartState } from '@cart/store/reducers';

export const selectCartState = createFeatureSelector<CartState>('cartState');

export const { selectLoaded: selectCartLoaded, selectLoading: selectCartLoading } =
  createFetchStateSelectors(selectCartState);

export const selectCart = createSelector(selectCartState, (state) => state.cart);

export const selectFetchState = createSelector(selectCartState, (state) => state.fetchState);

export const selectNotes = createSelector(selectCartState, (state) => state.notes);

export const selectCartItems = createSelector(selectCart, (cart) => cart?.items);

export const selectCartTotals = createSelector(selectCart, (cart) => cart?.totals);
