import { Injectable } from '@angular/core';
import { isMessageError, MessageError } from '@core/models';
import { LOCAL_STORAGE_VOUCHER_KEY } from '@mkp/shared/data-access';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { UserDisplayType } from '@mkp/user/data-access';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { SendVerificationEmailApi } from '@shared/core-api/apis/send-verification-email.api';
import { onboardingActions } from '@store/actions/settings.actions';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class SettingsEffects {
  resendVerificationEmail$ = createEffect(
    () =>
      this.actions$.pipe(ofType(onboardingActions.resendEmailVerificationStart)).pipe(
        switchMap(({ userId }) =>
          this.sendVerificationEmailApi.sendVerificationEmail(userId).pipe(
            tap(() => this.notificationsService.show('ONBOARDING.VERIFY.RESEND_EMAIL.SUCCESS')),
            map(() => onboardingActions.resendEmailVerificationSuccess()),
            catchError((error: unknown) => {
              console.error(error);
              this.notificationsService.show('ONBOARDING.VERIFY.RESEND_EMAIL.FAILURE', {
                state: ActionState.Error,
              });

              return (
                isSettingsError(error) &&
                of(onboardingActions.resendEmailVerificationFailure({ error }))
              );
            })
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  saveVoucher$ = createEffect(
    () =>
      this.actions$.pipe(ofType(routerNavigatedAction)).pipe(
        filter(({ payload }) => payload.event.url.includes('onboarding')),
        map(({ payload }) => payload.routerState['queryParams'][LOCAL_STORAGE_VOUCHER_KEY]),
        filter(Boolean),
        concatLatestFrom(() => this.store.select(selectLoggedInUser)),
        tap(([voucher, currentUser]) => {
          if (
            !currentUser ||
            currentUser.settings.displayType !== UserDisplayType.CustomerService
          ) {
            this.saveVoucherToLocalStorage(voucher as string);
          }
        })
      ),
    { dispatch: false, useEffectsErrorHandler: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationsService: SnackbarService,
    private readonly sendVerificationEmailApi: SendVerificationEmailApi,
    private store: Store
  ) {}

  saveVoucherToLocalStorage(voucher: string) {
    localStorage.setItem(LOCAL_STORAGE_VOUCHER_KEY, voucher);
  }
}

export const getNotificationMessage = (page: string | null): string => page?.toUpperCase() ?? '';

const isSettingsError = (error: unknown): error is MessageError =>
  isMessageError(error, 'SettingsEffects');
