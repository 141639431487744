import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { selectActiveAccountMembershipCount } from '@mkp/account-membership/state';
import { selectIsBoUser, selectIsUserRole } from '@user/store/selectors/user.selectors';
import { concatLatestFrom } from '@ngrx/effects';
import { UserRole } from '@mkp/user/data-access';
import { isNotNull } from './guard-helpers';

const { routes } = AppConfig;

export const activeUserGuard: () => Observable<true | UrlTree> = () => {
  const store = inject(Store);
  const router = inject(Router);

  return hasAccountMembershipOperator(store).pipe(
    map((hasAccountMembership) => hasAccountMembership || router.parseUrl(routes.claimCompany))
  );
};

const hasAccountMembershipOperator = (store: Store): Observable<boolean> =>
  store.select(selectActiveAccountMembershipCount).pipe(
    filter(isNotNull),
    concatLatestFrom(() => [
      store.select(selectIsBoUser),
      store.select(selectIsUserRole(UserRole.SuperAdmin)),
    ]),
    take(1),
    map(
      ([accountMembershipCount, isBoUser, isSuperAdmin]) =>
        isBoUser || isSuperAdmin || accountMembershipCount > 0
    )
  );
