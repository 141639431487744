import { Button, ButtonColor, ButtonSize, ButtonVariant } from './button.model';

export const BUTTON_DEFAULT_CONFIG: Button = {
  classList: [],
  size: ButtonSize.Medium,
  color: ButtonColor.Primary,
  variant: ButtonVariant.Square,
  mobileFullWidth: true,
  fullWidth: false,
};

export const BUTTON_SMALL_DEFAULT_CONFIG: Button = {
  classList: ['body-s', 'semibold'],
  size: ButtonSize.Small,
  color: ButtonColor.Secondary,
  variant: ButtonVariant.Square,
};

export const LINK_BUTTON_DEFAULT_CONFIG: Button = {
  classList: ['semibold'],
  size: ButtonSize.Medium,
  color: ButtonColor.Link,
  variant: ButtonVariant.Square,
  disableRipple: true,
  fullWidth: false,
};

export const ICON_BUTTON_DEFAULT_CONFIG: Button = {
  classList: ['icon-only'],
  size: ButtonSize.Medium,
  color: ButtonColor.Tertiary,
  variant: ButtonVariant.Square,
};
