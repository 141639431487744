import { createSelector } from '@ngrx/store';
import { fromUser } from '@user/store/reducers';
import { UserDisplayType, UserRole, UserType } from '@mkp/user/data-access';
import {
  selectIsLegalEntityBeingVerified,
  selectSelectedAccountId,
} from '../../../../../../../../libs/account/state/src/lib/account/account.selectors'; // no @mkp/account/state

export const {
  selectLoading,
  selectLoaded,
  selectTotalCount,
  selectLinks,
  selectFilter,
  selectEntities: selectUsers,
  selectFeatureState,
  selectById: selectUserById,
} = fromUser.selectors;

export const selectLoggedInUser = createSelector(
  selectFeatureState,
  selectUsers,
  ({ loggedInId }, users) => (loggedInId ? users?.[loggedInId] : null)
);

export const selectLoggedInUserEmail = createSelector(selectLoggedInUser, (user) =>
  user ? user.email : null
);

export const selectLoggedInUserId = createSelector(selectLoggedInUser, (user) => user?.id);

/**
 * @deprecated Super Admin should have displayType set to CustomerService
 * @param userRole
 */
export const selectIsUserRole = (userRole: UserRole) =>
  createSelector(selectLoggedInUser, (user) => user?.role === userRole);

export const selectIsBoUser = createSelector(
  selectLoggedInUser,
  selectIsUserRole(UserRole.SuperAdmin),
  (user, isSuperAdmin) =>
    user ? user.settings?.displayType === UserDisplayType.CustomerService || isSuperAdmin : null
);

export const selectUserDisplayType = createSelector(
  selectLoggedInUser,
  (user) => user?.settings?.displayType
);

export const selectUserPublicationAiUsageConsent = createSelector(
  selectLoggedInUser,
  (user) => user?.settings?.publicationAiUsageConsent
);

export const selectList = createSelector(selectFeatureState, selectUsers, ({ list }, users) =>
  list.map((userId) => users[userId])
);

export const selectUserType = createSelector(
  selectIsBoUser,
  (isBoUser): UserType => (isBoUser ? UserType.BoUser : UserType.RegularUser)
);

export const selectIsSelectedAccountBeingVerified = createSelector(
  selectIsBoUser,
  selectIsLegalEntityBeingVerified,
  // never display "account is being verified" features for multi-account users
  (isMultipleAccountsUser, isLegalEntityBeingVerified) =>
    !isMultipleAccountsUser && isLegalEntityBeingVerified
);

export const selectAccountVerificationStatus = createSelector(
  selectIsSelectedAccountBeingVerified,
  selectSelectedAccountId,
  (isBeingVerified, accountId) => ({
    isBeingVerified,
    accountId,
  })
);
