import { Injectable } from '@angular/core';
import { BaseHttpResource } from '../base-http.resource';
import { CreditRedemptionDto } from './credit-redemption.dto';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';

@Injectable({ providedIn: 'root' })
export class CreditRedemptionResource extends BaseHttpResource<
  CreditRedemptionDto,
  CoreListEnvelop<CreditRedemptionDto>
> {
  constructor() {
    super('credit-redemption');
  }
}
