import { LayoutElements } from '@mkp/layout/state';

export class ElementBuilder {
  private elements: LayoutElements = {
    hasNavigation: false,
    isNavigationCollapsed: false,
    hasBorders: false,
    hasTopHeader: false,
    normalDocumentFlow: false,
    hasFooter: false,
  }

  withNavigation(hasNavigation: boolean) {
    this.elements.hasNavigation = hasNavigation;
    return this;
  }

  withNavigationCollapsed(isNavigationCollapsed = true) {
    this.elements.isNavigationCollapsed = isNavigationCollapsed;
    return this;
  }

  withBorders(hasBorders = true) {
    this.elements.hasBorders = hasBorders;
    return this;
  }

  withFooter(hasFooter = true) {
    this.elements.hasFooter = hasFooter;
    return this;
  }

  withTopHeader(hasTopHeader = true) {
    this.elements.hasTopHeader = hasTopHeader;
    return this;
  }

  withNormalDocumentFlow(normalDocumentFlow = true) {
    this.elements.normalDocumentFlow = normalDocumentFlow;
    return this;
  }

  build(): LayoutElements {
    return this.elements;
  }
}