import { ApplicationStatus, ApplicationStep, isApplicationStep } from '@mkp/application/models';
import { ApplicationStatusDto } from './application-status.dto';

export const mapApplicationStatusDtoToModel = (dto: ApplicationStatusDto): ApplicationStatus => ({
  ...dto,
  createdAt: new Date(dto.createdAt),
  updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : null,
  step: mapStepToApplicationStep(dto.step),
});
export const mapStepToApplicationStep = (step: number): ApplicationStep => {
  try {
    if (isApplicationStep(step)) {
      return step;
    } else {
      throw new Error(`Error mapping step to ApplicationStep`);
    }
  } catch (error) {
    console.error(error);
    return ApplicationStep.New;
  }
};
