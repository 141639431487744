import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrafficFunnel } from '@shared/enums';
import { getLogoPlatformClass } from '@shared/helpers/platform.helpers';
import { selectUserDisplayType } from '@user/store/selectors/user.selectors';
import { RouterStateUrl } from '../reducers';

export const selectRouterState =
  createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('routerReducer');

export const selectRouteData = createSelector(
  selectRouterState,
  (routerState) => routerState?.state.data
);

export const selectRouteParams = createSelector(
  selectRouterState,
  (routerState) => routerState?.state?.params
);

export const selectRouteUrl = createSelector(
  selectRouterState,
  (routerState) => routerState?.state?.url
);

export const selectRouteParam = (paramName: string) =>
  createSelector(selectRouteParams, (params) => params[paramName] as string | undefined);

const selectRouteQueryParams = createSelector(
  selectRouterState,
  (routerState) => routerState?.state.queryParams
);

export const selectPlatformClassesFromRoute = createSelector(
  selectRouteParam('funnel'),
  selectUserDisplayType,
  (funnel, displayType) => getLogoPlatformClass(funnel as TrafficFunnel, displayType)
);

export const selectRouteQueryParam = (paramName: string) =>
  createSelector(
    selectRouteQueryParams,
    (queryParams) => queryParams[paramName] as string | undefined
  );
