import { VacancyDto, VacancyModel } from './vacancy.models';

export const toViewModel = (vacancyDto: VacancyDto): VacancyModel => {
  const {
    _embedded,
    _type,
    createdAt: _createdAt,
    updatedAt: _updatedAt,
    createdBy: _createdBy,
    updatedBy: _updatedBy,
    _version,
    ...vacancy
  } = vacancyDto;

  return { ...vacancy, videoUrl: vacancy.videos?.[0]?.url };
};
