import { LanguageCode, LanguageIso } from '@mkp/shared/data-access';
import { createAction, props } from '@ngrx/store';

export const appLanguageInitForUser = createAction(
  '[Language Effects] App Language Initialized for User',
  props<{ languageIso: LanguageIso }>()
);
export const appLanguageInitForVisitor = createAction(
  '[Language Effects] App Language Initialized for Visitor',
  props<{ languageIso: LanguageIso }>()
);
export const appLanguageSwitchedForUser = createAction(
  '[Language Effects] App Language Switched for User',
  props<{
    updateUser: { id: string; _version: string; language: LanguageCode };
    notification: false;
    redirectUrl: false;
  }>()
);
export const appLanguageSwitchedForVisitor = createAction(
  '[Language Effects] App Language Switched for Visitor'
);
