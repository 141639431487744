import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { authFeature } from './auth.reducer';
import * as authEffects from './auth.effects';
import * as authWorkspaceEffects from './auth-workspace.effects';

import { AuthApiEffects } from './auth-api.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([authEffects, authWorkspaceEffects, AuthApiEffects]),
  ],
})
export class AuthStateModule {}
