import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ngIfTopDownAnimationStickyHeader } from '@mkp/shared/ui-animations';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { Button, ButtonComponent } from '@mkp/shared/ui-library';
import {
  Product,
  ProductCode,
  ProductPlatformGroup,
  ScrollDirection,
} from '@mkp/shared/util-product';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollPosition, ScrollToNextDirective } from '../../directives';
import { ProductStickyHeaderCardComponent } from '../product-sticky-header-card/product-sticky-header-card.component';

@Component({
  selector: 'mkp-product-sticky-header',
  templateUrl: './product-sticky-header.component.html',
  styleUrls: ['./product-sticky-header.component.scss', '../../style/product-offering.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AssetComponent,
    ButtonComponent,
    DataTestDirective,
    ProductStickyHeaderCardComponent,
    NgIf,
    NgFor,
    ScrollToNextDirective,
    TranslateModule,
  ],
  animations: [ngIfTopDownAnimationStickyHeader],
})
export class ProductStickyHeaderComponent {
  @Input({ required: true }) products: Product[] = [];
  @Input({ required: true }) scrollConfig!: Record<ScrollPosition, Button>;
  @Input({ required: true }) activePlatformGroup: ProductPlatformGroup | undefined;
  @Input() isReadMode = false;
  @Input() showLabel = true;
  @Input() isProductLoading: ProductCode | false = false;

  @Output() handleSelectedProduct = new EventEmitter<Product['code']>();
  @Output() handleProductScroll: EventEmitter<ScrollDirection> = new EventEmitter();

  @HostBinding('@topDownAnimation') get inOutAnimation() {
    return;
  }
}
