import { inject, Injectable } from '@angular/core';
import { FeatureFlags, selectTealiumFlags } from '@mkp/debug/state';
import { TealiumConsentDto } from '@mkp/gdpr/state';
import { Store } from '@ngrx/store';
import {
  AsyncSubject,
  lastValueFrom,
  map,
  Observable,
  share,
  take,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { Category } from './categories';
import { ClickEventDto } from './models';
import { TealiumGDPR, UTag, WindowWithTealium } from './tealium.model';

@Injectable({ providedIn: 'root' })
export class TealiumService {
  readonly isBooted$: Observable<boolean> = timer(0, 1000).pipe(
    map((i) => ({
      i,
      isBooted: isWindowUtag(this.window),
    })),
    takeWhile(({ i, isBooted }) => !isBooted && i < 10, true),
    map(({ isBooted }) => isBooted), // use share operator to prevent multiple timers on multiple subscriptions
    share({
      // use `AsyncSubject` as a connector to emit the value only when the source observable completes
      connector: () => new AsyncSubject(),
    })
  );

  private readonly store = inject(Store);
  private readonly tealiumFlags$ = this.store.select(selectTealiumFlags);
  private readonly window = inject(Window) as WindowWithTealium;

  constructor() {
    this.window['utag_cfg_ovrd'] = { noview: true };
  }

  setPreferencesFromList(tealiumCategories: string[]): Promise<void> {
    return this.waitForIsBootedThen(({ gdpr }) => gdpr.setPreferencesFromList(tealiumCategories));
  }

  // Data layer is an optional set of key/value pairs
  track(eventType: string, data: Category | unknown): Promise<void> {
    return this.waitForIsBootedThen(({ track }) => track(eventType, data));
  }

  view = async (data?: Category): Promise<Category> => {
    await this.track('view', data);

    this.debugLogger(FeatureFlags.trackingView, () =>
      console.log(`TealiumService - view: '${data?.page_location}'`, data)
    );

    return data as Category;
  };

  link = async (data?: Category): Promise<Category> => {
    await this.track('link', data);

    this.debugLogger(FeatureFlags.trackingLink, () =>
      console.log(`TealiumService - link: '${data?.event_name}'`, data)
    );

    return data as Category;
  };

  click = async (clickEventDto: ClickEventDto): Promise<ClickEventDto> => {
    await this.track('link', clickEventDto);

    this.debugLogger(FeatureFlags.trackingClick, () =>
      console.log(`TealiumService - click: ${getClickTrackingDebug(clickEventDto)}`, clickEventDto)
    );

    return clickEventDto;
  };

  getConsentState = (): Promise<ReturnType<TealiumGDPR['getConsentState']> | null> =>
    this.waitForIsBootedThen(({ gdpr }) => gdpr.getConsentState());

  setConsentState = (consentState: TealiumConsentDto): Promise<void> =>
    this.waitForIsBootedThen(({ gdpr }) => gdpr.setPreferencesValues(consentState));

  private waitForIsBootedThen = <T>(callback: (uTag: UTag) => T): Promise<T | null> =>
    lastValueFrom<T>(
      this.isBooted$.pipe(
        filter(Boolean),
        map(() => this.window as WindowWithTealium),
        map((window: WindowWithTealium) => callback(window.utag))
      )
    ).catch(() => null);

  private debugLogger = (
    flag: FeatureFlags.trackingClick | FeatureFlags.trackingView | FeatureFlags.trackingLink,
    cb: () => void
  ) => {
    this.tealiumFlags$
      .pipe(
        take(1),
        filter((flags) => flags[flag]),
        tap(() => cb())
      )
      .subscribe();
  };
}

const isWindowUtag = (window: Window): window is WindowWithTealium =>
  !!(window as WindowWithTealium).utag;
const getClickTrackingDebug = (clickEventDto: ClickEventDto): string =>
  `\n - name: ${clickEventDto.event_name}\n - type: ${clickEventDto.event_type}\n - data-test: ${
    clickEventDto.event_component_type
  }\n - innerText: ${clickEventDto.event_link_text}\n - url: ${
    (
      clickEventDto as {
        event_target_url_full: string;
      }
    )?.event_target_url_full
  }\n - detail: ${clickEventDto.event_details}`;
