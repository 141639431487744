import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';

export interface RouteRedirection {
  from: string;
  to: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountProtectionService {
  private unAuthorizedRoute: RouteRedirection[] = [
    // redirect on account deselection for the whole creation process
    { from: '/vacancy/publish/', to: AppConfig.routes.vacancy },
    {
      from: `/${AppConfig.routes.user}/${AppConfig.routes.new}`,
      to: `/${AppConfig.routes.user}/${AppConfig.routes.list}`,
    },
    {
      from: `/${AppConfig.routes.credits}/${AppConfig.routes.productsOptions}`,
      to: AppConfig.routes.vacancy,
    },
  ];
  constructor(public router: Router) {}

  checkCurrentUrl(url: string) {
    const unAuthorizedRoute = this.unAuthorizedRoute.find(({ from }) =>
      url.includes(from)
    );

    if (unAuthorizedRoute) {
      this.router.navigate([unAuthorizedRoute.to]);
    }
  }
}
