import { CreditRedemptionViewModel } from '@mkp/shared/data-access';
import { createSelector } from '@ngrx/store';
import { isFuture, parseISO } from 'date-fns';
import { creditRedemptionFeature } from './credit-redemption.reducer';

export const { selectFetchState, selectEntities } = creditRedemptionFeature;

// get the active credit redeem of a vacancy
export const getActiveCreditRedeem = (vacancyId: string) =>
  createSelector(getCreditRedeemsFromVacancy(vacancyId), (credits) =>
    credits.find((credit) => credit?.end && isFuture(parseISO(credit.end)) && !credit.stoppedAt)
  );

// get all the credits redeem of a vacancy and sort them by creation date DESC (latest first)
export const getCreditRedeemsSortedFromLatest = (vacancyId: string) =>
  createSelector(getCreditRedeemsFromVacancy(vacancyId), (credits) =>
    credits ? credits.sort(sortByStartDateDesc) : null
  );

// if there is an active credit : get it, otherwise get the latest one
export const getRelevantCreditRedeem = (vacancyId: string) =>
  createSelector(
    getActiveCreditRedeem(vacancyId),
    getLatestCreditRedeem(vacancyId),
    (current, last) => current ?? last
  );

export const selectRelevantProductCode = (vacancyId: string) =>
  createSelector(
    getRelevantCreditRedeem(vacancyId),
    (creditRedeem) => creditRedeem?.information.productCode
  );

export const selectIsVacancyDraft = (vacancyId: string) =>
  createSelector(getCreditRedeemsFromVacancy(vacancyId), (credits) => credits.length === 0);

const getCreditRedeemsFromVacancy = (vacancyId: string) =>
  createSelector(selectEntities, (entities) =>
    Object.values(entities).filter((credit) => credit?.vacancyId === vacancyId)
  );
const getLatestCreditRedeem = (vacancyId: string) =>
  createSelector(getCreditRedeemsSortedFromLatest(vacancyId), (credits) => credits?.[0]);

const sortByStartDateDesc = (
  current?: CreditRedemptionViewModel,
  previous?: CreditRedemptionViewModel
): number => new Date(previous?.start ?? 0).getTime() - new Date(current?.start ?? 0).getTime();
