import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Benefit } from '@shared/models/benefit.model';
import { ResourceService } from '@shared/services/resource.service';
import { BenefitSerializer } from '@shared/interfaces/benefit-serializer.interface';

@Injectable({
  providedIn: 'root',
})
export class BenefitService extends ResourceService<Benefit> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.api.core, 'benefit', new BenefitSerializer());
  }
}
