import { AccountDto } from '../account.dto';
import { map, Observable, OperatorFunction, pipe, switchMap, take } from 'rxjs';
import { Account } from '../account.model';
import { LegalEntity, LegalEntityResource, mapLegalEntityDtosToLegalEntities } from '@mkp/legal-entity/data-access';
import { mapAccountDtoToAccount } from '../account.mapper';

export const addLegalEntityToAccountDto = (
  legalEntityResource: LegalEntityResource
): OperatorFunction<AccountDto, Account> =>
  pipe(
    switchMap((account: AccountDto) =>
      fetchLegalEntitiesAndMapToAccount([account], legalEntityResource)
    ),
    map((accounts) => accounts[0])
  );

export const addLegalEntityToAccountsDto = (
  legalEntityResource: LegalEntityResource
): OperatorFunction<AccountDto[], Account[]> =>
  pipe(
    switchMap((accounts: AccountDto[]) =>
      fetchLegalEntitiesAndMapToAccount(accounts, legalEntityResource)
    )
  );

export const isAccount = (account: unknown): account is Account =>
  account != null &&
  (account as Account).id !== undefined &&
  (account as Account).legalEntity !== undefined;

const fetchLegalEntitiesAndMapToAccount = (
  accounts: AccountDto[],
  legalEntityResource: LegalEntityResource
): Observable<Account[]> =>
  legalEntityResource.getWithQuery(getLegalEntityFilter(accounts)).pipe(
    map(({ _embedded }) => _embedded?.results ?? []),
    map(mapLegalEntityDtosToLegalEntities),
    map((legalEntities) => mapAccountDtosToAccounts(accounts, legalEntities)),
    take(1)
  );

export const mapAccountDtosToAccounts = (
  accounts: AccountDto[],
  legalEntities: LegalEntity[]
): Account[] => {
  const legalEntityMap = new Map(legalEntities.map((legalEntity) => [legalEntity.id, legalEntity]));
  return accounts.map((account) =>
    mapAccountDtoToAccount(account, legalEntityMap.get(account.legalEntityId) as LegalEntity)
  );
};

const getLegalEntityFilter = (accounts: AccountDto[]): { filter: string } | {} =>
  accounts.length ? { filter: accounts.map((account) => `id==${account.legalEntityId}`).join(',') } : {};

