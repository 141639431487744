import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { RouterLink } from '@angular/router';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { ButtonComponent } from '../button/button.component';
import { FeedbackBodyComponent } from './feedback-body/feedback-body.component';
import { FeedbackFooterComponent } from './feedback-footer/feedback-footer.component';
import { FeedbackHeaderComponent } from './feedback-header/feedback-header.component';
import { FeedbackProgressBarComponent } from './feedback-progress-bar/feedback-progress-bar.component';

@NgModule({
  declarations: [
    FeedbackBodyComponent,
    FeedbackHeaderComponent,
    FeedbackFooterComponent,
    FeedbackProgressBarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    ButtonComponent,
    AssetComponent,
    DataTestDirective,
    RouterLink,
  ],
  exports: [
    FeedbackBodyComponent,
    FeedbackHeaderComponent,
    FeedbackFooterComponent,
    FeedbackProgressBarComponent,
  ],
})
export class FeedbackModule {}
