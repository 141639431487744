import { Cart } from '@cart/models/cart.model';
import { createAction, props } from '@ngrx/store';

export const fetchCartSuccess = createAction(
  '[Cart API] Cart fetched successfully',
  props<{ cart: Cart }>()
);

export const fetchCart = createAction('[Cart API] Get Cart', props<{ cartGetInput: string }>());

export const fetchCartFailure = createAction(
  '[Cart API] Error when fetching Cart',
  props<{ error: { message: string } }>()
);

export const createCart = createAction(
  '[Cart API] Create Cart',
  props<{ productCode: string; coupon?: string }>()
);

export const createCartSuccess = createAction(
  '[Cart API] Cart received successfully',
  props<{ cart: Cart; vacancyId: string }>()
);

export const createCartFailure = createAction(
  '[Cart API] Error when receiving the cart',
  props<{ error: { message: string } }>()
);

export const addCouponToCartSuccess = createAction(
  '[Cart API] Add Coupon to Cart successfully',
  props<{ cart: Cart }>()
);
export const addCouponToCartFailure = createAction(
  '[Cart API] Error when add coupon to the cart',
  props<{ error: { message: string } }>()
);
export const removeCouponFromCartSuccess = createAction(
  '[Cart API] Remove Coupon to Cart successfully',
  props<{ cart: Cart }>()
);
export const removeCouponFromCartFailure = createAction(
  '[Cart API] Error when remove coupon to the cart',
  props<{ error: { message: string } }>()
);
