import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/store';
import { environment } from '@env/environment';
import { Account } from '@mkp/account/data-access';
import { selectSelectedAccount } from '@mkp/account/state';
import { ErrorService } from '@mkp/core/feature-errors';
import { selectIsDefault } from '@mkp/debug/state';
import { LanguageIso } from '@mkp/shared/data-access';
import { CUSTOM_AUTHORIZATION_TOKEN } from '@mkp/shared/util-auth';
import { Store } from '@ngrx/store';
import { AuthFacade, Token } from '@mkp/auth/util';
import { selectInterfaceLanguage } from '@store/selectors';
import { EMPTY, Observable, concat, filter, interval, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, map, switchMap, take, tap } from 'rxjs/operators';

const DEFAULT_MAX_RETRY = 3;
const NB_SHORT_POLLS = 3;
const SHORT_POLL_INTERVAL = 500;
const LONG_POLL_INTERVAL = 3000;

const { network } = environment;

interface HeaderOptions {
  authorization: boolean;
  acceptLanguage: boolean;
  accept: null; // No case found for this property
  contentType: null; // No case found for this property
  externalMandatorId: string;
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private publicAPI: string[] = [
    '/assets',
    '/benefit',
    '/check-email',
    '/media',
    '/meta',
    '/onboarding',
    network.jobcloud,
  ];

  private authExceptionURLs = new Map<number, string[]>([
    [304, ['/company-profile']],
    [400, ['/check-email']],
    [401, ['/shop-api', '/account']],
    [403, ['/api/account', '/company-profile']],
    [404, ['/user', '/check-is-claimed', '/check-email']],
    [500, ['/commercial-register-entry', '/uid-register-entry', '/openai']],
  ]);

  constructor(
    private readonly auth: AuthFacade,
    private readonly store: Store<State>,
    private readonly errorService: ErrorService
  ) {}

  intercept(req: HttpRequest<Request>, next: HttpHandler): Observable<HttpEvent<Response>> {
    const maxRetry = req.headers.has('maxRetry')
      ? Number(req.headers.get('maxRetry'))
      : DEFAULT_MAX_RETRY;
    let nbAttempt = 0;

    return this.getPollingClock(maxRetry).pipe(
      // we use concatMap to queue requests one after another
      concatMap(() =>
        this.auth.isAuthenticated$.pipe(
          tap(() => (nbAttempt += 1)),
          switchMap((isAuthenticated) => (isAuthenticated ? this.auth.token$ : of(null))),
          switchMap((token: Token | null) =>
            isTokenExpired(token) ? this.getNewToken() : of(token)
          ),
          map((token: Token | null) => token?.stringToken),
          switchMap((stringToken) => this.buildRequest$(req, stringToken)),
          switchMap((request) => next.handle(request)),
          // we need to swallow errors in the substream until we reach the last attempt
          catchError((err: unknown) => (nbAttempt >= maxRetry ? throwError(() => err) : EMPTY))
        )
      ),
      // if one request succeeds : complete the observable
      take(1),
      catchError((error: unknown) => this.requestError(error as HttpErrorResponse))
    );
  }

  private buildRequest$(
    req: HttpRequest<Request>,
    stringToken: string | null | undefined
  ): Observable<HttpRequest<Request>> {
    return this.requestHeaders(req.clone(), stringToken).pipe(
      map((cloneReq) => this.requestResponseType(cloneReq))
    );
  }

  private requestHeaders(
    cloneReq: HttpRequest<Request>,
    stringToken: string | null | undefined
  ): Observable<HttpRequest<Request>> {
    const authorization = !this.publicAPI.filter((key) => cloneReq.url.indexOf(key) > -1).length;
    const acceptLanguage = cloneReq.url.indexOf('meta') > -1 || cloneReq.url.indexOf('ats') > -1;
    const accept = null; // No case found for this property
    const contentType = null; // No case found for this property

    const options: Omit<HeaderOptions, 'externalMandatorId'> = {
      authorization,
      acceptLanguage,
      accept, // No case found for this property
      contentType, // No case found for this property
    };

    return this.store.select(selectSelectedAccount).pipe(
      take(1),
      map((selectedAccount) => ({
        ...options,
        externalMandatorId: getExternalMandatorId(cloneReq.url, selectedAccount),
      })),
      map((options) =>
        cloneReq.clone({ headers: this.buildHeaders(cloneReq, options, stringToken) })
      )
    );
  }

  private buildHeaders(
    cloneReq: HttpRequest<Request>,
    options: HeaderOptions,
    stringToken: string | null | undefined
  ): HttpHeaders {
    const url = cloneReq.url;

    const cloneReqHeaders = cloneReq.headers
      .keys()
      .filter((k) => k !== 'maxRetry')
      .reduce((acc, key) => {
        acc[key] = cloneReq.headers.get(key);
        return acc;
      }, {} as Headers);

    const headers = Object.keys(options)
      .filter((option) => !!options[option])
      .map((option) => {
        switch (option) {
          case 'authorization': {
            if (!stringToken) return {};
            const customAuthToken = cloneReq.context.get(CUSTOM_AUTHORIZATION_TOKEN);
            return { Authorization: customAuthToken ? customAuthToken : `Bearer ${stringToken}` };
          }
          case 'contentType': {
            return { 'Content-Type': 'application/json' };
          }
          case 'accept': {
            return { Accept: 'application/json' };
          }
          case 'acceptLanguage': {
            const wildcard = url.indexOf('skill') > -1 || url.indexOf('job-title') > -1;
            const language = !wildcard ? this.getAppLanguage() : '*';
            return { 'Accept-Language': language };
          }
          case 'externalMandatorId': {
            return { externalMandatorId: options[option] };
          }
        }
      })
      .reduce((acc, reduce) => Object.assign(reduce, acc), cloneReqHeaders);

    return new HttpHeaders(headers);
  }

  private getAppLanguage(): LanguageIso {
    let appLanguage = LanguageIso.ENGLISH;
    this.store
      .select(selectInterfaceLanguage)
      .pipe(take(1))
      .subscribe((language) => {
        appLanguage = language;
      });

    return appLanguage;
  }

  private requestResponseType(cloneReq: HttpRequest<Request>): HttpRequest<Request> {
    return cloneReq.url.indexOf('change_password') > -1
      ? cloneReq.clone({ responseType: 'text' })
      : cloneReq;
  }

  private requestError(err: HttpErrorResponse): Observable<never> {
    this.log(err);

    const isException = this.checkIfErrorIsException(err);
    // if error is an exception : throw error. Otherwise add the error to the error stack and continue as if nothing happened
    return isException ? throwError(() => err) : this.errorService.handleError(err);
  }

  private log(err: HttpErrorResponse) {
    this.store
      .select(selectIsDefault)
      .pipe(
        take(1),
        filter((isDefault) => isDefault),
        tap(() => {
          console.log('<> requestError', err);
        })
      )
      .subscribe();
  }

  private checkIfErrorIsException(err: HttpErrorResponse): boolean {
    const exceptionUrls = this.authExceptionURLs.get(err.status);
    return exceptionUrls
      ? exceptionUrls.some((exceptionUrl) => err.url.includes(exceptionUrl))
      : false;
  }

  // we run x short polls, then y long polls so that x + y = maxRetry
  private getPollingClock(maxRetry: number): Observable<number> {
    return concat(
      timer(0, SHORT_POLL_INTERVAL).pipe(take(Math.min(NB_SHORT_POLLS, maxRetry))),
      interval(LONG_POLL_INTERVAL).pipe(take(Math.max(0, maxRetry - NB_SHORT_POLLS)))
    );
  }

  private getNewToken(): Observable<Token> {
    return this.auth.fetchAccessToken();
  }
}

const isTokenExpired = (token: Token | null): boolean => token && token.expiryDate < new Date();
const ERecUrlPaths = [
  'dashboard/messages',
  'dashboard/data',
  'vacancy/applicants/info',
  'vacancy/applications',
  'vacancy/candidates',
];
const getExternalMandatorId = (url: string, selectedAccount: Account | undefined) =>
  ERecUrlPaths.some((path) => !!url.includes(path)) && !!selectedAccount
    ? selectedAccount.id
    : null;
