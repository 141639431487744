import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ScrollPosition } from '../directives/scroll-to-next.directive';

@Injectable()
export class ScrollToNextService {
  currentScrollLeft = 0;
  activePosition$: Observable<ScrollPosition>;
  private readonly activePositionSource = new Subject<ScrollPosition>();

  constructor() {
    this.activePosition$ = this.activePositionSource.asObservable();
  }

  changeActivePosition(position: ScrollPosition) {
    this.activePositionSource.next(position);
  }
}
