import { createAction, props } from '@ngrx/store';
import { Location } from '@shared/models';

export const loadLocationsSuccess = createAction(
  '[Location API] Load Locations Success',
  props<{ locations: Location[] }>()
);
export const loadLocationsFailure = createAction(
  '[Location API] Load Locations Failure',
  props<{ error: { message: string } }>()
);
