import { ProductPlatformGroup } from '@mkp/shared/util-product';
import { UserDisplayType } from '@mkp/user/data-access';
import { TrafficFunnel } from './traffic-funnel.enum';

export const userDisplayTypePlatform: {
  [userDisplayType in UserDisplayType]?: ProductPlatformGroup;
} = {
  [UserDisplayType.CustomerJobScout24]: ProductPlatformGroup.JobScout24,
  [UserDisplayType.CustomerJobs]: ProductPlatformGroup.Jobs,
  [UserDisplayType.CustomerJobUp]: ProductPlatformGroup.JobUp,
};

export const trafficFunnelPlatform: { [funnel in TrafficFunnel]: ProductPlatformGroup } = {
  [TrafficFunnel.JobScout24Free]: ProductPlatformGroup.JobScout24,
  [TrafficFunnel.JobScout24Purchase]: ProductPlatformGroup.JobScout24,
  [TrafficFunnel.JobScout24]: ProductPlatformGroup.JobScout24,
  [TrafficFunnel.JobsFree]: ProductPlatformGroup.Jobs,
  [TrafficFunnel.JobsPurchase]: ProductPlatformGroup.Jobs,
  [TrafficFunnel.JobUp]: ProductPlatformGroup.JobUp,
};

export const platformLogoMapping: { [platform in ProductPlatformGroup]: string } = {
  [ProductPlatformGroup.Jobs]: 'jobs',
  [ProductPlatformGroup.JobScout24]: 'jobscout24',
  [ProductPlatformGroup.JobUp]: 'jobup',
};
