import { Component, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { Product, ProductCode } from '@mkp/shared/util-product';
import { ExplainBenefitsWrapperStore } from './explain-benefits-wrapper.store';

@Component({
  selector: 'mkp-explain-benefits-wrapper',
  template: `<ng-content />`,
  styleUrl: './explain-benefits-wrapper.component.scss',
  standalone: true,
  providers: [ExplainBenefitsWrapperStore],
})
export class ExplainBenefitsWrapperComponent {
  private readonly explainBenefitsWrapperStore = inject(ExplainBenefitsWrapperStore);

  @Input() set product(product: Product) {
    this.explainBenefitsWrapperStore.setProduct(product);
  }

  @Input() set productCode(productCode: ProductCode) {
    this.explainBenefitsWrapperStore.fetchProduct(productCode);
  }

  @Input() set isReadMode(isReadMode: boolean) {
    this.explainBenefitsWrapperStore.setIsReadMode(isReadMode);
  }

  @Output() handleSelectProduct: EventEmitter<Product['code']> = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick() {
    this.explainBenefitsWrapperStore.openExplainBenefitsModal(() =>
      this.handleSelectProduct.emit()
    );
  }
}
