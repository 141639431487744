import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  numberAttribute,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'mds-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrl: './tab-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TabItemComponent {
  @Input()
  set count(count: number | undefined) {
    this._count = count;

    this.tabChanged.emit();
  }

  get count(): number | undefined {
    return this._count;
  }

  @Input()
  set active(active: boolean) {
    this._active = !!active;

    active
      ? this.renderer.addClass(this.elementRef.nativeElement, 'active')
      : this.renderer.removeClass(this.elementRef.nativeElement, 'active');

    this.tabChanged.emit();
    this.cd.detectChanges();
  }

  get active(): boolean {
    return !!this._active;
  }

  @Input()
  set title(title: string) {
    this._title = title;

    this.tabChanged.emit();
  }

  get title(): string {
    return this._title;
  }

  @Input() action!: string;
  @Input({ transform: numberAttribute }) index!: number;
  @Input() dataTest!: string;
  @Input() countLoaded = false;

  @Output() tabChanged = new EventEmitter();
  private _title!: string;
  private _count?: number;
  private _active?: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}
}
