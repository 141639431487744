import { CreditRedemptionViewModel, FREE_TRIAL_COUPON } from '@mkp/shared/data-access';
import { Product, ProductCode } from '../models/product.model';
import { isPaid } from './product.helper';

export const isFreeTrialProduct = (product: Product): boolean =>
  product.coupon === FREE_TRIAL_COUPON && isPaid(product);

export const isFreeProductCode = (
  productCode: ProductCode
): productCode is ProductCode.JOBSOFFER22FREE | ProductCode.JS24FREE | ProductCode.JOBUP24FREE => {
  return [ProductCode.JOBSOFFER22FREE, ProductCode.JS24FREE, ProductCode.JOBUP24FREE].includes(
    productCode
  );
};
export const canProductBeUpgraded = (
  creditRedeem: CreditRedemptionViewModel | undefined
): boolean => {
  const productCode = creditRedeem?.information?.productCode as ProductCode;
  const upgradeableProducts = [
    ProductCode.JS24FREE,
    ProductCode.JOBSOFFER22FREE,
    ProductCode.JS24SOLO,
    ProductCode.JS24BASIC,
    ProductCode.JOBSOFFER22XS,
    ProductCode.JOBSOFFER22S,
    ProductCode.JOBSOFFER22M,
    ProductCode.JOBSOFFER22L,
    ProductCode.JOBUP24FREE,
    ProductCode.JOBUP24BASIC,
    ProductCode.JOBUP24LITE,
    ProductCode.JOBUP24ADVANCED,
    ProductCode.JOBUP24ULTIMATE,
  ];
  return upgradeableProducts.includes(productCode);
};

export const isFreeProduct = (creditRedeem: CreditRedemptionViewModel | undefined): boolean => {
  const productCode = creditRedeem?.information?.productCode as ProductCode;
  return isFreeProductCode(productCode);
};
