import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import {
  BEST_VALUE_PRODUCTS,
  Product,
  ProductCode,
  ProductFeature,
  ProductPlatformGroup,
} from '@mkp/shared/util-product';
import { ProductCalendarLabelComponent } from '@mkp/product/feature-product-shared';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgIf } from '@angular/common';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';

@Component({
  selector: 'mkp-product-sticky-header-card',
  templateUrl: './product-sticky-header-card.component.html',
  styleUrl: './product-sticky-header-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    ProductCalendarLabelComponent,
    NgIf,
    TranslateModule,
    CurrencyPipe,
    DataTestDirective,
  ],
  standalone: true,
})
export class ProductStickyHeaderCardComponent implements OnInit {
  @Input({ required: true }) product!: Product;
  @Input({ required: true }) isReadMode: boolean | undefined;
  @Input({ required: true }) activePlatformGroup: ProductPlatformGroup | undefined;
  @Input() isLoading = false;

  @Output() handleSelectedProduct = new EventEmitter<Product['code']>();

  isBestValue: boolean | undefined;
  calendarFeature: ProductFeature | undefined;

  selectConfig: Button = {
    color: ButtonColor.Primary,
    size: ButtonSize.Small,
    fullWidth: true,
  };

  ngOnInit(): void {
    this.isBestValue = BEST_VALUE_PRODUCTS.includes(this.product.code as ProductCode);
    this.setFeatures();
  }

  private setFeatures() {
    const calendarFeature = this.product.features.find(({ id }) => id.includes('calendar'));
    if (calendarFeature) {
      this.calendarFeature = calendarFeature;
    }
  }
}
