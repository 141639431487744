<div [formGroup]="formGroup" *ngIf="selectedProduct" class="wrapper">
  <mds-select [config]="{ options: options }" formControlName="productCode" />
  <span class="title-s">
    <span class="nobold">{{ selectedProduct.currency }} </span>
    {{ selectedProduct.price.current / 100 | currency: ' ' : 'code' : '1.0-0' : 'fr-CH' }}
  </span>
  <ui-button
    *ngIf="!isReadMode"
    [loading]="isProductLoading === selectedProduct.code"
    [config]="selectConfig"
    (click)="handleSelectedProduct.emit(selectedProduct.code)"
    mkpDataTest="select-cta-comparison-table-btn"
  >
    {{ 'PRODUCT_OFFERING.SELECT_PLAN' | translate }}
  </ui-button>
</div>
