import { UserDisplayType } from '@mkp/user/data-access';
import { ProductPlatformGroup } from '@mkp/shared/util-product';
import { TrafficFunnel } from '../enums';
import { platformLogoMapping, trafficFunnelPlatform, userDisplayTypePlatform } from '@shared/enums';

export const getLogoPlatformClass = (
  funnel: TrafficFunnel | undefined,
  displayType: UserDisplayType | undefined
): string[] => {
  const platform: ProductPlatformGroup | undefined = funnel
    ? getPlatformClassByTraficFunnel(funnel as TrafficFunnel)
    : getPlatformClassByDisplayType(displayType);

  return platform
    ? [platformLogoMapping[platform]]
    : Object.values(platformLogoMapping).map((platformLogo) => platformLogo);
};

export const getPlatformClassByDisplayType = (
  displayType: UserDisplayType | undefined
): ProductPlatformGroup | undefined =>
  displayType ? userDisplayTypePlatform[displayType] : undefined;

export const getPlatformClassByTraficFunnel = (
  funnel: TrafficFunnel | undefined
): ProductPlatformGroup | undefined => (funnel ? trafficFunnelPlatform[funnel] : undefined);

export const getButtonPlatformClass = (
  platform: ProductPlatformGroup | string | undefined
): string[] => [platform ? `button-color-${platform}` : ''];
