import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  inject,
  DestroyRef,
} from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { CarouselControlsComponent } from './carousel-controls/carousel-controls.component';
import { CarouselItemsComponent } from './carousel-items/carousel-items.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ui-carousel',
  standalone: true,
  imports: [AsyncPipe, NgIf, CarouselControlsComponent, CarouselItemsComponent],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewInit {
  private readonly element: ElementRef<HTMLElement> = inject(ElementRef<CarouselComponent>);
  private readonly destroy = inject(DestroyRef);

  @Input() navigation = true;
  @Input() finite = false;

  index = 0;
  collection = [] as Element[];
  width$ = new BehaviorSubject<number>(0);

  ngOnInit(): void {
    this.updateWidth();
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(() => this.updateWidth());
  }

  updateIndex(newIndex: number) {
    if (this.finite && newIndex < this.collection.length && newIndex >= 0) {
      this.index = newIndex;
    } else if (!this.finite && newIndex < 0) {
      this.index = this.collection.length - 1;
    } else if (!this.finite && this.collection.length - 1 < newIndex) {
      this.index = 0;
    } else if (!this.finite) {
      this.index = newIndex;
    }
  }

  updateCollection(collection: Element[]) {
    this.collection = collection;
  }

  private updateWidth(): void {
    this.width$.next(this.element.nativeElement.offsetWidth);
  }
}
