<form [formGroup]="cookiesForm">
  <section>
    <ng-container *ngIf="template === 'info'; else fullDetailsTemplate">
      <h1>{{ 'GDPR.COOKIE-DIALOG.TEXT.TITLE' | translate }}</h1>
      <span
        class="body-s"
        mkpDataTest="gdpr-dialog-privacy-policy"
        [innerHTML]="'GDPR.COOKIE-DIALOG.TEXT.MAIN-TEXT' | translate | gdprLink"
      ></span>
    </ng-container>
  </section>

  <ng-template #fullDetailsTemplate>
    <h1>{{ 'GDPR.COOKIE-DIALOG.TEXT.FULL-TITLE' | translate }}</h1>
    <span
      class="body-s"
      [innerHTML]="'GDPR.COOKIE-DIALOG.TEXT.FULL-MAIN-TEXT' | translate | gdprLink"
    ></span>
    <div class="cookie body-s">
      {{ 'GDPR.COOKIE-DIALOG.LIST.ESSENTIAL-COOKIES' | translate }}
      <mat-checkbox [checked]="true" [disabled]="true" mkpDataTest="gdpr-dialog-strictly-necessary">
        {{ 'GDPR.COOKIE-DIALOG.TEXT.STRICTLY-NECESSARY' | translate }}
      </mat-checkbox>
    </div>
    <div class="cookie body-s">
      {{ 'GDPR.COOKIE-DIALOG.LIST.FUNCTIONAL-COOKIES' | translate }}
      <mat-checkbox formControlName="functional" mkpDataTest="gdpr-dialog-functional-cookies">
        {{ 'GDPR.COOKIE-DIALOG.TEXT.FUNCTIONAL-COOKIES' | translate }}
      </mat-checkbox>
    </div>
    <div class="cookie body-s">
      {{ 'GDPR.COOKIE-DIALOG.LIST.MARKETING-COOKIES' | translate }}
      <mat-checkbox formControlName="marketing" mkpDataTest="gdpr-dialog-marketing">
        {{ 'GDPR.COOKIE-DIALOG.TEXT.MARKETING-COOKIES' | translate }}
      </mat-checkbox>
    </div>
  </ng-template>

  <ui-feedback-footer
    [primaryConfig]="acceptAllButton.config"
    [primaryDataTest]="acceptAllButton.dataTest"
    [secondaryConfig]="secondaryButton[template].config"
    [secondaryDataTest]="secondaryButton[template].dataTest"
    (handlePrimaryClick)="selectAll()"
    (handleSecondaryClick)="handleSecondaryBtnClick()"
  />
</form>
