import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductOptionsEffects } from './effects/product-options.effects';
import * as fromProductOptions from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromProductOptions.featureKey, fromProductOptions.reducer),
    EffectsModule.forFeature([ProductOptionsEffects]),
  ],
})
export class ProductOptionsStateModule {}
