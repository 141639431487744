import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LegalEntitySerializer } from '@company/interfaces/legal-entity-serializer.interface';
import { environment } from '@env/environment';
import { LegalEntity, LegalEntityState } from '@mkp/legal-entity/data-access';
import { ClaimManagementFilterCounts } from '@mkp/legal-entity/feature-claim-management';
import { ApiListEnvelop } from '@shared/models';
import { ResourceService } from '@shared/services/resource.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { escapeDelimiters } from '@mkp/shared/data-access';

/**
 * @deprecated migrate to a resource in the libs folder
 */
@Injectable({
  providedIn: 'root',
})
export class LegalEntityService extends ResourceService<LegalEntity> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.api.core, 'legal-entity', new LegalEntitySerializer());
  }

  checkIsClaimed(legalEntity: Pick<LegalEntity, 'externalId'>): Observable<null> {
    const headers = new HttpHeaders({
      maxRetry: 1,
    });
    return this.httpClient.post<null>(
      `${environment.api.core}/legal-entity/check-is-claimed`,
      {
        cheUid: legalEntity.externalId,
      },
      { headers }
    );
  }

  fetchFilterCounts(searchTerm: string): Observable<ClaimManagementFilterCounts> {
    const states = [
      LegalEntityState.InVerification,
      LegalEntityState.OnHold,
      LegalEntityState.Archived,
      LegalEntityState.Active,
    ];

    const counts = states.map((state) => this.getCount(state, searchTerm));

    return forkJoin(counts).pipe(
      map((counts) => {
        const [inVerification, onHold, unclaimed, active] = counts;
        const all = counts.reduce((a, b) => a + (b ?? 0), 0);
        return {
          inVerification,
          onHold,
          unclaimed,
          active,
          all,
        };
      })
    );
  }

  getCount(legalEntityState: LegalEntityState, searchTerm: string): Observable<number> {
    return this.httpClient
      .get<ApiListEnvelop<LegalEntity>>(`${environment.api.core}/legal-entity`, {
        params: { limit: 0, filter: getFilter(legalEntityState, searchTerm) },
      })
      .pipe(
        map((apiEnvelope) => apiEnvelope.totalCount),
        catchError(() => of(0))
      );
  }
}

const getFilter = (legalEntityState: LegalEntityState, searchTerm: string): string =>
  [`state==${legalEntityState}`, searchTerm ? `name=@${escapeDelimiters(searchTerm)}` : '']
    .filter(Boolean)
    .join(';');
