import { PublicationCalendarBenefit } from '@mkp/publication/feature-publication-store';
import { Currencies } from '@shared/enums';
import { Features } from '../constants/feature-per-product.constant';
import { Price } from './price.model';

export type Product = Omit<
  ProductDto,
  'taxons' | 'associations' | 'attributes' | 'images' | 'averageRating'
> & {
  features: ProductFeature[];
  calendar: PublicationCalendarBenefit;
  longname: string;
  availablePromotions?: string;
  coupon: string;
  currency: Currencies;
  description: string;
  shortName?: string;
  originalPrice: Price;
  price: Price;
  cmsDiscountPrice?: Price;
  // promotion: string;
};

export interface ProductDto {
  code: ProductCode;
  name: string;
  slug: string;
  averageRating: string;
  taxons: ProductTaxon;
  variants: Record<VariantKey, Variant>;
  attributes: Attribute[];
  associations: { [key: string]: ProductDto };
  images: Image[];
}

export type VariantKey = `${ProductDto['code']}-variant-${
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10}`;

export interface ProductTaxon {
  main: string;
  others: string[];
}

export interface Variant {
  code: VariantKey;
  name: string;
  axis: string[];
  nameAxis: { [key: string]: string };
  originalPrice: Price;
  price: Price;
  prices: { [quantity: number]: Price };
  images: Image[];
}

export interface Attribute {
  code: string;
  name: string;
  value: string;
}

export interface Image {
  code: string;
  path: string;
}

// WARNING - order matters in this enum - see sortProducts function
export enum ProductCode {
  JS24FREE = 'JS24FREE', // price 0
  JS24SOLO = 'JS24SOLO',
  JS24PLUS = 'JS24PLUS',
  JS24BASIC = 'JS24BASIC',
  JS24ADVANCED = 'JS24ADVANCED',
  JOBSSTARTER = 'JOBSSTARTER',
  JOBSSTANDARD = 'JOBSSTANDARD', // join integration, not using on UI
  JOBSFLEX = 'JOBSFLEX', // used only for upsell landing page
  JOBSOFFER22FREE = 'JOBSOFFER22FREE',
  JOBSLIMITED = 'JOBSLIMITED',
  JOBSOFFER22XS = 'JOBSOFFER22XS', // aka "Jobs Lite"
  JOBSOFFER22S = 'JOBSOFFER22S', // aka "Jobs Basic"
  JOBSOFFER22M = 'JOBSOFFER22M', // aka "Jobs Advanced"
  JOBSOFFER22L = 'JOBSOFFER22L', // aka "Jobs Ultimate"
  JOBSOFFER22XL = 'JOBSOFFER22XL', // aka "Jobs Ultimate" Plus
  JOBUP24FREE = 'JOBUP24FREE',
  JOBUP24LITE = 'JOBUP24LITE',
  JOBUP24BASIC = 'JOBUP24BASIC',
  JOBUP24ADVANCED = 'JOBUP24ADVANCED',
  JOBUP24ULTIMATE = 'JOBUP24ULTIMATE',
  JOBUP24ULTIMATEPLUS = 'JOBUP24ULTIMATEPLUS',
}

export interface ProductFeature {
  id: Features;
  parent?: string;
  title?: string;
  description?: string;
  translation?: string;
}

export type ActiveTab = 'products' | 'credits';

type ProductPerformanceMapping = Record<ProductCode, string>;

const PERFORMANCE_TRANSLATION_KEY: ProductPerformanceMapping = {
  [ProductCode.JOBSOFFER22S]: 'PERFORMANCE_REACH_JOBSOFFER22S',
  [ProductCode.JOBUP24BASIC]: 'PERFORMANCE_REACH_JOBUP24BASIC',
  [ProductCode.JOBUP24LITE]: 'PERFORMANCE_REACH_JOBUP24LITE',
  [ProductCode.JOBSOFFER22XS]: 'PERFORMANCE_REACH_JOBSOFFER22XS',
  [ProductCode.JOBSOFFER22M]: 'PERFORMANCE_REACH_JOBSOFFER22M',
  [ProductCode.JOBUP24ADVANCED]: 'PERFORMANCE_REACH_JOBUP24ADVANCED',
  [ProductCode.JOBSOFFER22L]: 'PERFORMANCE_REACH_JOBSOFFER22L',
  [ProductCode.JOBUP24ULTIMATE]: 'PERFORMANCE_REACH_JOBUP24ULTIMATE',
  [ProductCode.JOBSOFFER22XL]: 'PERFORMANCE_REACH_JOBSOFFER22XL',
  [ProductCode.JOBUP24ULTIMATEPLUS]: 'PERFORMANCE_REACH_JOBUP24ULTIMATEPLUS',
  [ProductCode.JS24FREE]: 'PERFORMANCE_REACH_JS24FREE',
  [ProductCode.JS24SOLO]: 'PERFORMANCE_REACH_JS24SOLO',
  [ProductCode.JS24PLUS]: 'PERFORMANCE_REACH_JS24PLUS',
  [ProductCode.JS24BASIC]: 'PERFORMANCE_REACH_JS24BASIC',
  [ProductCode.JS24ADVANCED]: 'PERFORMANCE_REACH_JS24ADVANCED',
  [ProductCode.JOBSSTARTER]: 'PERFORMANCE_REACH_JOBSSTARTER',
  [ProductCode.JOBSSTANDARD]: 'PERFORMANCE_REACH_JOBSSTANDARD',
  [ProductCode.JOBSFLEX]: 'PERFORMANCE_REACH_JOBSFLEX',
  [ProductCode.JOBSOFFER22FREE]: 'PERFORMANCE_REACH_JOBSOFFER22FREE',
  [ProductCode.JOBSLIMITED]: 'PERFORMANCE_REACH_JOBSLIMITED',
  [ProductCode.JOBUP24FREE]: 'PERFORMANCE_REACH_JOBUP24FREE',
};

export const getPerformanceKey = (productCode: ProductCode) =>
  PERFORMANCE_TRANSLATION_KEY[productCode];
