<div class="sticky-header product-offering-layout" #stickyLayout>
  <div
    class="sticky-header-placeholder product-offering-placeholder"
    [class.scroll-left]="stickyLayout.scrollLeft > 0"
  >
    <div *ngIf="stickyLayout.scrollLeft > 0" class="scroll-placeholder">
      <ui-button
        mkpDataTest="scroll-products-left-btn"
        [config]="scrollConfig['left']"
        [scrollableElement]="stickyLayout"
        [itemsWrapperElement]="stickyColumns"
        mkpScrollToNext
        [length]="products?.length"
        position="left"
        (click)="handleProductScroll.emit('left')"
      >
        <ui-asset name="arrow_left" />
        <span *ngIf="showLabel">{{ 'PRODUCT_OFFERING.TABLE.SCROLL' | translate }}</span>
      </ui-button>
    </div>
  </div>
  <div
    [class.center-column]="products?.length === 1"
    class="sticky-header-columns product-offering-columns"
    #stickyColumns
  >
    <mkp-product-sticky-header-card
      *ngFor="let product of products"
      [isLoading]="isProductLoading === product.code"
      [product]="product"
      [isReadMode]="isReadMode"
      [activePlatformGroup]="activePlatformGroup"
      (handleSelectedProduct)="handleSelectedProduct.emit($event)"
    />
  </div>
</div>
<section class="scroll-placeholder-wrapper">
  <div
    *ngIf="stickyLayout.scrollWidth - stickyLayout.offsetWidth > stickyLayout.scrollLeft"
    class="scroll-placeholder scroll-placeholder-right"
  >
    <ui-button
      mkpDataTest="scroll-products-right-btn"
      [config]="scrollConfig['right']"
      [scrollableElement]="stickyLayout"
      [itemsWrapperElement]="stickyColumns"
      mkpScrollToNext
      [length]="products?.length"
      (click)="handleProductScroll.emit('right')"
    >
      <ui-asset name="arrow_right" />
      <span *ngIf="showLabel">{{ 'PRODUCT_OFFERING.TABLE.SCROLL' | translate }}</span>
    </ui-button>
  </div>
</section>
