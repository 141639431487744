export enum LanguageIso {
  FRENCH = 'fr-CH',
  GERMAN = 'de-CH',
  ENGLISH = 'en-CH',
}

export enum LanguageCode {
  FRENCH = 'fr',
  GERMAN = 'de',
  ENGLISH = 'en',
}

export interface Language {
  label: string;
  code: LanguageCode;
  iso: LanguageIso;
}

export const isLanguageCode = (language?: string): language is LanguageCode =>
  !!language && Object.values(LanguageCode).includes(language as LanguageCode);
