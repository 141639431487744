import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { productOptionsPageActions } from '@app/features/product-options/store/actions';
import { selectActiveTab } from '@app/features/product-options/store/selectors/product-options.selectors';
import { Button, ButtonColor, ButtonSize } from '@mkp/shared/ui-library';
import {
  PRODUCT_PLATFORM_GROUP_TRANSLATIONS,
  ProductPlatformGroup,
  getSwitchedPlatformFrom,
} from '@mkp/shared/util-product';
import { Store } from '@ngrx/store';

const PLATFORMS: {
  name: ProductPlatformGroup;
  logoSrc: string;
  translationKey: string;
}[] = [
  {
    name: ProductPlatformGroup.Jobs,
    logoSrc: '/assets/logos/jobs-ch.svg',
    translationKey: 'PRODUCT_OFFERING.PLATFORM.JOBS',
  },
  {
    name: ProductPlatformGroup.JobUp,
    logoSrc: '/assets/logos/jobup-logo.svg',
    translationKey: 'PRODUCT_OFFERING.PLATFORM.JOBUP',
  },
  {
    name: ProductPlatformGroup.JobScout24,
    logoSrc: '/assets/logos/jobscout24-logo.svg',
    translationKey: 'PRODUCT_OFFERING.PLATFORM.JOB-SCOUT-24',
  },
];

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProductDisplaySwitchSharedComponent implements OnChanges {
  readonly jobScoutConfig: Button = {
    color: ButtonColor.Secondary,
    size: ButtonSize.Small,
  };

  @Input({ required: true }) activePlatformGroup!: ProductPlatformGroup;
  @Input() withCreditTab = false;

  @Output() handleSwitchOffering = new EventEmitter();

  switchedPlatform!: ProductPlatformGroup;

  store = inject(Store);

  activeTab$ = this.store.select(selectActiveTab);
  platorms = PLATFORMS;
  readonly translations = PRODUCT_PLATFORM_GROUP_TRANSLATIONS;

  ngOnChanges({ activePlatformGroup }: SimpleChanges): void {
    if (activePlatformGroup.currentValue !== activePlatformGroup.previousValue) {
      this.switchedPlatform = getSwitchedPlatformFrom(this.activePlatformGroup);
    }
  }

  handleSwitchPlatform(platform: ProductPlatformGroup): void {
    if (this.withCreditTab) {
      this.store.dispatch(productOptionsPageActions.setActiveTab({ activeTab: 'products' }));
    }
    this.handleSwitchOffering.emit(platform);
  }

  selectCreditTab() {
    this.store.dispatch(productOptionsPageActions.setActiveTab({ activeTab: 'credits' }));
  }
}
