import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Dictionary, EntityAdapter } from '@ngrx/entity';
import { ApiLink } from '@mkp/shared/data-access';
import { IGraphQLPagination } from '@shared/modules/graphql/interfaces/graphql-pagination.interface';
import { createFetchStateSelectors, MemoizedFetchStateSelectors } from '../fetch-state';
import { EntityFeatureState } from './entity-feature-state';

interface MemoizedAdapterSelectors<AppState extends object, Entity extends { id: string }> {
  selectIds: MemoizedSelector<AppState, string[]>;
  selectEntities: MemoizedSelector<AppState, Dictionary<Entity>>;
  selectAll: MemoizedSelector<AppState, Entity[]>;
  selectTotal: MemoizedSelector<AppState, number>;
}

interface MemoizedCommonSelectors<AppState extends object, Entity extends { id: string }> {
  selectTotalCount: MemoizedSelector<AppState, number>;
  selectLinks: MemoizedSelector<AppState, Record<string, ApiLink> | null>;
  selectFilter: MemoizedSelector<AppState, string | null>;
  selectGqlPagination: MemoizedSelector<AppState, IGraphQLPagination | null>;
  selectSelectedEntity: MemoizedSelector<AppState, Entity | null>;
  selectById: (id: string) => MemoizedSelector<AppState, Entity | null>;
}

export type EntityFeatureSelectors<
  AppState extends object,
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error,
> = {
  selectFeatureState: MemoizedSelector<AppState, FeatureState>;
} & MemoizedAdapterSelectors<AppState, Entity> &
  MemoizedCommonSelectors<AppState, Entity> &
  MemoizedFetchStateSelectors<AppState, Error>;

export function createEntityFeatureSelectors<
  AppState extends object,
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error,
>(
  featureKey: string,
  adapter: EntityAdapter<Entity>
): EntityFeatureSelectors<AppState, FeatureState, Entity, Error> {
  const selectFeatureState = createFeatureSelector<FeatureState>(featureKey);
  const adapterSelectors = adapter.getSelectors(selectFeatureState);
  const fetchStateSelectors = createFetchStateSelectors<AppState, FeatureState, Error>(
    selectFeatureState
  );
  const selectEntities = adapterSelectors.selectEntities as MemoizedSelector<
    object,
    Dictionary<Entity>
  >;
  return {
    selectFeatureState,
    // It's necessary to cast adapterSelectors here, because return type of
    // adapter.getSelectors(selectFeatureState) is not defined correctly.
    // Instead of plain selectors, return type should be memoized selectors.
    // https://github.com/ngrx/platform/issues/2751
    selectIds: adapterSelectors.selectIds as MemoizedSelector<object, string[]>,
    selectEntities,
    selectAll: adapterSelectors.selectAll as MemoizedSelector<object, Entity[]>,
    selectTotal: adapterSelectors.selectTotal as MemoizedSelector<object, number>,
    selectTotalCount: createSelector(selectFeatureState, (state) => state.totalCount ?? 0),
    selectLinks: createSelector(selectFeatureState, (state) => state._links),
    selectFilter: createSelector(selectFeatureState, (state) => state.filter),
    selectGqlPagination: createSelector(selectFeatureState, (state) => state.gqlPagination),
    selectSelectedEntity: createSelector(
      selectFeatureState,
      selectEntities,
      ({ selectedId }, entities) => (selectedId != null ? entities[selectedId] ?? null : null)
    ),
    selectById: (id) => createSelector(selectEntities, (entities) => entities[id] ?? null),
    ...fetchStateSelectors,
  };
}
