import { MetadataDictionary } from '@mkp/metadata/data-access';
import { MetadataActions } from '@mkp/metadata/state/actions';
import { createEntityFeature, EntityFeatureState, LoadingState } from '@mkp/shared/util-state';
import { Action, createReducer, on } from '@ngrx/store';
import { appHeaderActions } from '@store/actions';

export interface State extends EntityFeatureState<MetadataDictionary, { message: string }> {
  metadatasList: MetadataDictionary;
}

const {
  featureKey,
  initialState: initialFeatState,
  selectors,
} = createEntityFeature<State, 'metadatasState'>('metadatasState');

const initialState: State = {
  ...initialFeatState,
  metadatasList: {},
};

const GetMetadatasListSuccess = (state: State, payload: MetadataDictionary) => {
  return {
    ...state,
    metadatasList: payload,
    fetchState: LoadingState.LOADED,
  };
};

const GetMetadatasFailure = (state: State, error: { message: string }) => {
  return {
    ...state,
    metadatasList: {},
    fetchState: { error },
  };
};

const _reducer = createReducer(
  initialState,
  on(MetadataActions.getMetadatasList, appHeaderActions.switchAppLanguage, (state) => ({
    ...state,
    fetchState: LoadingState.LOADING,
  })),
  on(MetadataActions.getMetadatasListSuccess, (state, { payload }) =>
    GetMetadatasListSuccess(state, payload)
  ),
  on(MetadataActions.getMetadatasListFailed, (state, { error }) =>
    GetMetadatasFailure(state, error)
  ),
  on(MetadataActions.resetMetadatasStatus, (state) => ({ ...state, fetchState: LoadingState.INIT }))
);

function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}

export { featureKey, initialState, reducer, selectors };
