import { ErrorHandler, Injectable } from '@angular/core';
import { SentryError } from './sentry-error.interface';
import { SentryService } from './sentry.service';

export const CHUNK_LOAD_ERROR_KEYWORD = 'ChunkLoadError';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private readonly sentryService: SentryService) {}

  handleError(error: SentryError) {
    const err = error.originalError || error;
    if (isChunkLoadError(err)) {
      // chunk-load-error is now handled in router-effects, as such we can now emit it as only a warning
      return this.sentryService.captureWarning(err);
    }
    return this.sentryService.captureException(err);
  }
}

export const isChunkLoadError = (error: Error | null | undefined): boolean =>
  !!error?.name?.includes(CHUNK_LOAD_ERROR_KEYWORD);
