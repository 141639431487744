export interface ApiListEnvelop<T> {
  limit?: number;
  offset?: number;
  filter?: any;
  sort?: any[];
  resultCount?: number;
  totalCount: number;
  result?: [];
  results: T[];
  _embedded?: { results: T[] };
  _links?: any;
  _type?: string;
}

export const getEmptyApiListEnvelop = <T>(): ApiListEnvelop<T> => ({
  result: [],
  results: [],
  _embedded: { results: [] },
  totalCount: 0,
});
