<ng-container *ngIf="config">
  <mds-tab-header
    *ngIf="config.showHeader"
    [disabled]="!!config.disabled"
    [tabs]="tabs"
    [size]="config.size ?? TabSize.Small"
    [icon]="config.icon"
    (handleClick)="selectTab($event)"
  />
  <ng-content />
</ng-container>
