import { selectSelectedAccountId } from '@mkp/account/state';
import { createSelector } from '@ngrx/store';
import { applicationFeature } from './application.reducer';

const {
  selectEntities,
  selectAll: selectApplications,
  selectApplicationStatuses,
} = applicationFeature;

export { selectApplications, selectEntities };

export const selectApplicationById = (applicationId: string | undefined) =>
  createSelector(applicationFeature.selectEntities, (applications) =>
    applicationId ? applications[applicationId] : undefined
  );

export const selectApplicationStatusesForSelectedAccount = createSelector(
  selectSelectedAccountId,
  selectApplicationStatuses,
  (selectedAccountId, statuses) =>
    statuses.filter(({ accountId }) => accountId === selectedAccountId)
);
