<div *ngIf="arrows">
  <ui-asset name="chevron_left" mkpDataTest="carousel-left" (click)="slideTo.emit(index - 1)" />
</div>
<div class="indicators" *ngIf="indicators">
  <ui-asset
    name="bullet"
    *ngFor="let item of collection; let i = index"
    [class.active]="index === i"
    (click)="slideTo.emit(i)"
  />
</div>
<div *ngIf="arrows">
  <ui-asset name="chevron_right" mkpDataTest="carousel-right" (click)="slideTo.emit(index + 1)" />
</div>
