import { Injectable } from '@angular/core';
import { AbstractProductDisplayService } from '@mkp/product/feature-product-display';
import {
  getBenefitsFromFeatures,
  getFeaturesFromProducts,
  getPlatformsFromFeatures,
  orderFeatures,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingPlatform,
  removeUnavailableJS24Product,
} from '@mkp/shared/util-product';
import {
  selectActivePlatformGroup,
  selectDisplayedProducts,
  selectFreeDisplayedProduct,
  selectPaidDisplayedProducts,
} from '@product-options/store/selectors/product-options.selectors';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ProductDisplayReadOnlyService extends AbstractProductDisplayService {
  readonly activePlatformGroup$ = this.store.select(selectActivePlatformGroup(''));
  readonly products$ = this.store.select(selectPaidDisplayedProducts(''));
  readonly switchedProducts$ = this.store.select(selectDisplayedProducts('', false));
  readonly displayedProducts$ = this.store
    .select(selectDisplayedProducts(''))
    .pipe(map(removeUnavailableJS24Product));
  readonly freeProduct$ = this.store.select(selectFreeDisplayedProduct(''));

  readonly features$: Observable<ProductOfferingFeature[]> = this.products$.pipe(
    map((products) => getFeaturesFromProducts(products)),
    map((feature) => orderFeatures(feature))
  );
  readonly platforms$: Observable<ProductOfferingPlatform[]> = this.features$.pipe(
    withLatestFrom(this.activePlatformGroup$),
    map(([features, activePlatformGroup]) =>
      getPlatformsFromFeatures(features, activePlatformGroup)
    )
  );
  readonly benefits$: Observable<ProductOfferingBenefit[]> = this.features$.pipe(
    map((f) => getBenefitsFromFeatures(f))
  );
}
