import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { layoutFeature } from './layout.reducer';
import * as layoutEffects from './layout.effect';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [StoreModule.forFeature(layoutFeature), EffectsModule.forFeature([layoutEffects])],
})
export class LayoutStateModule {}
