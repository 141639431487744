import { featureMapping } from '../constants/feature.constant';
import {
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ProductPlatformOther,
} from '../models/product-offering-feature.model';
import { Product, ProductCode, ProductFeature } from '../models/product.model';
import { PublicationProduct } from '../models/publication-product.model';
import {
  getBenefitsFromFeatures,
  getFeaturesFromProducts,
  orderFeatures,
  removeUnavailableJS24Product,
} from './product-offering.helper';
import { getOriginalPrice, isCalendarProductFeature, isPlatformFeature } from './product.helper';

export function toPublicationProducts(
  products: Product[] | undefined
): PublicationProduct[] | undefined {
  // exclude deprecated products
  products = products ? [...removeUnavailableJS24Product(products)] : undefined;
  return products?.map((product) => toPublicationProduct(product));
}

export function toPublicationProduct(product: Product): PublicationProduct {
  return {
    ...product,
    periodPrice: getOriginalPrice(product) / 100,
    benefits: getBenefitsTranslations(product.features),
    toolTipText:
      product.code === ProductCode.JOBSOFFER22XS
        ? featureMapping[ProductOfferingBenefit.Calendar7Jobs].description
        : undefined,
  };
}

export function getPlatformIds(productFeatures: ProductFeature[]): ProductOfferingPlatform[] {
  return productFeatures.filter(isPlatformFeature).map((feature) => feature.id);
}

export function getProductOfferingBenefits(product: Product): ProductOfferingBenefit[] {
  const features = orderFeatures(getFeaturesFromProducts([product]));
  return getBenefitsFromFeatures(features);
}

function getBenefitsTranslations(productFeatures: ProductFeature[]): string[] {
  const benefitsWithoutCalendarAndPlatform = productFeatures
    .filter((feature) => !isCalendarProductFeature(feature) && !isPlatformFeature(feature))
    .map((feature) => feature.title);
  const platformBenefit = getPlatformBenefitTranslation(productFeatures);

  return [platformBenefit, ...(benefitsWithoutCalendarAndPlatform as string[])];
}

function getPlatformBenefitTranslation(productFeatures: ProductFeature[]): string {
  const platforms = getPlatformIds(productFeatures);
  const isJobs = platforms.includes(ProductPlatformGroup.Jobs);
  const isJs24 = platforms.includes(ProductPlatformGroup.JobScout24);
  const isJobUp = platforms.includes(ProductPlatformGroup.JobUp);
  const isJobWinner = platforms.includes(ProductPlatformOther.JobWinner);

  if (isJobs && isJs24 && isJobWinner && isJobUp) {
    return 'PRODUCT.BENEFIT.VISIBILITY_JOBS_JS24_AND_JOB_WINNER';
  }

  if (isJobs && isJs24) {
    return 'PRODUCT.BENEFIT.VISIBILITY_JOBS_AND_JS24';
  }

  if (isJobs) {
    return 'PRODUCT.BENEFIT.VISIBILITY_JOBS';
  }

  if (isJs24) {
    return 'PRODUCT.BENEFIT.VISIBILITY_JS24';
  }

  if (isJobUp) {
    return 'PRODUCT.BENEFIT.VISIBILITY_JOBUP';
  }

  return '';
}
