import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CartReducer } from '@cart/store/reducers';
import { CartEffects } from '@cart/store/effects/cart.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('cartState', CartReducer),
    EffectsModule.forFeature([CartEffects]),
  ],
})
export class CartModule {}
