import { Benefit } from '@shared/models';
import { ObjectMapper } from 'json-object-mapper';

export class BenefitSerializer {
  fromJson(json: any): Benefit {
    const newBenefit: Benefit = {
      id: json.id,
      name: json.name,
      _version: json._version,
    };
    const benefit = ObjectMapper.deserialize(Benefit, newBenefit);
    return benefit;
  }

  toJson(benefit: Benefit): any {
    return {
      id: benefit.id,
      name: benefit.name,
      _version: benefit._version,
    };
  }
}
