import { inject, Pipe, PipeTransform } from '@angular/core';
import { LanguageIso } from '@mkp/shared/data-access';
import { TranslateService } from '@ngx-translate/core';

const urlTypes = ['GTC', 'PP'] as const;

const AppSocialLinks = {
  'en-CH': {
    contactLink: 'https://www.jobcloud.ch/c/en/about/contact/',
    GTCLink: 'https://www.jobcloud.ch/c/en/terms/',
    privacyPolicyLink: 'https://www.jobcloud.ch/c/en/privacy-policy/',
    faqLink: 'https://www.jobcloud.ch/c/en/faq-jobcloud-ai/',
  },
  'fr-CH': {
    contactLink: 'https://www.jobcloud.ch/c/fr-ch/about/contact/',
    GTCLink: 'https://www.jobcloud.ch/c/fr-ch/conditions-generales/',
    privacyPolicyLink: 'https://www.jobcloud.ch/c/fr-ch/protection-des-donnees',
    faqLink: 'https://www.jobcloud.ch/c/fr-ch/pourquoi-jobcloud/faq/pme/',
  },
  'de-CH': {
    contactLink: 'https://www.jobcloud.ch/c/de-ch/about/contact/',
    GTCLink: 'https://www.jobcloud.ch/c/de-ch/agb/',
    privacyPolicyLink: 'https://www.jobcloud.ch/c/de-ch/datenschutzerklarung/',
    faqLink: 'https://www.jobcloud.ch/c/de-ch/warum-jobcloud/faq-tutorials/ku/',
  },
};

@Pipe({
  name: 'gdprLink',
  standalone: true,
})
export class GdprLinkPipe implements PipeTransform {
  translateService = inject(TranslateService);

  transform(text: string): string {
    return urlTypes.reduce(
      (acc, urlType) => acc.replace(`--${urlType}--`, this.makeLink(urlType)),
      text
    );
  }

  private makeLink(urlType: 'GTC' | 'PP'): string {
    const languageIso = this.getLanguageIso();
    return `<a href="${getHref(languageIso, urlType)}" class="gdprLink" target="_blank">`;
  }

  private getLanguageIso(): LanguageIso {
    return (
      (this.translateService.currentLang as LanguageIso) ||
      (this.translateService.getDefaultLang() as LanguageIso)
    );
  }
}

const getHref = (lang: LanguageIso, urlType: 'PP' | 'GTC'): string => {
  const appSocialUrlType = getAppSocialUrlType(urlType);
  return AppSocialLinks?.[lang]?.[appSocialUrlType] ?? '';
};

const getAppSocialUrlType = (urlType: 'PP' | 'GTC'): keyof (typeof AppSocialLinks)['en-CH'] => {
  return urlType === 'PP' ? 'privacyPolicyLink' : 'GTCLink';
};
