// These two regexp are used inside a form to differentiate protocol errors from domain errors
export const URL_REGEXP = new RegExp(
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
    '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
export const PROTOCOL_REGEXP = new RegExp('^(https?:\\/\\/)');

// This regexp is meant to losely detect a valid URL (with protocol or not)
const FULL_URL_REGEXP =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const isUrl = (url: string): boolean => FULL_URL_REGEXP.test(url);

export const getUrlWithoutParameters = (url: string): string => (url ? url.split('?')[0] : '');
