<div [class.show-mobile]="!isTestimonial">
  <!-- images -->
  <div class="carousel-container">
    <ng-container *ngIf="!isTestimonial; else testimonial">
      <ui-carousel>
        <ui-carousel-item class="item" *ngFor="let snap of snapshots; trackBy: trackByFn">
          <img
            alt="slide"
            mkpLazyLoad
            [carousel]="true"
            [src]="snap.src | replacePicture: path : language"
          />
        </ui-carousel-item>
      </ui-carousel>
    </ng-container>
  </div>
</div>

<ng-template #testimonial>
  <ui-carousel>
    <!-- data -->
    <ui-carousel-item class="item" *ngFor="let testimonial of content; trackBy: trackByFn">
      <div class="cms-testimonial-logo">
        <img [src]="testimonial?.testimonial_logo" alt="testimonial" />
      </div>
      <p class="cms-p body-default bold cms-testimonial-content">
        {{ testimonial?.testimonial_copy }}
      </p>
      <div class="cms-testimonial-infos">
        <span class="cms-display-block cms-testimonial-infos-name body-s bold"
          >{{ testimonial?.testimonial_author }}
        </span>
        <span class="cms-display-block cms-testimonial-infos-company body-s"
          >{{ testimonial?.testimonial_author_company }}
        </span>
      </div>
    </ui-carousel-item>
  </ui-carousel>
</ng-template>
<img
  *ngIf="!isTestimonial && (breakpointObserverService.isMobile$ | async) === false"
  class="candidate-hires img-shadow"
  alt="jobcloud-candidate-page"
  [src]="'candidate-hires' | replacePicture: path : language"
/>
