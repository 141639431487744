import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { retryWhen, tap, take, debounceTime, map } from 'rxjs/operators';
import { Observable, noop } from 'rxjs';

// TODO: ECOM health check; migrate to graphql
@Injectable({
  providedIn: 'root',
})
export class UpgradeService {
  public retryCount = 20;
  private retryIntervalMs = 300;

  constructor(private http: HttpClient) {}
  checkAuth(): Observable<boolean> {
    return this.http.get(`${environment.api.ecom}/me`).pipe(
      debounceTime(this.retryIntervalMs),
      map((val) => !!val),
      retryWhen((err) => {
        let errors = 0;
        return err.pipe(
          tap((err) => {
            errors++;
            if (errors === this.retryCount) {
              throw new Error(err);
            }
            return noop();
          }),
          take(this.retryCount)
        );
      })
    );
  }
}
