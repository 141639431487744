import { Environment } from '@env/environment.model';
type EnvironmentName = Environment['name'];

const isEnvNameInArray = (environment: Environment, environmentNames: EnvironmentName[]) =>
  environmentNames.includes(environment.name);

// debug is not active on stage or prod
export const getIsDebugActive = (environment: Environment) =>
  isEnvNameInArray(environment, ['local', 'dev', 'qa']);
// on stage: debug menu is inaccessible but the sync reducer remains active. So cypress and devs can tweak debug through localStorage
export const getShouldSyncDebug = (environment: Environment) =>
  isEnvNameInArray(environment, ['local', 'dev', 'qa', 'stage']);
// on prod: debug and feature flags should be static (locked to initialState)
export const getShouldLockDebug = (environment: Environment) =>
  isEnvNameInArray(environment, ['prod']);
