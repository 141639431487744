import { environment } from '@env/environment';
import { Environment } from '@env/environment.model';

export interface MetaTag {
  name: string;
  content: string;
}
export const AppMetaTag: { [key in Exclude<Environment['name'], 'local' | 'qa'>]: MetaTag[] } = {
  dev: [
    {
      name: 'description',
      content: 'META_DESCRIPTION_DEV',
    },
    {
      name: 'creator',
      content: 'META_CREATOR_DEV',
    },
    {
      name: 'application-name',
      content: 'META_NAME_DEV',
    },
    {
      name: 'keywords',
      content: 'META_KEYWORDS_DEV',
    },
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
  stage: [
    {
      name: 'description',
      content: 'META_DESCRIPTION_DEV',
    },
    {
      name: 'creator',
      content: 'META_CREATOR_DEV',
    },
    {
      name: 'application-name',
      content: 'META_NAME_DEV',
    },
    {
      name: 'keywords',
      content: 'META_KEYWORDS_DEV',
    },
    {
      name: 'robots',
      content: 'noindex',
    },
    {
      name: 'app-version',
      content: environment.version,
    },
    {
      name: 'app-hash',
      content: environment.hash,
    },
  ],
  prod: [
    {
      name: 'description',
      content: 'META_DESCRIPTION',
    },
    {
      name: 'creator',
      content: 'META_CREATOR',
    },
    {
      name: 'application-name',
      content: 'META_NAME',
    },
    {
      name: 'keywords',
      content: 'META_KEYWORDS',
    },
    {
      name: 'robots',
      content: 'index',
    },
    {
      name: 'app-version',
      content: environment.version,
    },
    {
      name: 'app-hash',
      content: environment.hash,
    },
  ],
};
