import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productAlternativeType',
  standalone: true,
})
export class ProductAlternativeTypePipe implements PipeTransform {
  transform(translatedString: string): string {
    return translatedString.split(' ').slice(2).join(' ');
  }
}
