import { productSummaryPageActions } from '@app/features/product-summary/store/actions';
import { ProductOfferingActions } from '@mkp/product/feature-product-offering/actions';
import { PublicationStorePageActions } from '@mkp/publication/feature-publication-store/actions';
import {
  ActiveTab,
  Product,
  ProductCode,
  ProductOfferingUpgrade,
  ProductPlatformGroup,
  isProductCode,
} from '@mkp/shared/util-product';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { createReducer, on } from '@ngrx/store';
import {
  productOptionsApiActions,
  productOptionsPageActions,
} from '@product-options/store/actions';

/**
 * Represents the state of the application.
 *
 * @interface State
 */
export interface State {
  products: Product[] | null;
  selectedPlatformGroup: ProductPlatformGroup | null;
  removedCmsProductCodes: ProductCode[];
  productsDiscount: Record<string, number> | null;
  upgrade: ProductOfferingUpgrade | null;
  fetchState: FetchState<{ message: string }>;
  activeTab: ActiveTab;
} // TODO add shopping mode that will improve routing (upgrade, boost, create)

/***** initial state  ****/
export const initialState: State = {
  products: null,
  selectedPlatformGroup: null,
  removedCmsProductCodes: [],
  productsDiscount: null,
  upgrade: null,
  fetchState: LoadingState.INIT,
  activeTab: 'products',
};

export const featureKey = 'productOptionsState';

export const reducer = createReducer(
  initialState,
  on(
    productOptionsPageActions.selectProduct,
    productOptionsPageActions.enter,
    PublicationStorePageActions.enter,
    ProductOfferingActions.enter,
    (state) =>
      state.fetchState === LoadingState.LOADED
        ? state
        : {
            ...state,
            fetchState: LoadingState.LOADING,
          }
  ),
  on(productOptionsApiActions.loadProductOptionsSuccess, (state, { products }) => ({
    ...state,
    products,
    fetchState: LoadingState.LOADED,
  })),
  on(productOptionsApiActions.loadProductOptionsFailure, (state, { error }) => ({
    ...state,
    fetchState: { error },
  })),
  on(
    productOptionsPageActions.enter,
    productOptionsPageActions.switchPlatformGroup,
    (state, { platformGroup }) => ({
      ...state,
      selectedPlatformGroup: platformGroup,
    })
  ),
  on(productOptionsPageActions.removeProductsCmsPage, (state, { productCodes }) => ({
    ...state,
    removedCmsProductCodes: productCodes.filter(isProductCode),
  })),
  on(productSummaryPageActions.resetPlatformGroupPostCheckout, (state) => ({
    ...state,
    selectedPlatformGroup: null,
  })),
  on(productOptionsPageActions.setUpgrade, (state, upgrade) => ({
    ...state,
    upgrade,
  })),
  on(productOptionsPageActions.setActiveTab, (state, { activeTab }) => ({
    ...state,
    activeTab,
  })),
  on(productOptionsPageActions.setProductPriceDiscount, (state, { productsDiscount }) => ({
    ...state,
    productsDiscount,
  }))
);
