export enum VerificationFunnel {
  Email = 'email',
  Phone = 'phone',
  Authenticator = 'authenticator',
}

export enum VerificationStatus {
  Pending = 'pending',
  Verified = 'verified',
  Failed = 'failed',
}

export interface VerifyIdentityParams {
  type: VerificationFunnel;
  status: VerificationStatus;
}

export interface VerifyIdentityState {
  heading: string;
  text: string;
  value: VerificationStatus;
}
