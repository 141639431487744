import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { VacancyResource } from '@mkp/vacancy/data-access';
import { authActions, authWorkspaceActions, boAuthActions } from '@mkp/auth/actions';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  getVacancyId,
  hasVacancyId,
  fetchVacancyFromUrl,
  isBoUserFn,
  getSelectWorkspacePayload,
  ROUTS_WITHOUT_SELECTED_ACCOUNT,
} from './helpers/auth-effects.helpers';
import { selectIsBoUser, selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { of } from 'rxjs';
import { getMessageError } from '@core/models';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { SplashscreenService } from '@core/services';

export const setWorkspaceInitState = createEffect(
  (actions$ = inject(Actions), store = inject(Store), location = inject(Location)) =>
    actions$.pipe(
      ofType(authActions.loadAccountsAfterLoginSuccess),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([, isBoUser]) => !isBoUserFn(isBoUser)),
      map(([{ accounts }]) => ({
        canLoadFromUrl: hasVacancyId(location.path()),
        accounts,
      })),
      map(({ canLoadFromUrl, accounts }) =>
        canLoadFromUrl && accounts.length > 1
          ? authWorkspaceActions.workspaceResourceLoading({
              vacancyId: getVacancyId(window.location.href),
            })
          : authWorkspaceActions.workspaceResourceNotLoading({
              accountCount: accounts.length,
            })
      )
    ),
  { functional: true }
);

export const setBoWorkspaceInitState = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([, isBoUser]) => isBoUserFn(isBoUser)),
      map(() => hasVacancyId(window.location.href)),
      map((canLoadFromUrl) =>
        canLoadFromUrl
          ? authWorkspaceActions.workspaceResourceLoading({
              vacancyId: getVacancyId(window.location.href),
            })
          : authWorkspaceActions.workspaceResourceNotLoading({ accountCount: 0 })
      )
    ),
  { functional: true }
);

export const loadWorkspaceForBoUser = createEffect(
  (actions$ = inject(Actions), vacancyResource = inject(VacancyResource), store = inject(Store)) =>
    actions$.pipe(
      ofType(authWorkspaceActions.workspaceResourceLoading),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([_, isBoUser]) => isBoUserFn(isBoUser)),
      switchMap(([{ vacancyId }]) =>
        fetchVacancyFromUrl(vacancyResource, vacancyId).pipe(
          switchMap(({ accountId }) => of(boAuthActions.loadWorkspace({ accountId }))),
          catchError((error: unknown) =>
            of(
              authWorkspaceActions.workspaceResourceLoadingError({
                error: getMessageError(error, 'loadWorkspaceForBoUser'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

export const loadWorkspaceForUser = createEffect(
  (actions$ = inject(Actions), vacancyResource = inject(VacancyResource), store = inject(Store)) =>
    actions$.pipe(
      ofType(authWorkspaceActions.workspaceResourceLoading),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      filter(([_, isBoUser]) => !isBoUserFn(isBoUser)),
      switchMap(([{ vacancyId }]) =>
        fetchVacancyFromUrl(vacancyResource, vacancyId).pipe(
          switchMap(({ accountId }) =>
            getSelectWorkspacePayload(accountId, store).pipe(
              map((payload) => authActions.workspaceSelected(payload))
            )
          ),
          catchError((error: unknown) =>
            of(
              authWorkspaceActions.workspaceResourceLoadingError({
                error: getMessageError(error, 'loadWorkspaceForUser'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

export const redirectToSelectWorkspace = createEffect(
  (actions$ = inject(Actions), router = inject(Router), location = inject(Location)) =>
    actions$.pipe(
      ofType(authWorkspaceActions.workspaceResourceNotLoading),
      filter(({ accountCount }) => accountCount > 1),
      filter(
        () => !ROUTS_WITHOUT_SELECTED_ACCOUNT.some((route) => location.path().includes(route))
      ),
      tap(() => router.navigate([AppConfig.routes.selectAccountMembership]))
    ),
  { functional: true, dispatch: false }
);

export const hideSplashScreen = createEffect(
  (actions$ = inject(Actions), store = inject(Store), splashscreen = inject(SplashscreenService)) =>
    actions$.pipe(
      ofType(
        authActions.workspaceSelected,
        authActions.loadActiveAccountMembershipsFailed,
        authWorkspaceActions.workspaceResourceNotLoading,
        authWorkspaceActions.workspaceResourceLoadingError,
        boAuthActions.loadWorkspaceCompanyLoadSuccess,
        boAuthActions.loadWorkspaceCompanyLoadError,
        boAuthActions.loadWorkspaceAccountLoadError,
        boAuthActions.loadWorkspaceError
      ),
      take(1),
      concatLatestFrom(() => store.select(selectLoggedInUser).pipe(filter(Boolean))),
      delay(500),
      take(1),
      filter(() => splashscreen.isShown()),
      tap(() => splashscreen.hide())
    ),
  { functional: true, dispatch: false }
);
