import { animate, query, style, transition, trigger } from '@angular/animations';

export const ngIfInOutAnimation = (
  name: string,
  enterTiming: string,
  leaveTiming: string | number = 0
) =>
  trigger(name, [
    transition(':enter', [style({ opacity: 0 }), animate(enterTiming, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(leaveTiming, style({ opacity: 0 }))]),
  ]);

export const ngIfTranslateYAnimation = (
  name: string,
  enterTiming: string | number,
  leaveTiming: string | number,
  direction: 'down' | 'up'
) =>
  trigger(name, [
    transition(':enter', [
      style({ transform: `translateY(${direction === 'down' ? '-100%' : '100%'})` }),
      animate(enterTiming, style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)' }),
      animate(
        leaveTiming,
        style({ transform: `translateY(${direction === 'down' ? '-100%' : '100%'})` })
      ),
    ]),
  ]);

export const ngIfTranslateYDelayedAnimation = (
  name: string,
  enterTiming: string | number,
  leaveTiming: string | number,
  enterDelay: string | number,
  leaveDelay: string | number
) =>
  trigger(name, [
    transition(':enter', [
      style({ opacity: 0, height: 0 }),
      animate(enterTiming, style({ height: '*' })),
      animate(enterDelay, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ height: '*', opacity: 1 }),
      animate(leaveTiming, style({ opacity: 0 })),
      animate(leaveDelay, style({ height: 0 })),
    ]),
  ]);

export const ngIfLeftRightAnimationNavigation = trigger('leftRightAnimation', [
  transition(':enter', [
    style({ width: '0', opacity: 0 }),
    animate('.3s ease-in', style({ width: '*' })),
    animate('.3s ease-in', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ width: '*', opacity: 1 }),
    animate('.3s ease-out', style({ opacity: 0 })),
    animate('.3s ease-out', style({ width: 0 })),
  ]),
]);

export const ngIfElementFadeIn = trigger('elementFadeInAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.3s .3s ease-in', style({ opacity: 1 }))]),
]);

export const ngIfInOutAnimationNavigationHeader = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in'
);
export const ngIfInOutAnimationDrawerBackdrop = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in'
);
export const ngIfInOutAnimationNavigationCompany = ngIfInOutAnimation(
  'inOutAnimation',
  '.4s .2s ease-in'
);

export const ngIfTopDownAnimationStickyHeader = ngIfTranslateYAnimation(
  'topDownAnimation',
  '.3s ease-in-out',
  '.3s ease-in-out',
  'down'
);
export const ngIfTopDownAnimationSalary = ngIfTranslateYDelayedAnimation(
  'topDownAnimation',
  '.2s ease-in-out',
  '.2s ease-in-out',
  '.3s ease-in-out',
  '.3s ease-in-out'
);
export const ngIfTopUpAnimationSGdprBanner = ngIfTranslateYAnimation(
  'topUpAnimation',
  '.4s .2s ease-in-out',
  0,
  'up'
);

export const routeTransition = trigger('routeTransition', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':enter', [animate('0.3s 0.6s', style({ opacity: 1 }))], { optional: true }),
  ]),
]);
