import {
  AfterViewInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  HostListener,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import { combineLatest, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScrollToNextService } from '../services/scroll-to-next.service';

export type ScrollPosition = 'left' | 'right';

@Directive({
  selector: '[mkpScrollToNext]',
  standalone: true,
})
export class ScrollToNextDirective implements OnInit, AfterViewInit {
  @Input() length = 0;
  @Input() position: ScrollPosition = 'right';
  @Input() scrollableElement!: HTMLDivElement;
  @Input() itemsWrapperElement!: HTMLDivElement;

  private readonly destroy = inject(DestroyRef);

  constructor(
    private scrollToNextService: ScrollToNextService,
    private cd: ChangeDetectorRef,
    private breakpointObserverService: BreakpointObserverService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.scrollToNextService.activePosition$,
      this.breakpointObserverService.isTabletPortrait$,
    ])
      .pipe(
        filter(([position]) => position === this.position),
        takeUntilDestroyed(this.destroy)
      )
      .subscribe(([position, isTabletPortrait]) => {
        const noToDisplay = isTabletPortrait ? 1 : 2; // number of products to display

        this.scrollToNextProducts(position, noToDisplay);
      });
  }

  ngAfterViewInit() {
    // set the initial scroll for the sticky header in case the user has already scrolled in the table and vice versa
    this.scrollableElement.scrollLeft = this.scrollToNextService.currentScrollLeft;
    setTimeout(() => {
      this.cd.markForCheck();
    });
  }

  /*
    when the user clicks the scroll button,
    the sticky header and the table are notified that the scroll position needs to be changed
  */
  @HostListener('click')
  scrollTo() {
    this.scrollToNextService.changeActivePosition(this.position);
  }

  // scroll to view next products
  scrollToNextProducts(position: ScrollPosition, noToDisplay: number) {
    const scrollWidth = noToDisplay * (this.itemsWrapperElement.scrollWidth / this.length); // display 1 by 1 for tablet portrait, 2 by 2 products for the rest
    const currentScrollLeft = this.scrollableElement.scrollLeft;

    this.scrollToNextService.currentScrollLeft = this.scrollableElement.scrollLeft =
      position === 'left' ? currentScrollLeft - scrollWidth : currentScrollLeft + scrollWidth;
  }
}
