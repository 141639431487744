import { UpdateStr } from '@ngrx/entity/src/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountMembership, AccountMembershipPatch } from '@mkp/account-membership/data-access';
import { QueryOptions } from '@shared/models';

export const accountMembershipActions = createActionGroup({
  source: 'Account Membership',
  events: {
    Reset: emptyProps(),
    'Load Account Memberships': props<{ query: QueryOptions }>(),
    'Load Account Memberships Success': props<{ accountMemberships: AccountMembership[] }>(),
    'Load Account Memberships Failure': props<{ error: { message: string } }>(),
    'Load More Account Memberships': props<{ query: QueryOptions }>(),
    'Load More Account Memberships Success': props<{
      accountMemberships: AccountMembership[];
      _links: any;
      filter: any;
      totalCount: any;
    }>(),
    'Load More Account Memberships Failure': props<{ error: { message: string } }>(),
    'Create Account Membership': props<{ accountId: string; userId: string }>(),
    'Create Account Membership Success': props<{ accountMembership: AccountMembership }>(),
    'Create Account Membership Failure': props<{ error: { message: string } }>(),
    'Patch Account Membership State': props<{ patch: AccountMembershipPatch }>(),
    'Patch Account Membership State Success': props<UpdateStr<AccountMembership>>(),
    'Patch Account Membership State Failure': props<{ error: { message: string } }>(),
  },
});
