<button
  mat-flat-button
  class="ui-button"
  [class]="classList"
  [type]="type"
  [disabled]="disabled"
  [disableRipple]="config?.disableRipple"
  (focus)="handleFocus.emit($event)"
>
  @if (translate) {
    {{ translate | translate: translateParams }}
  } @else {
    <ng-content />
  }
</button>
