import { Injectable } from '@angular/core';
import { UUID } from '@mkp/shared/data-access';

export interface LocalStorageDashboardItemDto {
  id: string;
  createdAt: string;
}

interface LocalStorageDashboardDataDto {
  accounts: LocalStorageDashboardItemDto[];
  vacancies: LocalStorageDashboardItemDto[];
}

export interface LocalStorageDashboardItem {
  id: UUID;
  createdAt: Date;
}

interface LocalStorageDashboardData {
  accounts: LocalStorageDashboardItem[];
  vacancies: LocalStorageDashboardItem[];
}

@Injectable({ providedIn: 'root' })
export class BoDashboardLocalStorageService {
  get(): LocalStorageDashboardData {
    const str = localStorage.getItem(LOCALSTORAGE_DASHBOARD_KEY);
    const state = str != null && str.length ? JSON.parse(str) : null;

    return isLocalStorageDashboardData(state) ? mapDashboardData(state) : defaultDashboardData;
  }
  save(key: keyof LocalStorageDashboardData, newItems: LocalStorageDashboardItem[]): void {
    const localeStorageData = this.get();
    localeStorageData[key] = newItems;
    localStorage.setItem(LOCALSTORAGE_DASHBOARD_KEY, JSON.stringify(localeStorageData));
  }
}

export const defaultDashboardData: LocalStorageDashboardData = {
  accounts: [],
  vacancies: [],
};

const mapDashboardData = (
  localstorageDataDto: LocalStorageDashboardDataDto
): LocalStorageDashboardData => ({
  accounts: localstorageDataDto.accounts.map(mapDashboardItem),
  vacancies: localstorageDataDto.vacancies.map(mapDashboardItem),
});
const LOCALSTORAGE_DASHBOARD_KEY = 'jc_bo_dashboardState';
const isLocalStorageDashboardData = (
  state: { [key: string]: any } | null
): state is LocalStorageDashboardDataDto =>
  state != null && Array.isArray(state['accounts']) && Array.isArray(state['vacancies']);
const mapDashboardItem = ({
  id,
  createdAt,
}: LocalStorageDashboardItemDto): LocalStorageDashboardItem => ({
  id,
  createdAt: new Date(createdAt),
});
