import { Injectable } from '@angular/core';
import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { Observable, map } from 'rxjs';
import { toViewModel } from './vacancy.mapper';
import { VacancyDto, VacancyModel } from './vacancy.models';

export type VacancyList = Pick<CoreListEnvelop<VacancyModel>, 'totalCount' | 'limit' | 'offset'> & {
  vacancies: VacancyModel[];
};

@Injectable({ providedIn: 'root' })
export class VacancyResource extends BaseHttpResource<VacancyDto, CoreListEnvelop<VacancyDto>> {
  constructor() {
    super('vacancy');
  }

  list(): Observable<VacancyList> {
    return this.http.get<CoreListEnvelop<VacancyDto>>(this.url).pipe(
      map(({ limit, offset, totalCount, _embedded: { results } }) => ({
        limit,
        offset,
        totalCount,
        vacancies: results.map((vacancyDto) => toViewModel(vacancyDto)),
      }))
    );
  }
}
