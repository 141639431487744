import { Language, LanguageCode, LanguageIso } from './language.model';

export const DEFAULT_LANGUAGE: Omit<Language, 'label'> = {
  code: LanguageCode.GERMAN,
  iso: LanguageIso.GERMAN,
};

export const APP_LANGUAGES: Language[] = [
  {
    label: 'Deutsch',
    code: LanguageCode.GERMAN,
    iso: LanguageIso.GERMAN,
  },
  {
    label: 'Français',
    code: LanguageCode.FRENCH,
    iso: LanguageIso.FRENCH,
  },
  {
    label: 'English',
    code: LanguageCode.ENGLISH,
    iso: LanguageIso.ENGLISH,
  },
];
