import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as vacancyEffects from '@vacancy/store/effects/vacancy.effects';
import * as vacancyTrackingEffects from '@vacancy/store/effects/vacancy-tracking.effects';
import { vacancyStore } from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(vacancyStore.featureKey, vacancyStore.reducer),
    EffectsModule.forFeature([
      vacancyEffects.VacancyEffects,
      vacancyTrackingEffects.VacancyTrackingEffects,
    ]),
  ],
})
export class VacancyStateModule {}
