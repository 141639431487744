import { Injectable } from '@angular/core';
import { CompanyProfileSerializer } from '@company/interfaces';
import { CompanyProfile } from '@company/models';
import {
  MUTATION_UPDATE_COMPANY_PROFILE,
  QUERY_GET_COMPANY_PROFILE,
} from '@company/schemas/company-profile-schema';
import { QUERY_GET_COMPANY_PROFILE_LIST } from '@company/schemas/company.schema';
import {
  MUTATION_ADD_PROFILE_LOCATION,
  MUTATION_EDIT_PROFILE_LOCATION,
} from '@company/schemas/location-schema';
import { ApiLink, UUID } from '@mkp/shared/data-access';
import { Location, QueryOptions, toQueryString } from '@shared/models';
import { BaseApolloService } from '@shared/modules/graphql/services/base-apollo.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyLocationService } from '../services/company-location.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyResource {
  constructor(
    private companyLocationService: CompanyLocationService,
    private readonly baseApolloService: BaseApolloService,
    private readonly companyProfileSerializer: CompanyProfileSerializer
  ) {}

  getCompany(companyId: UUID): Observable<CompanyProfile> {
    return (
      this.baseApolloService.watchQuery(QUERY_GET_COMPANY_PROFILE, {
        id: companyId,
      }) as Observable<{ data: { companyProfileById } }>
    ).pipe(
      map(({ data: { companyProfileById: company } }) =>
        this.companyProfileSerializer.fromJson(company)
      ),
      take(1)
    );
  }

  getCompanies(query: QueryOptions): Observable<{
    companyProfiles: CompanyProfile[];
    totalCount: number;
    _links: { [key: string]: ApiLink };
    filter: string;
  }> {
    return (
      this.baseApolloService.watchQuery(QUERY_GET_COMPANY_PROFILE_LIST, {
        query: toQueryString(query),
      }) as Observable<{ data: { companyProfiles } }>
    ).pipe(
      map(({ data: { companyProfiles } }) => {
        const { totalCount, filter, _links, results } = companyProfiles;

        return {
          companyProfiles: results.map(this.companyProfileSerializer.fromJson),
          totalCount,
          filter,
          _links,
        };
      }),
      take(1)
    );
  }

  updateCompany(
    company: Partial<CompanyProfile> & Pick<CompanyProfile, 'id' | '_version'>
  ): Observable<CompanyProfile> {
    return this.baseApolloService
      .mutate<
        { updateCompanyProfile: CompanyProfile },
        { id: string; companyProfile: Partial<CompanyProfile> }
      >(MUTATION_UPDATE_COMPANY_PROFILE, {
        id: company.id,
        companyProfile: company,
      })
      .pipe(
        map(({ data: { updateCompanyProfile } }) =>
          this.companyProfileSerializer.fromJson(updateCompanyProfile)
        )
      );
  }

  createCompanyLocation(location: Location): Observable<{ companyId: UUID }> {
    return this.baseApolloService
      .mutate<
        { addProfileLocation: Location },
        { location: Location }
      >(MUTATION_ADD_PROFILE_LOCATION, { location })
      .pipe(
        map(
          ({
            data: {
              addProfileLocation: { companyProfileId: companyId },
            },
          }) => ({ companyId })
        )
      );
  }

  updateCompanyLocation(location: Location): Observable<{ companyId: UUID }> {
    return this.baseApolloService
      .mutate<
        { editProfileLocation: Location },
        { location: Location }
      >(MUTATION_EDIT_PROFILE_LOCATION, { location })
      .pipe(
        map(
          ({
            data: {
              editProfileLocation: { companyProfileId: companyId },
            },
          }) => ({ companyId })
        )
      );
  }

  deleteCompanyLocation(id: string): Observable<unknown> {
    return this.companyLocationService.delete(id);
  }
}
