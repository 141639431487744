import { Injectable } from '@angular/core';
import { AccountMembership, AccountMembershipPatch } from './account-membership.model';
import { AccountMembershipDto, AccountMembershipPatchDto } from './account-membership.dto';

@Injectable({ providedIn: 'root' })
export class AccountMembershipMapper {
  fromJson({
    id,
    userId,
    accountId,
    state,
    _links,
    _version,
    _embedded: { account, user },
  }: AccountMembershipDto): AccountMembership {
    return {
      id,
      userId,
      accountId,
      isActive: getIsActive(state),
      canToggleActivation: getCanToggleActivation(_links),
      _version,
      account,
      user,
    };
  }

  toJson({ id, _version, isActive }: AccountMembershipPatch): AccountMembershipPatchDto {
    return {
      id,
      state: isActive ? 'ACTIVE' : 'INACTIVE',
      _version,
    };
  }
}

const getIsActive = (state: string): boolean => state === 'ACTIVE';
const getCanToggleActivation = (_links: AccountMembershipDto['_links']): boolean =>
  !!_links?.update?.attributes?.properties?.some((property) => property === 'state');
