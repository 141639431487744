import { gql } from '@apollo/client/core';
import { AddressFragments } from './address-schema';
import { CompanyApiLinkFragments } from './api-link-schema';
import { CompanyProfileFragments } from './company-profile-schema';
import { LocationFragments } from './location-schema';

const CompanyFragments = {
  companyProfileList: gql`
    fragment companyProfileListFields on CompanyProfileList {
      limit
      offset
      filter
      sort
      resultCount
      totalCount
      _links {
        ...companyLinksFields
      }
    }
    ${CompanyApiLinkFragments.companyLinks}
  `,
};

export const QUERY_GET_COMPANY_PROFILE_LIST = gql`
  query queryGetCompanyProfileList($query: String!) {
    companyProfiles(query: $query) {
      ...companyProfileListFields
      results {
        ...companyProfileFields
        legalEntityName
        legalEntityAddress {
          ...addressFields
        }
        legalEntityState
        legalEntityVersion
        locations {
          ...locationFields
          address {
            ...addressFields
            
          }
        }
        _links {
          ...companyProfileLinksFields
        }
      }
    }
  }
  ${CompanyFragments.companyProfileList}
  ${CompanyProfileFragments.companyProfile}
  ${AddressFragments.address}
  ${LocationFragments.location}
  ${CompanyApiLinkFragments.companyProfileLinks}
`;
