import { ProductCode } from '@mkp/shared/util-product';
import { CreditDto } from '../credit/credit.dto';
import { CreditRedemptionDto } from './credit-redemption.dto';

export const LOCAL_STORAGE_VOUCHER_KEY = 'voucher';
export const FREE_TRIAL_COUPON = 'FREETRIALQ12022';

export type CreditRedemptionTimeUnit = 'DAY' | 'HOUR' | 'MINUTE' | 'SECOND';

export enum CreditRedemptionState {
  Active = 'active',
  Expired = 'expired',
  Stopped = 'stopped',
  Pending = 'pending',
  Inactive = 'inactive',
  Draft = 'draft', // draft? 🧐
}

export interface RefundCredit {
  id: CreditRedemptionDto['id'];
  creditId: CreditRedemptionDto['creditId'];
  _creditVersion: CreditDto['_version'];
  _creditRedeemVersion: CreditRedemptionDto['_version'];
}

export interface CreditRedemptionTimeLeft {
  unit: CreditRedemptionTimeUnit;
  value: number;
}

export interface CreditRedemptionInformation {
  productName: string;
  productCode: ProductCode;
  status: CreditRedemptionState;
  relevantDate: unknown | Date | string;
  timeLeft: CreditRedemptionTimeLeft | null;
}

export type CreditRedemptionStopBody = Pick<CreditRedemptionDto, 'id' | '_version' | 'stoppedAt'>;
