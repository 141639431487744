import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackbarComponent } from './snackbar.component';
import { FeedbackModule } from '../../elements/feedback/feedback.module';

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, BrowserAnimationsModule, FeedbackModule],
  exports: [SnackbarComponent],
})
export class SnackbarModule {}
