import { gql } from '@apollo/client/core';
import { CartCheckout } from '@cart/models/cart-checkout.model';
import { CartDto } from '@cart/models/cart.dto';
import { CreateCartInput } from '@cart/models/create-cart-input.model';

const TotalsFragment = gql`
  fragment totalsFields on Totals {
    total
    items
    taxes
    totalWithoutTaxes
    shipping
    promotion
  }
`;

const CartNoteFragment = gql`
  fragment cartNoteFields on CartNote {
    name
    amount {
      current
      currency
    }
  }
`;

const CartItemFragment = gql`
  fragment cartItemFields on CartItem {
    itemId
    productCode
    price
  }
`;

export interface MutationCartCreateInput {
  cartInput: CreateCartInput;
}
export interface MutationCartCreateOutput {
  cartCreate: CartDto;
}
export const MUTATION_CART_CREATE = gql`
  mutation cartCreation($cartInput: CartConfigInput!) {
    cartCreate(cartConfig: $cartInput) {
      couponCode
      creditNotes {
        ...cartNoteFields
      }
      cartTokenValue
      channel
      totals {
        ...totalsFields
      }
      items {
        ...cartItemFields
      }
    }
  }
  ${TotalsFragment}
  ${CartNoteFragment}
  ${CartItemFragment}
`;

export interface MutationCartAddCouponInput {
  cartTokenInput: string;
  couponInput: string;
}
export interface MutationCartAddCouponOutput {
  cartAddCoupon: CartDto;
}
export const MUTATION_CART_ADD_COUPON = gql`
  mutation cartAddingCoupon($cartTokenInput: String!, $couponInput: String!) {
    cartAddCoupon(cartTokenValue: $cartTokenInput, coupon: $couponInput) {
      couponCode
      creditNotes {
        ...cartNoteFields
      }
      cartTokenValue
      channel
      totals {
        ...totalsFields
      }
      items {
        ...cartItemFields
      }
    }
  }
  ${TotalsFragment}
  ${CartNoteFragment}
  ${CartItemFragment}
`;

export interface MutationCartCheckoutInput {
  cartCheckout: CartCheckout;
}
export interface MutationCartCheckoutOutput {
  cartCheckout: CartDto;
}
export const MUTATION_CART_CHECKOUT = gql`
  mutation completeCheckout($cartCheckout: CartCheckoutInput!) {
    completeCheckout(cartCheckout: $cartCheckout) {
      createdAt
      vacancyId
      state
    }
  }
`;

export interface MutationCartRemoveCouponInput {
  cartTokenInput: string;
}
export interface MutationCartRemoveCouponOutput {
  cartRemoveCoupon: CartDto;
}
export const MUTATION_CART_REMOVE_COUPON = gql`
  mutation cartRemovingCoupon($cartTokenInput: String!) {
    cartRemoveCoupon(cartTokenValue: $cartTokenInput) {
      couponCode
      creditNotes {
        ...cartNoteFields
      }
      cartTokenValue
      channel
      totals {
        ...totalsFields
      }
      items {
        ...cartItemFields
      }
    }
  }
  ${TotalsFragment}
  ${CartNoteFragment}
  ${CartItemFragment}
`;

export interface MutationCartGetInput {
  cartGetInput: string;
}
export interface MutationCartGetOutput {
  cart: CartDto;
}
export const QUERY_CART_GET = gql`
  query cartGet($cartGetInput: String!) {
    cart(cartTokenValue: $cartGetInput) {
      couponCode
      creditNotes {
        ...cartNoteFields
      }
      cartTokenValue
      channel
      totals {
        ...totalsFields
      }
      items {
        ...cartItemFields
      }
    }
  }
  ${TotalsFragment}
  ${CartNoteFragment}
  ${CartItemFragment}
`;
