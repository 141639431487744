import { RouterStateUrl } from '@app/store';
import { LegalEntity } from '@mkp/legal-entity/data-access';
import { LanguageCode, UUID } from '@mkp/shared/data-access';
import { UserDisplayType } from '@mkp/user/data-access';
import { User } from '@user/shared/models';
import { sha256 } from 'js-sha256';
import { AbTest } from 'libs/ab-test/state/src/lib/ab-test.config';
import {
  AbTestsCategory,
  CompanyCategory,
  EventCategory,
  PageCategory,
  TealiumCategory,
  UserCategory,
} from './categories';
import { UserPartialTracking } from './models';
import { EventActionType, EventCategoryType, EventNameType } from './types';
import { UserSourcePlatformType } from './types/user-source-platform.type';
import { UserTypeType } from './types/user-type.type';

export const getCategory = (url: RouterStateUrl['url']): string => {
  return url ? url.substring(1).split('/')[0] : '';
};

export const getPageCategory = (
  languageCode: LanguageCode | null,
  url: RouterStateUrl['url']
): PageCategory => ({
  page_category: getCategory(url),
  page_language: languageCode ?? undefined,
  page_referrer: window.location.toString(),
  page_location: window.location.toString(),
  page_title: document.title,
});

export const getEventCategory = (
  eventCategory: EventCategoryType,
  eventAction: EventActionType,
  eventName: EventNameType
): EventCategory => ({
  event_action: eventAction,
  event_category: eventCategory,
  event_name: eventName,
  event_origin: 'jobcloud.ai',
  event_source: 'application',
});

export const getTealiumCategory = (tealium_event: EventNameType): TealiumCategory => ({
  tealium_event,
});

export const getAbTestsCategory = (tests: AbTest | undefined): AbTestsCategory => {
  if (!tests) {
    return {};
  }

  const experimentNames = Object.keys(tests);
  const experimentVariants = Object.values(tests);

  return {
    experiment_name: experimentNames.length === 1 ? experimentNames[0] : experimentNames,
    experiment_variant:
      experimentVariants.length === 1 ? experimentVariants[0] : experimentVariants,
  };
};

export const getCompanyCategory = (legalEntity: LegalEntity | undefined): CompanyCategory =>
  legalEntity
    ? {
        company_employees_count: legalEntity.employeeCountTo,
        company_industry: legalEntity.industry.en,
        company_location: legalEntity.address.city,
        company_name: legalEntity.name, //only for logged in B2B users ?? ,
      }
    : {};

export const getUserCategory = (
  user: User | UserPartialTracking | null | undefined,
  displayType: UserDisplayType | undefined,
  accountId?: UUID
): UserCategory =>
  user
    ? {
        user_id: sha256(user.email),
        user_login_id: user?.id,
        user_account_id: accountId,
        user_type: userDisplayTypeToUserType(displayType),
        user_login_status: 'y',
        user_language: user?.language,
        user_source_platform: userDisplayTypeToSourcePlatform(displayType),
      }
    : {
        user_type: 'b2b',
        user_login_status: 'n',
      };

/**
 * @param displayType can be null or undefined, if so, the function will return undefined.
 */
export const userDisplayTypeToSourcePlatform = (
  displayType: UserDisplayType | null | undefined
): UserSourcePlatformType | undefined => {
  const map: { [key in UserDisplayType]: UserSourcePlatformType } = {
    [UserDisplayType.CustomerJobs]: 'jobs',
    [UserDisplayType.CustomerJobUp]: 'jobup',
    [UserDisplayType.CustomerJobScout24]: 'jobscout24',
    [UserDisplayType.CustomerService]: 'service',
    [UserDisplayType.CustomerJobcloud]: 'jobcloud',
  };
  return displayType ? map[displayType] : undefined;
};

/**
 * @param displayType can be null or undefined, if so, the function will return undefined.
 */
export const userDisplayTypeToUserType = (
  displayType: UserDisplayType | null | undefined
): UserTypeType | undefined => {
  switch (displayType) {
    case UserDisplayType.CustomerJobcloud:
    case UserDisplayType.CustomerJobs:
    case UserDisplayType.CustomerJobScout24:
      return 'b2b';
    case UserDisplayType.CustomerService:
      return 'backoffice';
    default:
      return undefined;
  }
};
