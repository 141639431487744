import { versionEffectsActions } from '@mkp/core/state/actions';
import { debugActions } from '@mkp/debug/actions';
import { FeatureFlags } from '@mkp/debug/state';
import { createFeature, createReducer, on } from '@ngrx/store';

export interface VersionState {
  isNewVersionAvailable: boolean;
}

const initialState: VersionState = {
  isNewVersionAvailable: false,
};

const reducer = createReducer(
  initialState,
  on(versionEffectsActions.newVersionIsAvailable, (state) => ({
    ...state,
    isNewVersionAvailable: true,
  })),
  on(debugActions.toggleFeatureFlag, (state, { featureFlag, value }) => ({
    ...state,
    ...getNewVersionAvailableFromFeatureFlag(featureFlag, value),
  }))
);

export const versionFeature = createFeature({ name: 'versionState', reducer });

const getNewVersionAvailableFromFeatureFlag = (
  featureFlag: FeatureFlags,
  value: boolean
): { isNewVersionAvailable: boolean } | Record<string, never> =>
  featureFlag === FeatureFlags.shouldTriggerSilentUpdate ? { isNewVersionAvailable: value } : {};
