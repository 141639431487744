import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgFor } from '@angular/common';
import { SkeletonLoaderModule } from '@shared/modules/skeleton-loader/skeleton-loader.module';

@Component({
  selector: 'mkp-product-table-column-skeleton',
  templateUrl: './product-table-column-skeleton.component.html',
  styleUrl: './product-table-column-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, SkeletonLoaderModule],
})
export class ProductTableColumnSkeletonComponent {}
