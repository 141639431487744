import { Injectable } from '@angular/core';
import { LanguageCode, LanguageIso } from '@mkp/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, take } from 'rxjs';
import { CookieService } from '@mkp/shared/util-device';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly defaultLanguage = LanguageIso.ENGLISH;

  constructor(
    private readonly cookieService: CookieService,
    private readonly translateService: TranslateService
  ) {}

  initAppLanguageForUser(language: LanguageCode): Observable<{ languageIso: LanguageIso }> {
    const languageIso = this.toLanguageIso(language);
    return this.initAppLanguage(languageIso);
  }

  initAppLanguageForVisitor(): Observable<{ languageIso: LanguageIso }> {
    const languageIso = this.getVisitorLanguageIso();
    return this.initAppLanguage(languageIso);
  }

  switchAppLanguage(languageIso: LanguageIso): void {
    this.translateService.setDefaultLang(languageIso);
    this.cookieService.setCookieString('language', languageIso);
  }

  toLanguageIso(language: string): LanguageIso {
    const lang = language ? language.substr(0, 2) : this.defaultLanguage.substr(0, 2);
    const test = String(lang).match(/^en|fr|de$/gi);
    return (test ? `${lang}-CH` : this.defaultLanguage) as LanguageIso;
  }

  private initAppLanguage(languageIso: LanguageIso): Observable<{ languageIso: LanguageIso }> {
    this.switchAppLanguage(languageIso);
    return this.translateService.onDefaultLangChange.pipe(
      map(() => ({ languageIso })),
      take(1)
    );
  }

  private getVisitorLanguageIso(): LanguageIso {
    return (
      (this.cookieService.getCookieString('language') as LanguageIso) ??
      this.toLanguageIso(window.navigator.language)
    );
  }
}
