import { CompanyDto } from '@mkp/company/data-access';
import { ErrorState } from '@mkp/shared/util-state';
import { createActionGroup, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models';

export const CompanyApiActions = createActionGroup({
  source: 'Company/API',
  events: {
    'Load Companies': props<{ query: QueryOptions }>(),
    'Load Companies Success': props<{ companies: CompanyDto[]; selectedId?: string | null }>(),
    'Load Companies Failed': props<{ error: ErrorState }>(),
  },
});
