import { createActionGroup, props } from '@ngrx/store';
import { Product } from '@mkp/shared/util-product';

export const productOptionsApiActions = createActionGroup({
  source: 'Product Options API',
  events: {
    'Load Product Options Success': props<{ products: Product[] }>(),
    'Load Product Options Failure': props<{ error: { message: string } }>(),
  },
});
