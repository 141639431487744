import {
  CommercialRegisterEntry,
  LegalEntity,
  LegalEntityState,
  OrganisationEntry,
} from './legal-entity.model';

export const isLegalEntityState = (value: unknown): value is LegalEntityState =>
  Object.values(LegalEntityState).includes(value as LegalEntityState);

export const isActiveLegalEntity = (legalEntity: LegalEntity): boolean =>
  legalEntity.state === LegalEntityState.Active;

export type LegalEntityUpdatePayload = Pick<LegalEntity, 'id' | 'state' | '_version'>;

export const isCommercialRegisterEntry = (value: unknown): value is CommercialRegisterEntry =>
  (value as CommercialRegisterEntry)._type === 'commercialRegisterEntry';

export const isOrganisationEntry = (value: unknown): value is OrganisationEntry =>
  (value as OrganisationEntry)._type === 'organisationEntry';
