import { inject } from '@angular/core';
import { getMessageError } from '@app/core/models';
import { selectIsBoUser } from '@app/features/user/store/selectors/user.selectors';
import { legalEntityGuardActions } from '@app/store/actions';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { selectAccountMemberships } from '@mkp/account-membership/state';
import { Account, AccountResource } from '@mkp/account/data-access';
import { accountApiActions } from '@mkp/account/data-access/actions';
import { authActions } from '@mkp/auth/actions';
import { legalEntityDetailsActions } from '@mkp/legal-entity/actions';
import { isActiveLegalEntity, LegalEntityResource } from '@mkp/legal-entity/data-access';
import { SnackbarService } from '@mkp/shared/ui-library';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import {
  addLegalEntityToAccountDto,
  addLegalEntityToAccountsDto,
} from '../../../../data-access/src/lib/helpers/helpers';
import { selectSelectedAccount } from './account.selectors';

// Fetch list of all user accounts
export const fetchAccountsAfterLogin = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(authActions.loadActiveAccountMembershipsSuccess),
      filter(({ accountMemberships }) => accountMemberships.length > 0),
      switchMap(({ accountMemberships }) =>
        accountResource.getWithQuery(getActiveAccountsFilter(accountMemberships)).pipe(
          map(({ _embedded: { results: accounts } }) => accounts),
          addLegalEntityToAccountsDto(legalEntityResource),
          map((accounts) => authActions.loadAccountsAfterLoginSuccess({ accounts })),
          catchError((error: unknown) =>
            of(
              authActions.loadAccountsAfterLoginFailed({
                error: getMessageError(error, 'fetchAccountsAfterLogin'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

export const fetchUserAccounts = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource)
  ) =>
    actions$.pipe(
      ofType(accountApiActions.loadUserAccounts),
      concatLatestFrom(() => store.select(selectAccountMemberships)),
      switchMap(([, accountMemberships]) =>
        accountResource.getWithQuery(getActiveAccountsFilter(accountMemberships)).pipe(
          map(({ _embedded: { results: accounts } }) => accounts),
          addLegalEntityToAccountsDto(legalEntityResource),
          map((accounts) => accountApiActions.loadUserAccountsSuccess({ accounts })),
          catchError((error: unknown) =>
            of(
              accountApiActions.loadUserAccountsFailure({
                error: getMessageError(error, 'fetchUserAccounts'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

// when legal-entity is inactive, we want to regularly check if legal-entity becomes active
// this is due to the fact that customer service usually activates an account within 5min
// we want reactive UI when this happens
export const checkActiveLegalEntity = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    legalEntityResource = inject(LegalEntityResource),
    accountResource = inject(AccountResource)
  ) =>
    actions$.pipe(
      // this guard is not blocking users, its only purpose is to trigger this refresh
      ofType(legalEntityGuardActions.canActivate),
      concatLatestFrom(() => store.select(selectSelectedAccount)),
      map(([, account]) => account),
      filter(Boolean),
      filter((account) => !isActiveLegalEntity(account.legalEntity)),
      switchMap(({ id: accountId }) =>
        accountResource.getById(accountId).pipe(
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) => accountApiActions.loadAccountSuccess({ account })),
          catchError((error: unknown) =>
            of(
              accountApiActions.loadAccountFailure({
                error: getMessageError(error, 'checkActiveLegalEntity'),
              })
            )
          )
        )
      )
    ),
  { functional: true }
);

export const updateAccount = createEffect(
  (
    actions$ = inject(Actions),
    accountResource = inject(AccountResource),
    legalEntityResource = inject(LegalEntityResource),
    store = inject(Store)
  ) =>
    actions$.pipe(
      ofType(accountApiActions.updateAccount),
      concatLatestFrom(() => store.select(selectSelectedAccount)),
      switchMap(([{ name }, account]) => {
        const { id, _version } = account!;
        return accountResource.update(id.toString(), { name, _version }).pipe(
          addLegalEntityToAccountDto(legalEntityResource),
          map((account) => accountApiActions.updateAccountSuccess({ account })),
          catchError(() => {
            return of(
              accountApiActions.updateAccountFailure({
                error: { message: name },
              })
            );
          })
        );
      })
    ),
  { functional: true }
);

export const updateAccountSuccess = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService), store = inject(Store)) =>
    actions$.pipe(
      ofType(accountApiActions.updateAccountSuccess),
      tap(({ account }) => {
        snackbarService.show('SETTINGS.WORKSPACE.NAME.SUCCESS', {
          translationParams: { name: account.name },
        });
      }),
      concatLatestFrom(() => store.select(selectIsBoUser)),
      map(([{ account }, isBoUser]) => handleAccountUpdateSuccess(account, isBoUser))
    ),
  { functional: true }
);

export const updateAccountFailure = createEffect(
  (actions$ = inject(Actions), snackbarService = inject(SnackbarService)) =>
    actions$.pipe(
      ofType(accountApiActions.updateAccountFailure),
      tap(({ error: { message } }) => {
        snackbarService.showError('SETTINGS.WORKSPACE.NAME.ERROR', {
          translationParams: { name: message },
        });
      })
    ),
  { functional: true, dispatch: false }
);

const getActiveAccountsFilter = (activeAccountMemberships: AccountMembership[]) => ({
  filter: activeAccountMemberships
    .map((accountMembership) => `id==${accountMembership.accountId}`)
    .join(','),
});

const handleAccountUpdateSuccess = (account: Account, isBoUser: boolean | null) =>
  isBoUser
    ? legalEntityDetailsActions.loadAccounts({
        legalEntity: account.legalEntity,
      })
    : accountApiActions.loadUserAccounts();
