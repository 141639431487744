import { CompanyProfile } from '@company/models';
import { createAction, props } from '@ngrx/store';
import { Location } from '@shared/models';

export const updateCompany = createAction(
  '[Company Form Page] Update Company',
  props<{ company: CompanyProfile }>()
);
export const createLocation = createAction(
  '[Company Form Page] Create Location',
  props<{ location: Location }>()
);
export const updateLocation = createAction(
  '[Company Form Page] Update Location',
  props<{ location: Location }>()
);
export const deleteLocation = createAction(
  '[Company Form Page] Delete Location',
  props<{ location: Location }>()
);
