<ng-container *ngIf="language$ | async as language">
  <div class="hp-block block1">
    <div class="blue-bean-background parallax"></div>
    <div class="hp-block-inner">
      <div class="hp-title title-l">
        <h1>{{ 'HOME.B1.TITLE' | translate }}</h1>
      </div>

      <p>{{ 'HOME.B1.TEXT' | translate }}</p>

      <div class="hp-call-to-action onboarding-btn">
        <ui-button
          mkpDataTest="register-button"
          routerLink="/onboarding"
          [config]="primaryRegisterButtonConfig"
          [translate]="'HOME.BUTTON.JOBAD'"
        />
      </div>
    </div>
    <mkp-demo-snapshots class="limited-width" [language]="language" />
  </div>
  <div
    class="shadow-separator hide-mobile"
    *ngIf="(breakpointObserverService.isMobile$ | async) === false"
  ></div>
  <div class="hp-logos" *ngIf="(breakpointObserverService.isMobile$ | async) === false">
    <div class="logo">
      <img
        loading="lazy"
        alt="jobscout24.ch"
        width="67"
        height="32"
        ngSrc="assets/images/homepage/jobscout-logo.svg"
      />
    </div>
    <div class="logo">
      <img
        loading="lazy"
        alt="jobsup.ch"
        width="159"
        height="28"
        ngSrc="assets/images/homepage/jobup.svg"
      />
    </div>
    <div class="logo">
      <img
        loading="lazy"
        alt="jobs.ch"
        width="159"
        height="32"
        ngSrc="assets/images/homepage/jobs-ch.svg"
      />
    </div>
    <div class="logo">
      <img
        loading="lazy"
        alt="jobwinner"
        width="118"
        height="28"
        ngSrc="assets/images/homepage/jobwinner-logo.svg"
      />
    </div>
    <div class="logo">
      <img
        loading="lazy"
        alt="google.ch"
        width="86"
        height="29"
        ngSrc="assets/images/homepage/google-logo.svg"
      />
    </div>
  </div>
  <div class="hp-block block2">
    <div class="hp-block-inner">
      <div class="hp-title title-l">
        <h1 class="fw400" [innerHTML]="'HOME.B4.TITLE1' | translate"></h1>
        <h1 [innerHTML]="'HOME.B4.TITLE2' | translate"></h1>
      </div>
    </div>

    <div class="hp-list">
      <div class="hp-list-item">
        <div class="hp-list-item-logo">
          <div
            class="hide-mobile circle-icon"
            inlineSVG="assets/images/homepage/circle-notification.svg"
          ></div>
          <ng-container *ngTemplateOutlet="mobileLogo" />
        </div>
        <div class="hp-list-item-title">{{ 'HOME.B4.ADV1.TITLE' | translate }}</div>
        <div class="hp-list-item-text body-default">{{ 'HOME.B4.ADV1.TEXT' | translate }}</div>
      </div>
      <div class="hp-list-item">
        <div class="hp-list-item-logo">
          <div
            class="hide-mobile circle-icon"
            inlineSVG="assets/images/homepage/circle-building.svg"
          ></div>
          <ng-container *ngTemplateOutlet="mobileLogo" />
        </div>
        <div class="hp-list-item-title">{{ 'HOME.B4.ADV2.TITLE' | translate }}</div>
        <div class="hp-list-item-text body-default">{{ 'HOME.B4.ADV2.TEXT' | translate }}</div>
      </div>
      <div class="hp-list-item">
        <div class="hp-list-item-logo">
          <div
            class="hide-mobile circle-icon"
            inlineSVG="assets/images/homepage/circle-signal.svg"
          ></div>
          <ng-container *ngTemplateOutlet="mobileLogo" />
        </div>
        <div class="hp-list-item-title">{{ 'HOME.B4.ADV3.TITLE' | translate }}</div>
        <div class="hp-list-item-text body-default">{{ 'HOME.B4.ADV3.TEXT' | translate }}</div>
      </div>
      <div class="hp-list-item">
        <div class="hp-list-item-logo">
          <div
            class="hide-mobile circle-icon"
            inlineSVG="assets/images/homepage/circle-device.svg"
          ></div>
          <ng-container *ngTemplateOutlet="mobileLogo" />
        </div>
        <div class="hp-list-item-title">{{ 'HOME.B4.ADV4.TITLE' | translate }}</div>
        <div class="hp-list-item-text body-default">{{ 'HOME.B4.ADV4.TEXT' | translate }}</div>
      </div>

      <ng-template #mobileLogo>
        <div class="show-mobile" inlineSVG="assets/images/homepage/checkmark_icon.svg"></div>
      </ng-template>
    </div>

    <hr class="hp-hr" />

    <div class="hp-sec">
      <div class="hp-sec-icon">
        <img
          loading="lazy"
          ngSrc="assets/images/homepage/shield-lock.svg"
          height="32"
          width="32"
          alt="jobcloud.ai shield lock"
        />
      </div>
      <div class="hp-sec-text body-default bold">{{ 'HOME.B4.ADV5.TEXT' | translate }}</div>
    </div>
  </div>
  <div class="hp-block block3">
    <div class="hide-mobile hide-tablet"></div>

    <div class="hp-title title-l">
      <h1 class="fw400" [innerHTML]="'HOME.B3.TITLE1' | translate"></h1>
      <h1 [innerHTML]="'HOME.B3.TITLE2' | translate"></h1>
    </div>

    <mkp-demo-products />
  </div>
  <div class="hp-block block4" *ngIf="(breakpointObserverService.isMobile$ | async) === false">
    <div class="hp-block-inner">
      <div class="hp-title title-l">
        <h1 class="fw400" [innerHTML]="'HOME.B5.TITLE1' | translate"></h1>
        <h1 [innerHTML]="'HOME.B5.TITLE2' | translate"></h1>
      </div>

      <p>{{ 'HOME.B4.TEXT' | translate }}</p>
    </div>

    <div class="hp-img-hold-it-2">
      <mds-tabs [config]="tabsConfig">
        <mds-tab-item
          *ngFor="let tab of tabs"
          [title]="tab.title | translate"
          [index]="tab.index"
          [dataTest]="tab.dataTest"
        >
          <img
            [ngSrc]="tab.imageSrc | replacePicture: homepageImageUrl : language"
            width="900"
            height="561"
            alt="jobcloud.ai homepage image"
          />
        </mds-tab-item>
      </mds-tabs>
    </div>
  </div>
  <div class="hp-block block5">
    <div
      class="contact-logo show-mobile"
      inlineSVG="assets/images/homepage/object-contact.svg"
    ></div>

    <div class="hp-block-inner-row">
      <div class="hp-question">
        <div class="hp-title title-l">
          <h1>{{ 'HOME.B5.TITLE' | translate }}</h1>
        </div>

        <p>{{ 'HOME.B5.TEXT' | translate }}</p>

        <div class="hp-call-to-action">
          <ui-button
            mkpDataTest="contact-us-button"
            [config]="contactUsButtonConfig"
            (click)="openZendesk()"
            [translate]="'HOME.BUTTON.CONTACT-US'"
          />
          <ui-button
            class="hide-mobile"
            mkpDataTest="register-button"
            routerLink="/onboarding"
            [config]="secondaryRegisterButtonConfig"
            [translate]="'HOME.BUTTON.START.JOBAD'"
          />
        </div>
      </div>
      <div class="hide-mobile">
        <div inlineSVG="assets/images/homepage/contact-us/customer-support_jc.ai.svg"></div>
      </div>
    </div>
  </div>
</ng-container>
