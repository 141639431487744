import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DisplayType } from './breakpoint-observer.model';

@Injectable({ providedIn: 'root' })
export class BreakpointObserverService {
  readonly breakpointsMap = new Map([
    [DisplayType.Mobile, '(max-width: 767.98px)'],
    [DisplayType.TabletPortrait, '(min-width: 768px) and (max-width: 1024.98px)'],
    [DisplayType.TabletLandscape, '(min-width: 1025px) and (max-width: 1199.98px)'],
    [DisplayType.Desktop, '(min-width: 1200px) and (max-width: 1439.98px)'],
    [DisplayType.Wide, '(min-width: 1440px)'],
  ]);

  private breakpoints$: Observable<BreakpointState>;

  constructor(readonly breakpointObserver: BreakpointObserver) {
    this.breakpoints$ = breakpointObserver
      .observe(Array.from(this.breakpointsMap.values()))
      .pipe(distinctUntilChanged());
  }

  get isMobile$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.Mobile]);
  }

  get isTablet$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.TabletPortrait, DisplayType.TabletLandscape]);
  }

  get isTabletPortrait$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.TabletPortrait]);
  }

  get isTabletLandscape$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.TabletLandscape]);
  }

  get isDesktop$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.Desktop]);
  }

  get isWide$(): Observable<boolean> {
    return this.compareBreakpointsWith([DisplayType.Wide]);
  }

  get isTabletDown$(): Observable<boolean> {
    return this.compareBreakpointsWith([
      DisplayType.TabletLandscape,
      DisplayType.TabletPortrait,
      DisplayType.Mobile,
    ]);
  }

  private compareBreakpointsWith(displayTypes: DisplayType[]): Observable<boolean> {
    const viewports = displayTypes.map((displayType) => this.breakpointsMap.get(displayType));
    return this.breakpoints$.pipe(
      map(({ breakpoints }) => viewports.some((viewport) => breakpoints[viewport as string])),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }
}
