import { Vacancy } from '@app/features/vacancy/models';
import { createdByIdsForImportedJobAds } from '@env/environment';
import { isFreeProduct } from '@mkp/shared/util-product';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  isBefore,
  isFuture,
  parseISO,
} from 'date-fns';
import { CreditRedemptionDto, CreditRedemptionViewModel } from './credit-redemption.dto';
import {
  CreditRedemptionInformation,
  CreditRedemptionState,
  CreditRedemptionTimeLeft,
  CreditRedemptionTimeUnit,
} from './credit-redemption.model';

export const isActiveCreditRedeem = (
  creditRedeem: CreditRedemptionViewModel | undefined
): boolean => creditRedeem?.information.status === CreditRedemptionState.Active;

export const isActiveFreeProduct = (creditRedeem: CreditRedemptionViewModel | undefined): boolean =>
  isActiveCreditRedeem(creditRedeem) && isFreeProduct(creditRedeem);

// TODO: is "draft" a possibility ? Otherwise replace with !isActive()
export const isCreditInactive = (creditRedeem: CreditRedemptionViewModel | undefined): boolean =>
  !!creditRedeem?.information?.status &&
  [CreditRedemptionState.Inactive, CreditRedemptionState.Draft].includes(
    creditRedeem.information.status
  );

export const isStoppedOrExpired = (creditRedeem: CreditRedemptionViewModel | undefined): boolean =>
  !creditRedeem ||
  [CreditRedemptionState.Stopped, CreditRedemptionState.Expired].includes(
    creditRedeem.information.status
  );

export const isCreditUndefinedOrStoppedOrExpiredOrInactive = (
  creditRedeem: CreditRedemptionViewModel | undefined
): boolean =>
  !creditRedeem ||
  [
    CreditRedemptionState.Stopped,
    CreditRedemptionState.Expired,
    CreditRedemptionState.Inactive,
  ].includes(creditRedeem.information.status);

// TODO: remove when we no longer have imported job ads - CDP-1098
export const isImported = (vacancy: Vacancy): boolean =>
  createdByIdsForImportedJobAds.includes(vacancy.createdBy) &&
  vacancy._embedded.creditRedemptions.length === 0;

export const isActive = (
  credit: CreditRedemptionDto | CreditRedemptionViewModel | undefined
): boolean => !!credit && isFuture(parseISO(credit.end)) && !credit.stoppedAt;
// an expired credit redeem has an expired end date but no stoppedAt date
export const isExpired = (credit: CreditRedemptionDto | CreditRedemptionViewModel): boolean =>
  isBefore(parseISO(credit?.end), new Date()) && !credit?.stoppedAt;
// a stopped credit redeem has an expired end date AND a stoppedAt date
export const isStopped = (credit: CreditRedemptionDto | CreditRedemptionViewModel): boolean =>
  !!credit?.stoppedAt;

export const getInformation = (
  creditRedeem: CreditRedemptionDto
): Pick<CreditRedemptionInformation, 'status' | 'relevantDate' | 'timeLeft'> => {
  if (isActive(creditRedeem)) {
    return {
      status: CreditRedemptionState.Active,
      relevantDate: format(parseISO(creditRedeem.start), 'dd.MM.yyyy'),
      timeLeft: timeLeft(creditRedeem),
    };
  } else if (isStopped(creditRedeem)) {
    return {
      status: CreditRedemptionState.Stopped,
      relevantDate: format(parseISO(creditRedeem.stoppedAt ?? ''), 'dd.MM.yyyy'),
      timeLeft: null,
    };
  } else if (isExpired(creditRedeem)) {
    return {
      status: CreditRedemptionState.Expired,
      relevantDate: format(parseISO(creditRedeem.end), 'dd.MM.yyyy'),
      timeLeft: null,
    };
  }

  // should be unreachable
  return {
    status: CreditRedemptionState.Inactive,
    relevantDate: null,
    timeLeft: null,
  };
};

export const timeLeft = (credit: CreditRedemptionDto): CreditRedemptionTimeLeft | null => {
  if (!credit) {
    return null;
  }

  const date = new Date();
  const matrix: { [key in string]: number } = {
    DAY: differenceInDays(parseISO(credit.end), date),
    HOUR: differenceInHours(parseISO(credit.end), date),
    MINUTE: differenceInMinutes(parseISO(credit.end), date),
    SECOND: differenceInSeconds(parseISO(credit.end), date),
  };

  const unit = Object.keys(matrix).find((unit) => matrix[unit] > 0) as CreditRedemptionTimeUnit;

  return { unit, value: matrix[unit] };
};
