import { gql } from '@apollo/client/core';
import { AddressFragments } from './address-schema';

export const LocationFragments = {
  location: gql`
    fragment locationFields on Location {
      id
      companyProfileId
      createdAt
      updatedAt
      text
      _version,
      _links {
        delete {
          href
        }
      }
    }
  `,
};

export const MUTATION_ADD_PROFILE_LOCATION = gql`
  mutation mutationAddProfileLocation($location: LocationInput!) {
    addProfileLocation(location: $location) {
      ...locationFields
      address {
        ...addressFields
      }
    }
  }
  ${LocationFragments.location}
  ${AddressFragments.address}
`;

export const MUTATION_EDIT_PROFILE_LOCATION = gql`
  mutation mutationEditProfileLocation($location: LocationInput!) {
    editProfileLocation(location: $location) {
      ...locationFields
      address {
        ...addressFields
      }
    }
  }
  ${LocationFragments.location}
  ${AddressFragments.address}
`;

export const MUTATION_DELETE_LOCATION = gql`
  mutation mutationDeleteLocation($id: String!) {
    deleteLocation(id: $id)
  }
`;


