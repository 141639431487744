import {
  CreditRedemptionDto,
  CreditRedemptionViewModel,
  CreditViewModel,
  RefundCredit,
} from '@mkp/shared/data-access';
import { ErrorState } from '@mkp/shared/util-state';
import { createActionGroup, props } from '@ngrx/store';
export const PublicationHistoryActions = createActionGroup({
  source: 'Publication History',
  events: {
    'Stop Credit': props<Pick<CreditRedemptionDto, 'id' | '_version'>>(),
    'Stop Credit Success': props<CreditRedemptionViewModel>(),
    'Stop Credit Error': props<ErrorState>(),
    'Refund Credit': props<RefundCredit>(),
    'Refund Credit Success': props<{ id: string; credit: CreditViewModel }>(),
    'Refund Credit Error': props<ErrorState>(),
  },
});
