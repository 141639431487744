import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SkeletonLoaderModule } from '@shared/modules/skeleton-loader/skeleton-loader.module';

@Component({
  selector: 'mkp-product-table-skeleton',
  standalone: true,
  imports: [SkeletonLoaderModule],
  templateUrl: './product-table-skeleton.component.html',
  styleUrl: './product-table-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableSkeletonComponent {}
