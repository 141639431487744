<ng-container *ngIf="feature?.value; else noValueTemplate">
  <ng-container *ngIf="feature.translation; then stringTemplate; else booleanTemplate" />
</ng-container>

<ng-template #stringTemplate>
  <div class="text-value body-s semibold">
    {{ feature.translation | translate }}
  </div>
</ng-template>

<ng-template #booleanTemplate>
  <mat-icon class="has-value">check</mat-icon>
</ng-template>

<ng-template #noValueTemplate>
  <div class="no-value">—</div>
</ng-template>
