import { Location } from '@shared/models';

export class CompanyLocationSerializer {
  fromJson({ id, companyProfileId, address, _version, _links, createdAt, updatedAt }: Location): Location {
    return {
      id,
      companyProfileId,
      address,
      _version,
      _links,
      createdAt: new Date(createdAt),
      updatedAt: updatedAt ? new Date(updatedAt) : undefined,
    };
  }

  toJson(location: Location): unknown {
    return {
      id: location.id,
      companyProfileId: location.companyProfileId,
      address: location.address,
      _version: location._version,
    };
  }
}
