import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ProductsModule } from '@features/products/products.module';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mkp-demo-products',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    RouterLink,
    TranslateModule,
    DataTestDirective,
    ProductsModule,
  ],
  templateUrl: './demo-products.component.html',
  styleUrl: './demo-products.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoProductsComponent {
  @Input() showPlatformSwitch = true;
  @Input() showStickyHeader = false;

  readonly registerBtnConfig: Button = {
    color: ButtonColor.Primary,
    size: ButtonSize.Medium,
    fullWidth: true,
  };
}
