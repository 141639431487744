import { Pipe, PipeTransform } from '@angular/core';
import { ProductCode } from '@mkp/shared/util-product';
@Pipe({
  name: 'descriptionNotePipe',
  pure: true,
  standalone: true,
})
export class DescriptionNotePipe implements PipeTransform {
  transform(code: ProductCode): boolean {
    return code === ProductCode.JOBSOFFER22FREE 
          || code === ProductCode.JS24FREE
          || code === ProductCode.JOBUP24FREE ;
  }
}
