import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Account } from '@mkp/account/data-access';
import { AccountMembership } from '@mkp/account-membership/data-access';
import { CompanyProfile } from '@company/models';

export interface WorkspaceLoadingResourcesMap {
  vacancyId: string;
}

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    'App Bootstrapped': emptyProps(),
    'App Bootstrap Failed': props<{ error: string }>(),
    'User Identity Not Verified': emptyProps(),
    'User Is Verified': props<{ userId: string }>(),
    'Load Active Account Memberships Success': props<{
      accountMemberships: AccountMembership[];
      totalCount: number;
    }>(),
    'Load Active Account Memberships Failed': props<{ error: { message: string } }>(),
    'Load Accounts After Login Success': props<{ accounts: Account[] }>(),
    'Load Accounts After Login Failed': props<{ error: { message: string } }>(),
    'Load Companies After Login Success': props<{ companyProfiles: CompanyProfile[] }>(),
    'Load Companies After Login Failed': props<{ error: { message: string } }>(),
    'Workspace Selected': props<{
      accountId: string;
      companyProfileId: string;
      accountMembershipId: string;
    }>(),
    'Initialize the User': emptyProps(),
  },
});

export const boAuthActions = createActionGroup({
  source: 'Bo Auth',
  events: {
    'Workspace Unselected': props<Pick<Account, 'legalEntityId'>>(),
    'Load Workspace': props<{ accountId: string }>(),
    'Load Workspace Error': props<{ error: { message: string } }>(),
    'Load Workspace Account Load Success': props<{ accounts: Account[] }>(),
    'Load Workspace Account Load Error': props<{ error: { message: string } }>(),
    'Load Workspace Company Load Success': props<{ companyProfiles: CompanyProfile[] }>(),
    'Load Workspace Company Load Error': props<{ error: { message: string } }>(),
  },
});

export const authWorkspaceActions = createActionGroup({
  source: 'Auth Workspace',
  events: {
    'Workspace Resource Loading': props<WorkspaceLoadingResourcesMap>(),
    'Workspace Resource Loading Error': props<{ error: { message: string } }>(),
    'Workspace Resource Not Loading': props<{ accountCount: number }>(),
  },
});
