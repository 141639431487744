import { Component, Input, OnInit, isDevMode } from '@angular/core';

@Component({
  selector: 'mkp-skeleton-loader',
  templateUrl: './skeleton-loader.html',
  styleUrl: './skeleton-loader.scss',
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() count = 1;

  @Input() appearance: 'circle' | '' = '';

  @Input() animation: 'progress' | 'progress-dark' | 'pulse' | 'false' = 'progress';

  @Input() theme: { [k: string]: string } = {};

  readonly items: void[] = [];

  ngOnInit() {
    this.items.length = this.count;
    const allowedAnimations = ['progress', 'progress-dark', 'pulse', 'false'];
    if (allowedAnimations.indexOf(this.animation) === -1) {
      // Shows error message only in Development
      if (isDevMode()) {
        console.error(
          `\`SkeletonLoaderComponent\` need to receive 'animation' as: ${allowedAnimations.join(
            ', '
          )}. Forcing default to "progress".`
        );
      }
      this.animation = 'progress';
    }
  }
}
