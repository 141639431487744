import { Injectable } from '@angular/core';
import { CreateVacancyWrapperState } from '@app/features/vacancy/modules/create-vacancy/components/create-vacancy-wrapper/create-vacancy-wrapper-store';

export interface SessionStorage {
  forcedLogout?: true;
  createVacancyState?: CreateVacancyWrapperState;
}
const parsableValues: Array<keyof SessionStorage> = ['createVacancyState'];
const booleanValues: Array<keyof SessionStorage> = ['forcedLogout'];

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  set<K extends keyof SessionStorage>(key: K, value: SessionStorage[K]): void {
    sessionStorage.setItem(key, value === Object(value) ? stringify(value, key) : value.toString());
  }

  get<K extends keyof SessionStorage>(key: K): SessionStorage[K] {
    const value = sessionStorage.getItem(key);
    return (
      parsableValues.includes(key)
        ? parse(value, key)
        : booleanValues.includes(key)
          ? Boolean(value)
          : value
    ) as SessionStorage[K];
  }

  remove(...keys: (keyof SessionStorage)[]): void {
    keys.forEach((key) => sessionStorage.removeItem(key));
  }
}

// wrappers around JSON.stringify / JSON.parse to swallow and display errors
const stringify = <K extends keyof SessionStorage>(object: SessionStorage[K], key: K): string => {
  let stringValue = '';

  try {
    stringValue = JSON.stringify(object);
  } catch (error: unknown) {
    console.error(`SessionStorageService - error when stringifying object '${key}'`, error);
  }

  return stringValue;
};

const parse = <K extends keyof SessionStorage>(stringValue: string, key: K): SessionStorage[K] => {
  let object = {} as SessionStorage[K];

  try {
    object = JSON.parse(stringValue);
  } catch (error: unknown) {
    console.error(`SessionStorageService - error when parsing object '${key}'`, error);
  }

  return object;
};
