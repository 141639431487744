import { Action, ActionReducer, createFeature, createReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AB_TEST_CONFIG, AbTestState } from './ab-test.config';
import { randomlyGenerateAbTestFromConfig } from './ab-test.helper';

export const getInitialState = (): AbTestState => ({
  versions: randomlyGenerateAbTestFromConfig(AB_TEST_CONFIG),
});

const reducer = createReducer(getInitialState());

export const abTestFeature = createFeature({ name: 'abTestState', reducer });

export const abTestSyncReducer: MetaReducer = (reducer: ActionReducer<AbTestState, Action>) =>
  localStorageSync({
    keys: [{ [abTestFeature.name]: ['versions'] }],
    storageKeySerializer: (key) => `jobcloud.${key}`,
    rehydrate: true,
    syncCondition: () => true,
  })(reducer);
