import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { ProductOfferingFeatureDictionary, featureMapping } from '../constants/feature.constant';
import {
  PRODUCT_OFFERING_ULTIMATE_PRODUCTS,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ProductPlatformOther,
} from '../models/product-offering-feature.model';
import { isProductCode } from '../models/product-offering.model';
import { Product, ProductCode, ProductFeature } from '../models/product.model';

export const featurePlanSeparator = '/';

export const productOfferingPlatformGroups = Object.values(ProductPlatformGroup);

export const removeUnavailableJS24Product = (products: Product[]): Product[] => {
  const deprecatedJS24ProductsCode = ['JS24SOLO', 'JS24PLUS'];

  return products.filter((product) => !deprecatedJS24ProductsCode.includes(product.code));
};

type FilterCmsProductsInput = [Product[], ProductCode[]];

export const filterCmsProducts = ([products, removedCmsProductCodes]: FilterCmsProductsInput) => {
  return removedCmsProductCodes.length
    ? products.filter(({ code }) => !removedCmsProductCodes.includes(code))
    : products;
};

export const getFeaturesFromProducts = (products: Product[]): ProductOfferingBenefit[] => {
  const featuresSet = new Set<ProductOfferingBenefit>();

  products.forEach(({ features }: Product) => {
    // ignore the calendar feature, it is only used for the label on the product card
    features
      .filter(({ id }) => !id.startsWith('calendar'))
      .map(({ id }: ProductFeature) => {
        const [feature] = id.split(featurePlanSeparator, 2);
        featuresSet.add(feature as ProductOfferingBenefit);
      });
  });

  return Array.from(featuresSet);
};

/**
 * @deprecated
 * @param platform
 * @param platformGroups
 */
export const getSwitchedPlatformFrom = (
  platform: ProductPlatformGroup,
  platformGroups = productOfferingPlatformGroups
): ProductPlatformGroup => {
  const indexOfCurrentPlatform = platformGroups.indexOf(platform);
  return [...platformGroups, platformGroups[0]][indexOfCurrentPlatform + 1] as ProductPlatformGroup;
};

export const isFeatureABenefit = (
  feature: ProductOfferingFeature
): feature is ProductOfferingBenefit => {
  return Object.values(ProductOfferingBenefit).includes(feature as ProductOfferingBenefit);
};

export const isFeatureAPlatform = (
  feature: ProductOfferingFeature
): feature is ProductOfferingPlatform => {
  return [...Object.values(ProductPlatformGroup), ...Object.values(ProductPlatformOther)].includes(
    feature as ProductOfferingPlatform
  );
};

export const isProductOfferingPlatformGroup = (
  productOffering: unknown
): productOffering is ProductPlatformGroup =>
  Object.values(ProductPlatformGroup).some((platformGroup) => productOffering === platformGroup);

export const getPlatformsFromFeatures = (
  features: ProductOfferingFeature[],
  activePlatformGroup: ProductPlatformGroup
): ProductOfferingPlatform[] => {
  const platforms = features.filter(isFeatureAPlatform);
  return moveActivePlatformToBeginning(platforms, activePlatformGroup);
};

export const getBenefitsFromFeatures = (
  features: ProductOfferingFeature[]
): ProductOfferingBenefit[] => {
  return features.filter(isFeatureABenefit);
};

export const orderFeatures = (
  features: ProductOfferingFeature[],
  fmapping: ProductOfferingFeatureDictionary = featureMapping
): typeof features => {
  return features.sort((a, b) => {
    const {
      [a]: { order: orderA },
      [b]: { order: orderB },
    } = fmapping;
    return orderA - orderB;
  });
};

export const moveActivePlatformToBeginning = (
  platforms: ProductOfferingPlatform[],
  activePlatformGroup: ProductPlatformGroup
): ProductOfferingPlatform[] => {
  return platforms.reduce(
    (acc, platform) =>
      isProductOfferingPlatformGroup(platform) && platform === activePlatformGroup
        ? [platform, ...acc]
        : [...acc, platform],
    [] as ProductOfferingPlatform[]
  );
};

export const isProductOfferingUltimate = (code: ProductCode): boolean =>
  PRODUCT_OFFERING_ULTIMATE_PRODUCTS.includes(code);

export const getProductCodeFromRoute = (
  route: ActivatedRouteSnapshot,
  router: Router
): Product['code'] | undefined =>
  // productCode can come from
  // 1. queryParams (marketing direct URL CDP-80)
  // 2. navigation.extras (when selecting a product on step 3)
  getProductCodeFromQueryParams(route) ??
  router.getCurrentNavigation()?.extras?.state?.['productCode'];

export const getProductCodeForRoute = (productCode: Product['code']): NavigationExtras => ({
  state: { productCode },
});

const getProductCodeFromQueryParams = (
  route: ActivatedRouteSnapshot
): Product['code'] | undefined => {
  const productCode = route.queryParamMap.get('productCode');
  return isProductCode(productCode) ? productCode : undefined;
};

const productCodeSortingMapDefault = Object.values(ProductCode).reduce<Record<string, number>>(
  (acc, productCode, index) => ({
    ...acc,
    [productCode.toString()]: index,
  }),
  {}
);

export const productOfferingSortingMap: Record<ProductCode, number> = {
  [ProductCode.JS24FREE]: 0, // price 0
  [ProductCode.JS24SOLO]: 1,
  [ProductCode.JS24PLUS]: 2,
  [ProductCode.JS24BASIC]: 3,
  [ProductCode.JS24ADVANCED]: 4,
  [ProductCode.JOBSSTARTER]: 5,
  [ProductCode.JOBSSTANDARD]: 6, // join integration, not using on UI
  [ProductCode.JOBSFLEX]: 7, // used only for upsell landing page
  [ProductCode.JOBSLIMITED]: 8,
  [ProductCode.JOBSOFFER22S]: 9, // aka "Jobs Basic"
  [ProductCode.JOBSOFFER22M]: 10, // aka "Jobs Advanced"
  [ProductCode.JOBSOFFER22L]: 11, // aka "Jobs Ultimate"
  [ProductCode.JOBSOFFER22XL]: 12, // aka "Jobs Ultimate" Plus
  [ProductCode.JOBSOFFER22XS]: 13, // aka "Jobs Lite"
  [ProductCode.JOBSOFFER22FREE]: 14,
  [ProductCode.JOBUP24BASIC]: 15,
  [ProductCode.JOBUP24ADVANCED]: 16,
  [ProductCode.JOBUP24ULTIMATE]: 17,
  [ProductCode.JOBUP24ULTIMATEPLUS]: 18,
  [ProductCode.JOBUP24LITE]: 19,
  [ProductCode.JOBUP24FREE]: 20,
};

export const sortProducts = (
  p1: Product,
  p2: Product,
  sortingMap?: Record<string, number>
): number =>
  sortingMap
    ? sortingMap[p1.code] - sortingMap[p2.code]
    : productCodeSortingMapDefault[p1.code] - productCodeSortingMapDefault[p2.code];
