import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Gdpr } from '@mkp/gdpr/state';
import {
  Button,
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  ButtonWrapper,
  FeedbackModule,
} from '@mkp/shared/ui-library';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { GdprDialogData } from '../gdpr-dialog-data.interface';
import { GdprLinkPipe } from '../gdpr-link/gdpr-link.pipe';

const DEFAULT_BTN_CONFIG: Button = {
  color: ButtonColor.Tertiary,
  size: ButtonSize.Small,
  mobileFullWidth: true,
};

type GdprTemplate = 'info' | 'full-details';

@Component({
  templateUrl: 'gdpr-dialog-content.component.html',
  styleUrl: 'gdpr-dialog-content.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    GdprLinkPipe,
    MatDialogModule,
    MatCheckboxModule,
    ButtonComponent,
    ReactiveFormsModule,
    FeedbackModule,
    DataTestDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdprDialogContentComponent {
  readonly initCookieSituation: Gdpr = {
    functional: false,
    marketing: false,
  } as const;
  readonly acceptAllButton: { config: ButtonWrapper; dataTest: string } = {
    config: {
      config: {
        ...DEFAULT_BTN_CONFIG,
        color: ButtonColor.Primary,
      },
      content: {
        label: 'GDPR.MODAL.BUTTON.ACCEPT-ALL',
      },
    },
    dataTest: 'gdpr-dialog-accept-all',
  };
  readonly secondaryButton: {
    [key in GdprTemplate]: { config: ButtonWrapper; dataTest: string };
  } = {
    'full-details': {
      config: {
        config: { ...DEFAULT_BTN_CONFIG },
        content: {
          label: 'GDPR.MODAL.BUTTON.ACCEPT-SELECTED',
        },
      },
      dataTest: 'gdpr-dialog-accept-selected',
    },
    info: {
      config: {
        config: { ...DEFAULT_BTN_CONFIG },
        content: {
          label: 'GDPR.MODAL.BUTTON.SETTINGS',
        },
      },
      dataTest: 'gdpr-dialog-view-settings',
    },
  };
  readonly cookiesForm = this.formBuilder.group(this.initCookieSituation);

  template: GdprTemplate = 'info';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GdprDialogContentComponent, Gdpr>,
    @Inject(MAT_DIALOG_DATA) data: GdprDialogData
  ) {
    this.prepareConfig(data);
  }

  goFullDetails() {
    this.template = 'full-details';
  }

  onSubmit() {
    this.dialogRef.close(this.cookiesForm.value);
  }

  selectAll() {
    this.cookiesForm.patchValue({
      functional: true,
      marketing: true,
    });
    this.onSubmit();
  }

  handleSecondaryBtnClick() {
    this.template === 'full-details' ? this.onSubmit() : this.goFullDetails();
  }

  private prepareConfig(data: GdprDialogData) {
    this.template = data.fullDetails ? 'full-details' : 'info';
    this.cookiesForm.patchValue(data.prefill);
  }
}
