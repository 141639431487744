import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TestimonialModel } from '@mkp/core/feature-cms';
import { CarouselComponent, CarouselItemComponent } from '@mkp/shared/ui-library';
import { BreakpointObserverService } from '@mkp/shared/util-device';
import { LazyLoadImagesDirective } from '../lazyload/lazyload.directive';
import { ReplacePicturePipe } from '../replace-picture/replace-picture.pipe';
import { PATH, SNAPSHOTS } from './demo-snapshots.config';

@Component({
  selector: 'mkp-demo-snapshots',
  standalone: true,
  imports: [
    CommonModule,
    ReplacePicturePipe,
    LazyLoadImagesDirective,
    CarouselComponent,
    CarouselItemComponent,
  ],
  templateUrl: './demo-snapshots.component.html',
  styleUrl: './demo-snapshots.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoSnapshotsComponent {
  @Input() language!: string;
  @Input() isTestimonial!: boolean;
  @Input() content!: TestimonialModel[];

  @ViewChild('glider', { static: true }) glider!: ElementRef;

  public snapshots: { id: string; src: string }[] = SNAPSHOTS;
  public path: string = PATH;

  constructor(public breakpointObserverService: BreakpointObserverService) {}

  trackByFn(index: number, name: any): number {
    return name.id;
  }
}
