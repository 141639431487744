import { MetadataDictionary } from '@mkp/metadata/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MetadataActions = createActionGroup({
  source: 'Metadata',
  events: {
    'Get Metadatas List': emptyProps(),
    'Get Metadatas List Success': props<{ payload: MetadataDictionary }>(),
    'Get Metadatas List Failed': props<{ error: { message: string } }>(),
    'Reset Metadatas Status': emptyProps(),
  },
});
