import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AuthFacade } from '@mkp/auth/util';
import { verifyIdentityPendingParams as queryParams } from '@mkp/onboarding/feature-verify-identity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const verifiedUserGuard: () => Observable<true | UrlTree> = () => {
  const authFacade = inject(AuthFacade);
  const router = inject(Router);

  return authFacade.isVerified$.pipe(
    map(
      (verified) =>
        verified || router.createUrlTree([AppConfig.routes.verifyIdentity], { queryParams })
    )
  );
};
