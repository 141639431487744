import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { iconClassMapping } from '../../elements';
import { SnackbarConfig } from './snackbar.config';
import { SnackbarService } from './snackbar.service';

@Component({
  selector: 'ui-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('state', [
      state(
        'void, hidden',
        style({
          transform: 'scale(0.8)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'scale(0.8)',
          opacity: 1,
        })
      ),
      transition('* => visible', animate('150ms cubic-bezier(0.550, 0.085, 0.680, 0.530)')),
      transition(
        '* => void, * => hidden',
        animate(
          '75ms cubic-bezier(0.4, 0.0, 1, 1)',
          style({
            opacity: 0,
          })
        )
      ),
    ]),
  ],
})
export class SnackbarComponent implements OnInit, OnDestroy {
  protected config!: SnackbarConfig;
  protected show!: boolean;
  protected icon: string | undefined;
  protected duration!: number;

  private snackbarSubscription!: Subscription;
  private started!: number;
  private timeoutId!: ReturnType<typeof setTimeout>;
  private remaining!: number;

  constructor(
    private snackbarService: SnackbarService,
    private el: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  @HostListener('mouseenter')
  onMouseOver() {
    this.pauseTimeout();
    this.pauseProgressBar();
  }

  @HostListener('mouseleave')
  onMouseOut() {
    this.setRemainingSnackbarDuration();
    this.continueProgressBar();
  }

  @HostListener('click')
  onClick() {
    if (!this.config?.buttonText) {
      this.hideSnackbar();
    }
  }

  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbar$
      // .pipe(filter(() => !this.show))
      .subscribe((config: SnackbarConfig) => {
        if (this.show) {
          this.resetSnackbar();
        }

        this.setSnackbar(config);
        this.showSnackbar();
        this.setRemainingSnackbarDuration();
      });
  }

  ngOnDestroy() {
    this.snackbarSubscription.unsubscribe();
  }

  private setSnackbar(config: SnackbarConfig): void {
    this.config = { ...config };

    if (config.state) {
      this.icon = iconClassMapping[config.state];
    }

    this.duration = config.buttonText ? 10 : 5;
    this.remaining = this.duration * 1000;
  }

  private setRemainingSnackbarDuration() {
    this.started = new Date().getTime();

    this.timeoutId = setTimeout(() => {
      this.hideSnackbar();
    }, this.remaining);
  }

  private showSnackbar = () => {
    this.show = true;
    this.cd.detectChanges();
  };

  private hideSnackbar = () => {
    this.show = false;
    this.cd.detectChanges();
  };

  private pauseTimeout() {
    clearTimeout(this.timeoutId);
    this.remaining -= new Date().getTime() - this.started;
  }

  private resetSnackbar() {
    clearTimeout(this.timeoutId);
    this.hideSnackbar();
    this.showSnackbar();
  }

  private pauseProgressBar() {
    const progressBarEl = this.el.nativeElement.querySelector('.progress-bar-animation');

    this.renderer.removeClass(progressBarEl, 'running');
    this.renderer.addClass(progressBarEl, 'paused');
  }

  private continueProgressBar() {
    const progressBarEl = this.el.nativeElement.querySelector('.progress-bar-animation');

    this.renderer.removeClass(progressBarEl, 'paused');
    this.renderer.addClass(progressBarEl, 'running');
  }
}
