import { EntityAdapter } from '@ngrx/entity';
import { LoadingState } from '../fetch-state';
import {
  CreatePayload,
  DeletePayload,
  ErrorPayload,
  GetGraphQLPaginatedListPayload,
  GetListPayload,
  GetOnePayload,
  GetPaginatedListPayload,
  SelectPayload,
  UpdatePayload,
} from './entity-feature-payloads';
import { EntityFeatureState } from './entity-feature-state';

type PlainUpdater<State> = () => State;
type Updater<State> = (state: State) => State;
type UpdaterWithPayload<State, Payload> = (state: State, payload: Payload) => State;

export interface EntityFeatureUpdaters<
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error,
> {
  reset: PlainUpdater<FeatureState>;
  resetSelectedId: Updater<FeatureState>;
  setLoading: Updater<FeatureState>;
  setLoaded: Updater<FeatureState>;
  setError: UpdaterWithPayload<FeatureState, ErrorPayload<Error>>;
  setSelected: UpdaterWithPayload<FeatureState, SelectPayload>;
  createSuccess: UpdaterWithPayload<FeatureState, CreatePayload<Entity>>;
  updateSuccess: UpdaterWithPayload<FeatureState, UpdatePayload<Entity>>;
  deleteSuccess: UpdaterWithPayload<FeatureState, DeletePayload>;
  getListSuccess: UpdaterWithPayload<FeatureState, GetListPayload<Entity>>;
  getPaginatedListSuccess: UpdaterWithPayload<FeatureState, GetPaginatedListPayload<Entity>>;
  getGraphQLPaginatedListSuccess: UpdaterWithPayload<
    FeatureState,
    GetGraphQLPaginatedListPayload<Entity>
  >;
  getOneSuccess: UpdaterWithPayload<FeatureState, GetOnePayload<Entity>>;
}

export function createEntityFeatureUpdaters<
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error = Record<string, unknown>,
>(
  initialState: FeatureState,
  adapter: EntityAdapter<Entity>
): EntityFeatureUpdaters<FeatureState, Entity, Error> {
  return {
    reset: () => initialState,
    resetSelectedId: (state) => ({ ...state, selectedId: null }),
    setLoading: (state) => ({
      ...state,
      fetchState: LoadingState.LOADING,
      selectedId: null,
    }),
    setLoaded: (state) => ({ ...state, fetchState: LoadingState.LOADED }),
    setError: (state, { error }) => ({
      ...state,
      fetchState: { error },
    }),
    setSelected: (state, { id }) => ({
      ...state,
      selectedId: state.entities[id] != null ? id : null,
    }),
    createSuccess: (state, { entity }) => ({
      ...state,
      ids: [entity.id, ...state.ids],
      entities: {
        [entity.id]: entity,
        ...state.entities,
      },
      fetchState: LoadingState.LOADED,
      selectedId: entity.id,
    }),
    updateSuccess: (state, { updateEntity }) =>
      adapter.updateOne(updateEntity, {
        ...state,
        fetchState: LoadingState.LOADED,
        selectedId: updateEntity.id,
      }),
    deleteSuccess: (state, { id }) =>
      adapter.removeOne(id, {
        ...state,
        fetchState: LoadingState.LOADED,
        selectedId: null,
        totalCount: state.totalCount !== null ? state.totalCount - 1 : null,
      }),
    getListSuccess: (state, { entities }) =>
      adapter.setAll(entities, {
        ...state,
        fetchState: LoadingState.LOADED,
        totalCount: null,
        _links: null,
        filter: null,
      }),
    getPaginatedListSuccess: (state, { entities, totalCount, filter, _links }) =>
      adapter.upsertMany(entities, {
        ...state,
        fetchState: LoadingState.LOADED,
        totalCount,
        _links,
        filter,
      }),
    getGraphQLPaginatedListSuccess: (state, { entities, gqlPagination }) =>
      adapter.upsertMany(entities, {
        ...state,
        fetchState: LoadingState.LOADED,
        gqlPagination,
      }),
    getOneSuccess: (state, { entity, changeSelectedId = true, shouldUpdate = false }) => {
      const newState = {
        ...state,
        fetchState: LoadingState.INIT,
        selectedId: changeSelectedId ? entity.id : state.selectedId,
      };

      return (state.ids as string[]).includes(entity.id)
        ? shouldUpdate
          ? adapter.updateOne({ id: entity.id, changes: entity }, { ...newState })
          : newState
        : adapter.addOne(entity, newState);
    },
  };
}
