<div class="product-switcher" *ngIf="activeTab$ | async as activeTab">
  @if (withCreditTab) {
    <div
      class="product-switcher-option credit-option"
      (click)="selectCreditTab()"
      mkpDataTest="switcher-credits"
      [ngClass]="{ active: activeTab === 'credits' }"
    >
      <span inlineSVG="assets/icons/redesign/toll.svg" class="credit-option-logo"></span>
      <span class="hide-mobile">
        {{ 'BUTTON_TAB.CREDITS' | translate }}
      </span>
    </div>
  }

  @for (platform of platorms; track platform) {
    <div
      class="product-switcher-option"
      (click)="handleSwitchPlatform(platform.name)"
      [ngClass]="{
        active:
          activePlatformGroup === platform.name && (!withCreditTab || activeTab === 'products')
      }"
      mkpDataTest="switcher-{{ platform.name }}"
    >
      <div class="product-switcher-option-content">
        <div class="product-switcher-image-container">
          <img
            loading="lazy"
            [src]="platform.logoSrc"
            [alt]="platform.name + '-logo'"
            [class]="platform.name + '-logo'"
          />
        </div>
        @if (showTranslations) {
          <span>{{ platform.translationKey | translate }}</span>
        }
      </div>
    </div>
  }
</div>
