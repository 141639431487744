import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { FeatureFlags, selectFeatureFlags } from '@mkp/debug/state';
import { Store } from '@ngrx/store';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { Observable, map, of, switchMap } from 'rxjs';

/**
 * Verify if a feature flag is enabled
 * @param featureFlag the feature flag to verify
 * @param store
 * @param router
 * @returns true if the feature flag is enabled, otherwise it will redirect to the dashboard
 */
const featureFlagEnabled = (
  featureFlag: FeatureFlags,
  store: Store,
  router: Router
): Observable<true | UrlTree> =>
  store
    .select(selectFeatureFlags)
    .pipe(
      map((featureFlags) =>
        !featureFlags[featureFlag] ? router.createUrlTree([AppConfig.routes.dashboard]) : true
      )
    );

/**
 * Note 29.12.2023 - we want to enable the invoice tab for customer service for a few weeks
 * because they have to help customers who ordered in December
 * the invoice tab will be disabled for everybody in CDA-632
 */
export const isInvoiceFeatureFlagEnabled: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store
    .select(selectIsBoUser)
    .pipe(
      switchMap((isBoUser) =>
        isBoUser ? of(true) : featureFlagEnabled(FeatureFlags.isInvoiceEnabled, store, router)
      )
    );
};

export const isNewAccountManagementGuard = () => {
  const store = inject(Store);
  const router = inject(Router);
  return featureFlagEnabled(FeatureFlags.isNewAccountManagementEnabled, store, router);
};

export const isNormalUserAccountManagementEnabled = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store
    .select(selectIsBoUser)
    .pipe(
      switchMap((isBoUser) =>
        isBoUser
          ? of(true)
          : featureFlagEnabled(FeatureFlags.isNormalUserAccountManagementEnabled, store, router)
      )
    );
};
