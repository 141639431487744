import { paginationConfig } from '@app/shared/services/pagination/constants';
import { EntityFeatureState, createEntityFeature } from '@mkp/shared/util-state';
import { vacancyEditActions } from '@mkp/vacancy/feature-vacancy-edit/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Vacancy } from '@vacancy/models/vacancy.model';
import { listVacancyActions, vacancyFormPageActions } from '@vacancy/store/actions';
import { vacancyApiActions } from '@vacancy/store/actions/vacancy-api.actions';
import * as vacancyActions from '@vacancy/store/actions/vacancy.actions';

export interface State extends EntityFeatureState<Vacancy, { message: string }> {
  list: Vacancy['id'][];
  listSize: number;
}

const {
  adapter,
  featureKey,
  initialState: initialFeatState,
  updaters,
  selectors,
} = createEntityFeature<State, 'vacancyState'>('vacancyState');
const initialState: State = {
  ...initialFeatState,
  list: [],
  listSize: paginationConfig.initialLimit,
};

const _reducer = createReducer<State>(
  initialState,
  on(listVacancyActions.reset, (state) => ({
    ...state,
    list: state.list.slice(0, state.listSize),
  })),
  on(
    vacancyEditActions.createVacancy,
    vacancyFormPageActions.updateVacancy,
    vacancyActions.GetVacancy,
    listVacancyActions.loadMore,
    listVacancyActions.reset,
    listVacancyActions.load,
    updaters.setLoading
  ),
  on(
    vacancyActions.CreateVacancyFailure,
    vacancyApiActions.updateVacancyFailure,
    vacancyActions.GetVacancyFailure,
    vacancyApiActions.loadVacanciesByIdsFailure,
    updaters.setError
  ),
  on(vacancyApiActions.loadVacanciesByIdsSuccess, (state, { vacancies }) =>
    adapter.upsertMany(vacancies, state)
  ),
  on(
    vacancyApiActions.loadVacancyFromGuardFailure,
    vacancyApiActions.updateVacancyFailure,
    (state, { error }) => ({
      ...state,
      fetchState: { error },
    })
  ),
  on(
    vacancyActions.GetVacancySuccess,
    vacancyApiActions.loadVacancyFromGuardSuccess,
    (state, { vacancy }) => updaters.getOneSuccess(state, { entity: vacancy, shouldUpdate: true })
  ),
  on(
    vacancyApiActions.listVacanciesSuccess,
    (state, { vacancies, totalCount, _links, filter }) => ({
      ...updaters.getPaginatedListSuccess(state, {
        entities: vacancies,
        totalCount,
        _links,
        filter,
      }),
      list: removeDuplicates(vacancies.map((vacancy) => vacancy.id)),
    })
  ),
  on(
    vacancyApiActions.loadMoreVacanciesSuccess,
    (state, { vacancies, totalCount, _links, filter }) => ({
      ...updaters.getPaginatedListSuccess(state, {
        entities: vacancies,
        totalCount,
        _links,
        filter,
      }),
      list: removeDuplicates(state.list.concat(vacancies.map((vacancy) => vacancy.id))),
    })
  ),
  on(vacancyActions.CreateVacancySuccess, (state, { vacancy }) =>
    updaters.createSuccess(state, { entity: vacancy })
  ),
  on(vacancyApiActions.updateVacancySuccess, (state, { update }) =>
    updaters.updateSuccess(state, { updateEntity: update })
  ),
  on(vacancyApiActions.deleteDraftVacancySuccess, (state, { id }) => ({
    ...updaters.deleteSuccess(state, { id: id }),
    totalCount: substractOne(state.totalCount),
    list: state.list.filter((vacancyId) => vacancyId !== id),
  }))
);

function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}

export { featureKey, initialState, reducer, selectors };

const removeDuplicates = (array: string[]): string[] => Array.from(new Set(array));
const substractOne = (value: number | null): number => Math.max(value ?? 0 - 1, 0);
