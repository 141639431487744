import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-feedback-body',
  template: '<div [innerHtml]="content | translate"></div>',
  styleUrl: './feedback-body.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackBodyComponent {
  @Input() content!: string;
}
