import { RouterModule } from '@angular/router';
import { inject, NgModule } from '@angular/core';
import { RouterTraceService } from '@app/services/router-trace.service';
import { selectIsRouterTracingEnabled } from '@mkp/debug/state';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      useHash: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [RouterTraceService],
})
export class AppRoutingModule {
  private readonly store = inject(Store);

  constructor() {
    this.store
      .select(selectIsRouterTracingEnabled)
      .pipe(
        take(1),
        filter((isEnabled) => isEnabled)
      )
      .subscribe(() => {
        inject(RouterTraceService);
      });
  }
}
