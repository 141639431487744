<mkp-products [showPlatformSwitch]="showPlatformSwitch" [showStickyHeader]="showStickyHeader">
  <div class="register-button-section">
    <div class="register-button-container">
      <ui-button
        mkpDataTest="register-button"
        routerLink="/onboarding"
        [config]="registerBtnConfig"
      >
        {{ 'HOME.BUTTON.JOBAD' | translate }}
      </ui-button>
    </div>
  </div>
</mkp-products>
