<div
  class="wrapper"
  [class]="isBestValue ? activePlatformGroup + '-border' : ''"
  [class.product-best-value]="isBestValue"
>
  <div class="title table-cell">&nbsp;</div>
  <mkp-product-feature-cell
    class="table-cell"
    *ngFor="let platform of platformMapping"
    [feature]="platform"
  />
  <div class="title benefit-title table-cell">&nbsp;</div>
  <mkp-product-feature-cell
    class="table-cell"
    *ngFor="let benefit of benefitMapping"
    [feature]="benefit"
  />
</div>
