import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { ErrorState, FetchState, LoadingState } from './fetch-state';

type ExtractError<FeatureState> = FeatureState extends { fetchState: FetchState<infer Error> }
  ? Error
  : never;

export interface FetchStateSelectors<
  FeatureState extends { fetchState: FetchState<Error> },
  Error,
> {
  selectLoading: Selector<FeatureState, boolean>;
  selectLoaded: Selector<FeatureState, boolean>;
  selectError: Selector<FeatureState, Error | null>;
}

export interface MemoizedFetchStateSelectors<AppState extends object, Error> {
  selectLoading: MemoizedSelector<AppState, boolean>;
  selectLoaded: MemoizedSelector<AppState, boolean>;
  selectError: MemoizedSelector<AppState, Error | null>;
}

export function createFetchStateSelectors<
  FeatureState extends { fetchState: FetchState<Error> },
  Error = ExtractError<FeatureState>,
>(): FetchStateSelectors<FeatureState, Error>;

export function createFetchStateSelectors<
  AppState extends object,
  FeatureState extends { fetchState: FetchState<Error> },
  Error = ExtractError<FeatureState>,
>(
  selectFeatureState: MemoizedSelector<AppState, FeatureState>
): MemoizedFetchStateSelectors<AppState, Error>;

export function createFetchStateSelectors<
  AppState extends object,
  FeatureState extends { fetchState: FetchState<Error> },
  Error = ExtractError<FeatureState>,
>(
  selectFeatureState?: MemoizedSelector<AppState, FeatureState>
): FetchStateSelectors<FeatureState, Error> | MemoizedFetchStateSelectors<AppState, Error> {
  const fetchStateSelectors = fetchStateSelectorsFactory<FeatureState, Error>();

  if (!selectFeatureState) {
    return fetchStateSelectors;
  }

  return {
    selectLoading: createSelector(selectFeatureState, fetchStateSelectors.selectLoading),
    selectLoaded: createSelector(selectFeatureState, fetchStateSelectors.selectLoaded),
    selectError: createSelector(selectFeatureState, fetchStateSelectors.selectError),
  };
}

function fetchStateSelectorsFactory<
  FeatureState extends { fetchState: FetchState<Error> },
  Error,
>(): FetchStateSelectors<FeatureState, Error> {
  return {
    selectLoading: ({ fetchState }) => fetchState === LoadingState.LOADING,
    // to keep consistency with the old implementation,
    // loaded is also true when fetchState is equal to ErrorState
    selectLoaded: ({ fetchState }) => fetchState === LoadingState.LOADED || !!getError(fetchState),
    selectError: ({ fetchState }) => getError(fetchState),
  };
}

const isErrorState = <Error>(fetchState: unknown): fetchState is ErrorState<Error> =>
  !!fetchState && (fetchState as ErrorState<Error>).error !== undefined;

export function getError<Error>(fetchState: FetchState<Error>): Error | null {
  return isErrorState(fetchState) ? fetchState.error : null;
}
