import { createAction, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models/query-options.model';
import { Vacancy } from '../../models/vacancy.model';

export const reset = createAction(`[Vacancy List] Reset`);
export const load = createAction(`[Vacancy List] Load`, props<{ query: QueryOptions }>());
export const loadMore = createAction(`[Vacancy List] Load More`, props<{ query: QueryOptions }>());

export const navigateToCompanyFromVacancy = createAction(
  '[Vacancy List] Navigate to company from vacancy',
  props<{ vacancy: Vacancy }>()
);

export const deleteDraftVacancy = createAction(
  '[Vacancy List] Delete Draft Vacancy',
  props<{ id: string }>()
);
