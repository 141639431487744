import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { CoreBackendJwtService, Token } from './core-backend-jwt.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

/**
 * We're using an auth flow that is not the recommended way from auth0.
 * Their libraries are built around "Auth0 Universal Login" -> the app redirects to the auth0 domain and login is done there
 * What we want is "Embedded Login" -> the user enters email/password in OUR app and we send the credentials to auth0
 * "Embedded Login" is also known as "crossOrigin login" and is less secure but more UX friendly for users (no redirect)
 *
 * We're using the "@auth0/auth0-angular" package to get the token, but this package doesn't handle crossOrigin login
 * So we have to use "auth0-js" to do crossOrigin login, but these two packages don't communicate well:
 *  -> auth0-js saves an encrypted "state" in a cookie
 *  -> auth0-angular uses a different decryption process and can't read the cookie that was left by auth0-js
 * Because of this we receive an error in auth0-angular and we avoid it by setting "skipRedirectCallback" in AuthModule
 */

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private readonly jwtService = inject(CoreBackendJwtService);
  private readonly authService = inject(AuthService);

  private readonly tokenSource = new BehaviorSubject<Token | null>(null);

  readonly isAuthenticated$ = this.authService.isAuthenticated$;
  readonly isVerified$ = this.getIsVerifiedObservable();
  readonly token$ = this.tokenSource.asObservable().pipe(filter(Boolean));

  fetchAccessToken(): Observable<Token> {
    return this.authService.getAccessTokenSilently().pipe(
      map((stringToken) => this.jwtService.getDataFromToken(stringToken)),
      tap((token) => this.tokenSource.next(token)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  logout() {
    this.authService.logout();
  }

  private getIsVerifiedObservable() {
    return this.authService.user$.pipe(
      switchMap((user) =>
        // refresh the ID token if it expires
        // bear in mind that ID token (to get user properties) is a totally different token than access_token (for API calls)
        // however, both tokens get refreshed by calling the same getAccessTokenSilently function
        !user ? this.fetchAccessToken().pipe(switchMap(() => this.authService.user$)) : of(user)
      ),
      map((user) => !!user?.email_verified),
      distinctUntilChanged()
    );
  }
}
