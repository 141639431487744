import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ResetPasswordBody, ResetPasswordDto, UserDto, UserPatch } from '@mkp/user/data-access';
import { UserMapper } from '@shared/core-api/mappers';
import { ApiListEnvelop, QueryOptions } from '@shared/models';
import { ResourceService } from '@shared/services/resource.service';
import { UpdateUser, User } from '@user/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserApi extends ResourceService<UserDto> {
  constructor(
    public override readonly httpClient: HttpClient,
    protected readonly userMapper: UserMapper
  ) {
    super(httpClient, environment.api.core, 'user', userMapper);
  }

  resetPassword(email: string): Observable<ResetPasswordDto> {
    const url = `https://${environment.auth.domain}/dbconnections/change_password`;
    const body: ResetPasswordBody = {
      email,
      client_id: environment.auth.clientId,
      connection: 'Username-Password-Authentication',
    };

    return this.httpClient.post<ResetPasswordDto>(url, body);
  }

  // I don't use "update" because I don't want to use Partial<User>, I want a customized UpdateUser object here
  patch(user: UpdateUser, maxRetry = 3): Observable<User> {
    const body: UserPatch = this.userMapper.toJson(user);
    return this.patchValue({ id: user.id, ...body }, { maxRetry });
  }

  // for some reason the resource service doesn't map the result
  listAndMap(queryOptions?: QueryOptions): Observable<ApiListEnvelop<User>> {
    return super.list(queryOptions).pipe(
      map((envelop) => ({
        ...envelop,
        results: [],
        _embedded: {
          ...envelop._embedded,
          results: envelop._embedded?.results.map(this.userMapper.fromJson) ?? [],
        },
      }))
    );
  }

  // read has wrong typing in resource service
  readAndMap(id: string): Observable<User> {
    return super.read(id) as unknown as Observable<User>;
  }
}
