import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GdprEffects } from './gdpr.effects';
import { gdprFeature } from './gdpr.reducer';

@NgModule({
  imports: [StoreModule.forFeature(gdprFeature), EffectsModule.forFeature([GdprEffects])],
})
export class GdprStateModule {}
