export interface WindowWithZendesk extends Window {
  zE?: (action: string, command: string, ...params: any[]) => void;
  initZendesk?: () => void;
}

export enum ZenDeskActions {
  Messenger = 'messenger',
  Set = 'messenger:set',
}

export enum ZenDeskCommands {
  Open = 'open',
  Close = 'close',
  Cookies = 'cookies',
  Locale = 'locale',
  LoginUser = 'loginUser',
}
