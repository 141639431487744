import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  getOriginalPrice,
  isProductOfferingUltimate,
  Product,
  ProductCode,
} from '@mkp/shared/util-product';
import {
  Button,
  ButtonColor,
  ButtonComponent,
  ButtonSize,
  SelectModule,
} from '@mkp/shared/ui-library';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mkp-product-comparison-table-column-header',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    ButtonComponent,
    TranslateModule,
    DataTestDirective,
  ],
  templateUrl: './product-comparison-table-column-header.component.html',
  styleUrl: './product-comparison-table-column-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComparisonTableColumnHeaderComponent implements OnInit, OnChanges {
  @Input() products!: Product[];
  @Input() isReadMode = false;
  @Input() defaultSelectedProductCode!: Product['code'];
  @Input() isProductLoading: ProductCode | false = false;

  selectConfig: Button = {
    size: ButtonSize.Small,
    color: ButtonColor.Primary,
    fullWidth: true,
  };

  options: { id?: string; name?: string; price: number }[] = [];
  formGroup = new FormGroup({
    productCode: new FormControl(''),
  });

  selectedProduct: Product | undefined;

  @Output() handleSelectedProduct = new EventEmitter<string>();
  @Output() handleChosenProduct = new EventEmitter<Product>();

  private destroyRef = inject(DestroyRef);

  // short names for ULTIMATE and ULTIMATE PLUS products
  getProductName = (product: Product): string | undefined =>
    isProductOfferingUltimate(product.code as ProductCode) ? product.shortName : product.name;

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ productCode }) => {
        this.selectedProduct = this.products.find((product) => product.code === productCode);
        this.handleChosenProduct.emit(this.selectedProduct);
      });
  }

  ngOnChanges() {
    if (this.products?.length) {
      this.options = this.products.map((product) => ({
        id: product.code,
        name: this.getProductName(product),
        price: getOriginalPrice(product),
      }));

      this.selectProduct();
    }
  }

  // when user go to the product summary and return to product selection, it only happens ngOnChanges
  selectProduct() {
    // select paid products by default
    const index =
      this.products.findIndex(({ code }) => code === this.defaultSelectedProductCode) ?? 0;
    this.selectedProduct = this.products[index];
    this.formGroup.patchValue({ productCode: this.options[index].id });

    this.handleChosenProduct.emit(this.selectedProduct);
  }
}
