import { gql } from '@apollo/client/core';

export const AddressFragments = {
  address: gql`
    fragment addressFields on Address {
      street
      addition
      postalCode
      city
      countryIsoCode
      latitude
      longitude
    }
  `,
};
