import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  getCookieObject<T extends object>(key: string): T | null {
    return this.parse({ key, value: this.getCookie(key) });
  }

  setCookieObject<T extends object>(key: string, value: T, days?: number): void {
    this.setCookie(key, this.stringify({ key, value }), days);
  }

  getCookieString(key: string): string | null {
    return this.getCookie(key);
  }

  setCookieString(key: string, value: string, days?: number): void {
    this.setCookie(key, value, days);
  }

  deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  private getCookie(name: string): string | null {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  setCookie(name: string, value: string, days?: number) {
    this.setCookieWrapper(getCookieText(name, value, days));

    if (name === 'language') {
      this.setCookieWrapper(`${getCookieText(name, value, days)} domain=${getAuth0Domain()};`);
    }
  }

  // exposing this function for testing purposes only
  setCookieWrapper(cookie: string): void {
    document.cookie = cookie;
  }

  // wrappers around JSON.stringify / JSON.parse to swallow and display errors
  private stringify<T>({ key, value }: { key: string; value: T }): string {
    try {
      return JSON.stringify(value);
    } catch (error: unknown) {
      console.error(`CookieHelpers - error when stringifying object '${key}'`, error);
    }
    return '';
  }

  private parse<T>({ key, value }: { key: string; value: string | null }): T | null {
    if (!value || value === '') {
      return null;
    }

    try {
      return JSON.parse(value ?? '{}');
    } catch (error: unknown) {
      // remove the broken cookie to avoid having this error again and again
      this.deleteCookie(key);
      console.error(
        `CookieHelpers - error when parsing object - key: '${key}' - value: ${value}`,
        error
      );
    }
    return null;
  }
}

// expires=null creates a session cookie
const getExpiresDateString = (days: number | undefined): string | null =>
  days ? getDatePlusDays(days).toUTCString() : null;

const getDatePlusDays = (nbDays: number): Date =>
  new Date(new Date().setDate(new Date().getDate() + nbDays));

const getAuth0Domain = () => environment.auth.domain.split('auth')[1];

const getCookieText = (name: string, value: string, days?: number): string =>
  `${name}=${value}; path=/; expires=${getExpiresDateString(days)};`;
