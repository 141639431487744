import { LegalEntity, LegalEntityDto, mapLegalEntityDtoToLegalEntity } from '@mkp/legal-entity/data-access';

/**
 * @deprecated migrate to a resource in the libs folder
 */
export class LegalEntitySerializer {
  fromJson(legalEntityDto: LegalEntityDto): LegalEntity {
    return mapLegalEntityDtoToLegalEntity(legalEntityDto);
  }

  toJson(legalEntity: LegalEntity): LegalEntityDto {
    // never used
    return {} as LegalEntityDto;
  }
}
