import { Injectable } from '@angular/core';
import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';

import { CompanyDto } from './company.dto';

@Injectable({ providedIn: 'root' })
export class CompanyResource extends BaseHttpResource<CompanyDto, CoreListEnvelop<CompanyDto>> {
  constructor() {
    super('company-profile');
  }
}
