import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppMetaTag, MetaTag } from '@config/meta.config';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private readonly updateTitle$ = new Subject<void>();
  private readonly metaTags: MetaTag[] =
    environment.name !== 'local' && environment.name !== 'qa'
      ? AppMetaTag[environment.name]
      : AppMetaTag.stage;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly metaService: Meta,
    private readonly titleService: Title
  ) {
    this.updateTitle$
      .pipe(
        debounceTime(50),
        filter(() => !this.router.url.startsWith('/cms/'))
      )
      .subscribe(() => {
        // butter cms page title is dynamically set via api -> do not set title via router data
        this.titleService.setTitle(this.translateTitle(this.activatedRoute.snapshot));
      });
  }

  updateTitle(): void {
    this.updateTitle$.next();
  }

  updateMetaTags(): void {
    for (const { name, content } of this.metaTags) {
      this.metaService.updateTag({
        name,
        content: this.translateService.instant(content),
      });
    }
  }

  private extractTitleFromRoute(obj: ActivatedRouteSnapshot): string {
    const firstChild = obj.firstChild;
    if (firstChild instanceof ActivatedRouteSnapshot) {
      return this.extractTitleFromRoute(firstChild);
    } else {
      return obj.data['title'];
    }
  }

  private translateTitle(snapshot: ActivatedRouteSnapshot): string {
    return this.translateService.instant(
      this.extractTitleFromRoute(snapshot) || 'META_TITLE.GENERIC'
    );
  }
}
