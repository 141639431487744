import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AssetCachingService {
  private readonly cache = new Map<string, string>();

  getFromCache(assetName: string): string | undefined {
    return this.cache.get(assetName);
  }

  setInCache(assetName: string, svgValue: string): void {
    this.cache.set(assetName, svgValue);
  }
}
