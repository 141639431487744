import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AuthFacade } from '@mkp/auth/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnverifiedUserGuard {
  constructor(
    private authFacade: AuthFacade,
    private router: Router
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    return this.isUnverified();
  }

  private isUnverified(): Observable<boolean | UrlTree> {
    return this.authFacade.isVerified$.pipe(
      map((verified) => {
        return !verified || this.router.parseUrl(AppConfig.routes.claimCompany);
      })
    );
  }
}
