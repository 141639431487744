import { ApplicationRef, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ModalService } from '@app/services/modal.service';
import { versionEffectsActions } from '@mkp/core/state/actions';
import { ActionState } from '@mkp/shared/ui-library';
import { createEffect } from '@ngrx/effects';
import { concatMap, filter, interval, map, switchMap, take, tap } from 'rxjs';

const TWENTY_SECONDS = 1000 * 20;

// check for update every 20 seconds
export const checkForUpdate = createEffect(
  (applicationRef = inject(ApplicationRef), swUpdate = inject(SwUpdate)) =>
    applicationRef.isStable.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => interval(TWENTY_SECONDS).pipe(concatMap(() => swUpdate.checkForUpdate())))
    ),
  {
    dispatch: false,
    functional: true,
  }
);

// when a new update is ready to be activated: update the state
export const listenForUpdateReady = createEffect(
  (swUpdate = inject(SwUpdate)) =>
    swUpdate.versionUpdates.pipe(
      filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
      map(() => versionEffectsActions.newVersionIsAvailable())
    ),
  {
    functional: true,
  }
);

// on unrecoverable state: we show a modal and force users to refresh the app
export const listenForUnrecoverableState = createEffect(
  (swUpdate = inject(SwUpdate), modalService = inject(ModalService), window = inject(Window)) =>
    swUpdate.unrecoverable.pipe(
      tap(() =>
        modalService.openDialog({
          primaryLabel: 'ERROR_UPDATE_DIALOG.RELOAD',
          state: ActionState.Warning,
          content: 'ERROR_UPDATE_DIALOG.TEXT',
          title: 'ERROR_UPDATE_DIALOG.TITLE',
          primaryDataTest: 'pwa-unrecoverable-modal-confirm-btn',
          secondaryDataTest: 'pwa-unrecoverable-modal-cancel-btn',
          action: () => swUpdate.activateUpdate().then(() => window.location.reload()),
        })
      ),
      take(1)
    ),
  { dispatch: false, functional: true }
);
