<ui-carousel-items
  [width]="width$ | async"
  [index]="index"
  (upliftCollection)="updateCollection($event)"
>
  <ng-content />
</ui-carousel-items>

<ui-carousel-controls
  *ngIf="navigation && collection.length > 0"
  [index]="index"
  [collection]="collection"
  (slideTo)="updateIndex($event)"
/>
