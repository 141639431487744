import { CreditDto } from '../credit/credit.dto';
import { toViewModel as toViewModelCredit } from '../credit/credit.mapper';
import { CreditRedemptionDto, CreditRedemptionViewModel } from './credit-redemption.dto';
import { getInformation } from './credit-redemption.util';
import { PRODUCT_TRANSLATIONS } from '@mkp/shared/util-product';

export const toViewModel = (credit: CreditRedemptionDto): CreditRedemptionViewModel => ({
  id: credit.id,
  start: credit.start,
  end: credit.end,
  externalId: credit.externalId,
  externalSource: credit.externalSource,
  stoppedAt: credit.stoppedAt,
  creditId: credit.creditId,
  vacancyId: credit.vacancyId,
  jobHitCount: credit.jobHitCount,
  jobApplicationSentCount: credit.jobApplicationSentCount,
  jobApplicationStartCount: credit.jobApplicationStartCount,
  information: {
    productName: PRODUCT_TRANSLATIONS[credit._embedded.credit.product.type]?.longname ?? '',
    productCode: credit._embedded.credit.product.type,
    ...getInformation(credit),
  },
  _state: credit._state,
  _version: credit._version,
  _embedded: {
    credit: toViewModelCredit(credit._embedded.credit as CreditDto),
  },
});
