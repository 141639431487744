import { UUID } from '@mkp/shared/data-access';
import { BenefitLanguages } from './benefit-languages.model';

export class Benefit {
  id!: UUID;
  name!: BenefitLanguages;
  _version?: string;
  status?: boolean;
}

export interface BenefitForCurrentLang {
  id: UUID;
  name: string;
  _version?: string;
}

export type BenefitsForCurrentLangDictionary = Record<UUID, BenefitForCurrentLang>;
