import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TrafficFunnel } from '@shared/enums';
import { AppConfig } from '@config/app.config';

@Injectable({
  providedIn: 'root',
})
export class ActiveTrafficFunnelGuard {
  readonly trafficFunnels = Object.values(TrafficFunnel);

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const { funnel = null } = route.params;
    return (
      this.trafficFunnels.includes(funnel) || this.router.parseUrl(AppConfig.routes.onboarding)
    );
  }
}
