<ui-button
  *ngIf="linkConfig"
  class="link"
  [mkpDataTest]="linkDataTest"
  [state]="linkConfig.state"
  [config]="linkConfig.config"
  [routerLink]="linkConfig.content.url"
>
  {{ linkConfig.content.label | translate }}
</ui-button>

<ui-button
  *ngIf="primaryConfig"
  [mkpDataTest]="primaryDataTest"
  [state]="primaryConfig.state"
  [config]="primaryConfig.config"
  [loading]="isLoading"
  [disabled]="isLoading"
  (click)="handlePrimaryClick.emit()"
>
   <span [ngClass]="{'hidden-text': isLoading}"> {{ primaryConfig.content.label | translate }} </span>
</ui-button>
<ui-button
  *ngIf="secondaryConfig"
  [mkpDataTest]="secondaryDataTest"
  [config]="secondaryConfig.config"
  [disabled]="isLoading"
  (click)="handleSecondaryClick.emit()"
>
  {{ secondaryConfig.content.label | translate }}
</ui-button>

<ui-button
  *ngIf="tertiaryConfig"
  [mkpDataTest]="tertiaryDataTest"
  [config]="tertiaryConfig.config"
  (click)="handleTertiaryClick.emit()"
>
  {{ tertiaryConfig.content.label | translate }}
</ui-button>
