import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({ selector: '[mkpInViewportNotifier]', standalone: true })
export class InViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange = new EventEmitter<boolean>();

  @Input() rootMargin = 0;

  private observer!: IntersectionObserver;

  constructor(@Host() private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: `${this.rootMargin}px`,
      threshold: 0.25,
    };
    this.observer = new IntersectionObserver(this.callback, options);
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private callback: IntersectionObserverCallback = ([entry]) => {
    this.visibilityChange.emit(entry.isIntersecting);
  };
}
