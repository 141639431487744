import { TopHeader, UserMenuHeaderItemOptions } from '@mkp/layout/state';
import { HeaderBuilder } from './layout-header-config-builder';

export const loggedInUserDefaultTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('burger_menu')
    .withLefBoxItem('selected_workspace')
    .withOptionalRightBoxItem(!isMobile ? 'lang_menu' : undefined)
    .withRightBoxItem<UserMenuHeaderItemOptions>('user_menu')
    .withName(`loggedInUserDefault${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();

export const workspaceSelectTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo', { disabled: true })
    .withOptionalRightBoxItem(!isMobile ? 'lang_menu' : undefined)
    .withRightBoxItem('user_menu')
    .withName(`workspaceSelect${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();

export const landingPageTopHeader = (isLoggedIn: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo')
    .withRightBoxItem('lang_menu')
    .withRightBoxItem(isLoggedIn ? 'dashboard_link' : 'login_link')
    .withName(`landingPageTopHeader`)
    .build();

export const supportCenterTopHeader = (isLoggedIn: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo', { disabled: true })
    .withRightBoxItem('lang_menu')
    .withRightBoxItem(isLoggedIn ? 'dashboard_link' : 'login_link')
    .withName(`supportCenterTopHeader`)
    .build();

export const cmsTopHeader = (isLoggedIn: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('light')
    .withLefBoxItem('url_logos')
    .withLefBoxItem('logo_divider')
    .withLefBoxItem('one_logo')
    .withRightBoxItem('lang_menu', { platformStyle: !isLoggedIn })
    .withRightBoxItem(isLoggedIn ? 'user_menu' : 'login_link', { platformStyle: !isLoggedIn })
    .withName(`cms${isLoggedIn ? 'LoggedIn' : 'Visitor'}TopHeader`)
    .build();

export const regFlowTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('one_logo')
    .withOptionalRightBoxItem(!isMobile ? 'platform_logos' : undefined)
    .withRightBoxItem('lang_menu')
    .withName(`regFlow${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();

export const withBackButtonTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withLefBoxItem('go_back')
    .withOptionalRightBoxItem(!isMobile ? 'lang_menu' : undefined)
    .withRightBoxItem('user_menu')
    .withName(`withBackButton${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();

export const summaryTopHeader = (isMobile: boolean): TopHeader =>
  new HeaderBuilder()
    .withHeaderBackground('dark')
    .withOptionalRightBoxItem(!isMobile ? 'lang_menu' : undefined)
    .withRightBoxItem('user_menu')
    .withName(`summary${isMobile ? 'Mobile' : ''}TopHeader`)
    .build();
