export interface MessageError {
  message: string;
}

/**
 * @deprecated use getMessageError instead to convert to MessageError and produce a console.error in case of issue
 */
export const isMessageError = (
  error: unknown,
  className = 'UnknownClass'
): error is MessageError => {
  // if the error doesn't have a message: we mutate the error so that it always has one
  if ((error as MessageError).message == null) {
    (error as MessageError).message = `${className} - unknown error`;
  }

  // the resulting error will always have a message, so we can send true here
  return true;
};

const isErrorWithMessage = (error: unknown): error is MessageError =>
  (error as MessageError).message != undefined;
// the new way to get MessageError is less bullshitty and easier to use
export const getMessageError = (error: unknown, className = 'UnknownClass'): MessageError => {
  // if error is a string then simply convert it to MessageError
  if (typeof error === 'string') {
    return { message: error };
  }

  // if error is neither a string nor a MessageError: return a default MessageError and send an error to sentry
  if (!isErrorWithMessage(error)) {
    const errorMessage = `${className} - invalid error (wrong format)`;
    console.error(errorMessage, error);
    return { message: errorMessage };
  }

  // we should probably filter the return here, but we would need to test for regressions
  // return { message: error.message };
  return error;
};
