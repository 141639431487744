export interface LabelConfig {
  color: LabelColor;
}

export type LabelColor =
  | 'blue'
  | 'turquoise'
  | 'grey'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'yellow';

export type LabelClass = `label-${LabelColor}`;

export const createClass = (color: LabelColor): LabelClass => `label-${color}`;
