import { Account } from '@mkp/account/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const accountTypeaheadActions = createActionGroup({
  source: 'Account Typeahead',
  events: {
    'Select Account': props<{ account: Account; selectedAccountId: string }>(),
    'Unselect Account': emptyProps(),
  },
});
