import { Directive, ElementRef, HostBinding, HostListener, Input, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { environment } from '@env/environment';
import {
  CallClickEventDto,
  ClickEventDtoMainFields,
  CtaInternalClickEventDto,
  DownloadClickEventDto,
  ExternalClickEventDto,
  GenericClickEventDto,
  InternalClickEventDto,
  MailClickEventDto,
  TealiumService,
} from '@mkp/tracking/feature-tealium';

const REDACTED_TEXT = '-redacted-';

@Directive({
  selector: '[mkpDataTest]',
  standalone: true,
})
export class DataTestDirective {
  private readonly elementRef = inject(ElementRef);
  private readonly tealiumService = inject(TealiumService);
  private readonly routerLink = inject(RouterLink, { optional: true });

  @HostBinding('attr.data-test') dataTest = '';
  @Input() trackingCta = false;
  @Input() set mkpDataTest(dataTest: string | undefined) {
    if (!dataTest) {
      console.error(
        `ClickTrackingDirective: no dataTest defined for element ${this.elementRef.nativeElement.innerText}`
      );
    }
    this.dataTest = dataTest ?? '';
  }
  @Input() trackingUrl: string | undefined;
  @Input() trackingDetails = '';
  @Input() hideTrackingText = false;

  @HostListener('click') onClick() {
    if (this.dataTest) {
      const { innerText, href } = this.elementRef.nativeElement;
      const url = this.trackingUrl ?? href ?? this.routerLink?.urlTree?.toString();

      const mainFields = getMainFields({
        dataTest: this.dataTest,
        innerText: this.hideTrackingText ? REDACTED_TEXT : cleanText(innerText),
        details: this.trackingDetails,
      });
      const clickEventDto = !url
        ? getGenericClickEvent(mainFields)
        : isMailLink(url)
          ? getMailClickEvent(url, mainFields, this.hideTrackingText)
          : isCallLink(url)
            ? getCallClickEvent(url, mainFields, this.hideTrackingText)
            : isDownloadLink(url)
              ? getDownloadClickEvent(url, mainFields, this.hideTrackingText)
              : isExternalLink(url)
                ? getExternalClickEvent(url, mainFields)
                : getInternalClickEvent(url, mainFields, this.trackingCta);

      this.tealiumService.click(clickEventDto);
    } else {
      console.error(`DataTestDirective - sending click event - NO DATATEST`);
    }
  }
}

interface ClickEventParams {
  dataTest: string;
  innerText: string;
  details: string;
}
const getMainFields = ({
  dataTest,
  innerText,
  details,
}: ClickEventParams): ClickEventDtoMainFields => ({
  event_name: 'click',
  event_source: 'application',
  event_origin: 'jobcloud.ai',
  event_component_type: dataTest,
  event_link_text: innerText.trim(),
  event_details: details,
});

// innerText will be undefined in jest
const cleanText = (text: string | undefined): string => text?.replace(/\n/g, ' ') ?? '';

const getGenericClickEvent = (mainFields: ClickEventDtoMainFields): GenericClickEventDto => ({
  ...mainFields,
  event_name: 'click',
  event_type: 'generic_click',
});

const isMailLink = (url: string): boolean => url.startsWith('mailto:');

const getMailClickEvent = (
  url: string,
  mainFields: ClickEventDtoMainFields,
  hideTrackingText: boolean
): MailClickEventDto => ({
  ...mainFields,
  event_name: 'click',
  event_target_url_full: hideTrackingText ? REDACTED_TEXT : url,
  event_type: 'mail_link',
});

const isCallLink = (url: string): boolean => url.startsWith('tel:');

const getCallClickEvent = (
  url: string,
  mainFields: ClickEventDtoMainFields,
  hideTrackingText: boolean
): CallClickEventDto => ({
  ...mainFields,
  event_name: 'click',
  event_target_url_full: hideTrackingText ? REDACTED_TEXT : url,
  event_type: 'call_link',
});

const isDownloadLink = (url: string) =>
  url.startsWith('https://media') && url.includes('/download/');

const getDownloadClickEvent = (
  url: string,
  mainFields: ClickEventDtoMainFields,
  hideTrackingText: boolean
): DownloadClickEventDto => ({
  ...mainFields,
  event_name: 'click',
  event_target_url_full: hideTrackingText ? REDACTED_TEXT : url,
  event_type: 'download_link',
});

const isExternalLink = (url: string): boolean =>
  url.includes('http') && !url.includes(environment.url);

const getExternalClickEvent = (
  url: string,
  mainFields: ClickEventDtoMainFields
): ExternalClickEventDto => ({
  ...mainFields,
  event_name: 'click',
  event_target_url_full: url,
  event_type: 'external_link',
});

const getInternalLink = (url: string): string =>
  url.includes(environment.url) ? url : `${environment.url}${url}`;

const getInternalClickEvent = (
  url: string,
  mainFields: ClickEventDtoMainFields,
  trackingCta: boolean
): InternalClickEventDto | CtaInternalClickEventDto => ({
  ...mainFields,
  event_name: trackingCta ? 'cta_click' : 'click',
  event_target_url_full: getInternalLink(url),
  event_type: 'internal_link',
});
