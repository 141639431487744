import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LabelComponent, LabelConfig } from '@mkp/shared/ui-library';
import {
  ProductCode,
  ProductPlatformGroup,
  getPlatformGroupFromProductCode,
} from '@mkp/shared/util-product';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mkp-product-calendar-label',
  standalone: true,
  imports: [TranslateModule, LabelComponent],
  templateUrl: './product-calendar-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCalendarLabelComponent implements OnChanges {
  @Input() productCode!: ProductCode;
  @Input() title!: string;

  platformGroup?: ProductPlatformGroup;

  ngOnChanges({ productCode }: SimpleChanges): void {
    if (productCode) {
      this.platformGroup = getPlatformGroupFromProductCode(this.productCode);
    }
  }

  readonly defaultLabelConfig: LabelConfig = { color: 'purple' };

  readonly labelConfig: { [key in ProductPlatformGroup]: LabelConfig } = {
    [ProductPlatformGroup.Jobs]: { color: 'blue' },
    [ProductPlatformGroup.JobScout24]: { color: 'orange' },
    [ProductPlatformGroup.JobUp]: { color: 'green' },
  };
}
