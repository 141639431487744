import {
  VerificationFunnel,
  VerificationStatus,
  VerifyIdentityParams,
} from './verify-identity.model';

export const verifyIdentityPendingParams: VerifyIdentityParams = {
  type: VerificationFunnel.Email,
  status: VerificationStatus.Pending,
};
