export type QueryParams = Partial<{
  offset: number;
  rows: number;
  term: string;
  limit: number;
  sort: string;
  filter: string;
}>;

export type QueryHeaders = Partial<{
  // the maxRetry header will be intercepted and removed before reaching the API
  maxRetry: number;
}>;

// according to the core-BE documentation, there are only two delimiters
export const escapeDelimiters = (filter: string): string =>
  filter.replace(',', '\\,').replace(';', '\\;');
