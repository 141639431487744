import { randomIntFromInterval } from '@mkp/shared/util-math';
import { AbTest, AbTestConfig } from './ab-test.config';

const getRandomIndex = (variants: AbTestConfig[keyof AbTestConfig]) =>
  randomIntFromInterval(0, variants.length - 1);

export const randomlyGenerateAbTestFromConfig = (config: AbTestConfig): AbTest =>
  Object.entries(config).reduce(
    (acc, [key, variants]) => ({ ...acc, [key]: variants[getRandomIndex(variants)] }),
    {} as AbTest
  );
