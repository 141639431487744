import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CreditRedemptionEffects } from './credit-redemption.effects';
import { creditRedemptionFeature } from './credit-redemption.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(creditRedemptionFeature),
    EffectsModule.forFeature([CreditRedemptionEffects]),
  ],
})
export class CreditRedemptionStateModule {}
