import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, finalize, of, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetResource {
  private readonly http = inject(HttpClient);
  private readonly httpCache = new Map<string, Observable<string | undefined>>();

  loadAsset(assetName: string): Observable<string | undefined> {
    const cachedCall = this.httpCache.get(assetName);
    if (cachedCall) {
      return cachedCall;
    }

    const call = this.getCall(assetName).pipe(finalize(() => this.httpCache.delete(assetName)));
    this.httpCache.set(assetName, call);

    return call;
  }

  private getCall(assetName: string): Observable<string | undefined> {
    return this.http.get(getUrl(assetName), { responseType: 'text' }).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError((error) => {
        console.error(`Error loading SVG ${assetName}:`, error);
        return of(undefined);
      })
    );
  }
}

const getUrl = (assetName: string): string => `assets/icons/${assetName}.svg`;
