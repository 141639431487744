<div
  *ngIf="config"
  [class.disabled]="config?.disabled"
  [class.has-errors]="config?.hasErrors"
  class="select-wrapper"
>
  <div *ngIf="config?.labelText || config?.topCaption" class="top-wrapper">
    <label>{{ config?.labelText | translate }}</label>
    <span class="top-caption">{{ config?.topCaption | translate }}</span>
  </div>
  <div #wrapper class="input-wrapper" [class.disabled]="config?.disabled">
    <mat-select
      [formControl]="$any(formControl)"
      (ngModelChange)="onChange($event)"
      [placeholder]="config?.placeholder"
      disableRipple="true"
      disableOptionCentering="true"
      hideSingleSelectionIndicator="true"
      panelClass="select-custom"
      [panelWidth]="wrapper.clientWidth + 4"
      [mkpDataTest]="config?.dataTest"
    >
      <mat-option
        *ngFor="let option of config.options; index as i"
        [value]="option.id"
        mkpDataTest="{{config?.dataTest}}-{{i}}"
      >
        <span [innerHtml]="option.name | translate"></span>
      </mat-option>
    </mat-select>
  </div>
  <div class="bottom-wrapper">
    <span *ngIf="config?.bottomCaption as bottomCaption" class="bottom-caption"
      >{{ bottomCaption | translate }}</span
    >
  </div>
</div>
