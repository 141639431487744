import {
  Category,
  ContentCategory,
  EventCategory,
  UserPartialTracking,
} from '@mkp/tracking/feature-tealium';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TrackingActions = createActionGroup({
  source: 'Tracking',
  events: {
    'Load Tealium Start': emptyProps(),
    'Load Tealium Success': emptyProps(),
    'Load Tealium Failed': emptyProps(),
    'Send Pure Frontend Event': props<Category>(),
    // @marko due to something I am not able to understand... this will be typed with any
    // the claim_company form as a company string item... somehow it converts to an object
    'Send Registration Event': props<UserPartialTracking>(),
    'Send Cms Event': props<{
      payload: EventCategory;
      cmsTracking: ContentCategory;
    }>(),
    'Send Job Ad Create Start Event': emptyProps(),
    'Send Open Logo Guidelines Event': emptyProps(),
  },
});
