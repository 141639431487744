import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  save<T>(key: string, obj: T): void {
    try {
      localStorage.setItem(key, JSON.stringify(obj));
    } catch (e) {
      console.error(`Error saving to localStorage: ${e}`);
    }
  }

  get<T>(key: string): T | null {
    const serializedObj = localStorage.getItem(key);

    if (!serializedObj) {
      return null;
    }

    try {
      return JSON.parse(serializedObj) as T;
    } catch (e) {
      console.error(`Error getting data from localStorage: ${e}`);
      return null;
    }
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}
