import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: 'img[mkpLazyLoad]',
  standalone: true,
})
export class LazyLoadImagesDirective implements OnChanges {
  @HostBinding('attr.src') srcAttr!: string;
  @Input() src!: string;
  @Input() carousel = false;

  constructor(
    private el: ElementRef,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnChanges({ src, carousel }: SimpleChanges) {
    if (src || carousel) {
      this.refresh();
    }
  }

  public refresh() {
    this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  public canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  public lazyLoadImage() {
    let element = this.el.nativeElement;
    if (this.carousel) {
      const previuousItem = element.parentElement.parentElement.previousElementSibling;
      element = previuousItem ? previuousItem : element.parentElement;
    }
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(element);
        }
      });
    });
    obs.observe(element);
  }

  public loadImage() {
    this.srcAttr = this.src;
    this._cd.markForCheck();
  }
}
