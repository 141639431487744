import { layoutFeature } from './layout.reducer';
import { createSelector } from '@ngrx/store';

export const selectLayout = layoutFeature.selectLayoutStateState;
export const selectTopHeader = layoutFeature.selectTopHeader;
export const selectIsNavigationCollapsed = layoutFeature.selectIsNavigationCollapsed;
export const selectHasNavigation = layoutFeature.selectHasNavigation;
export const selectLeftBoxContent = createSelector(selectTopHeader, (state) => state.leftBoxContent);
export const selectRightBoxContent = createSelector(selectTopHeader, (state) => state.rightBoxContent);
export const selectLayoutElements = createSelector(
  selectLayout,
  (state) => ({
    hasBorders: state.hasBorders,
    hasNavigation: state.hasNavigation,
    hasTopHeader: state.topHeader.hasTopHeader,
    isNavigationCollapsed: state.isNavigationCollapsed,
    normalDocumentFlow: state.normalDocumentFlow,
    hasFooter: state.hasFooter,
  })
);
