import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { InputWrapperComponent } from './input-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule],
  exports: [InputWrapperComponent],
  declarations: [InputWrapperComponent],
})
export class InputWrapperModule {}
