import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { UpgradeService } from '@features/upsell/services';
import { SnackbarService } from '@mkp/shared/ui-library';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EcomAuthGuard {
  constructor(
    private _router: Router,
    private _upgradeService: UpgradeService,
    private _notify: SnackbarService,
    private _translateService: TranslateService
  ) {}

  canLoad(): Observable<boolean> {
    return this.checkAuth();
  }

  private checkAuth(): Observable<boolean> {
    return this._upgradeService.checkAuth().pipe(
      take(1),
      catchError(() => {
        this._router.navigate([AppConfig.routes.home]);
        this._translateService
          .get('ECOM.AUTH_ERROR')
          .pipe(take(1))
          .subscribe((res: string) => this._notify.show(res));
        return of(false);
      })
    );
  }
}
