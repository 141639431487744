// use this config to define the AB tests
export const AB_TEST_CONFIG = {
  reverseProductOrder: ['no-reverse', 'reverse'],
  removeProduct: ['x', 'y', 'z'],
} as const;

export type AbTestConfig = typeof AB_TEST_CONFIG;
export type AbTest = { [key in keyof AbTestConfig]: AbTestConfig[key][number] };

export interface AbTestState {
  versions: AbTest;
}

export const AB_TEST_LOCAL_STORAGE_KEY = 'jobcloud.abTestState';
