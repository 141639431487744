import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppConfig } from '@app/config/app.config';
import {
  applicationApiActions,
  applicationExistGuardActions,
} from '@mkp/application/state/actions';
import { QUERY_PARAMS_ID } from '@mkp/shared/util-router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, race } from 'rxjs';

export const applicationExistGuard: CanActivateFn = (route) => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const router = inject(Router);

  store.dispatch(
    applicationExistGuardActions.canActivate({ id: route.queryParams[QUERY_PARAMS_ID] })
  );

  return route.queryParams[QUERY_PARAMS_ID]
    ? race(
        actions$.pipe(
          ofType(applicationApiActions.routeApplicationLoadedSuccess),
          map(() => true)
        ),
        actions$.pipe(
          ofType(applicationApiActions.routeApplicationLoadedFailure),
          map(() => router.createUrlTree([AppConfig.routes.error404]))
        )
      )
    : true;
};
