import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BadgeConfig, BadgeType } from './badge.config';

const DEFAULT_CONFIG: BadgeConfig = {
  type: BadgeType.Dot,
};

@Component({
  selector: 'ui-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BadgeComponent implements OnChanges {
  @Input() config: BadgeConfig = DEFAULT_CONFIG;
  @HostBinding('class') typeClass = this.config.type;
  BadgeType = BadgeType;

  ngOnChanges({ config }: SimpleChanges) {
    if (config) {
      this.typeClass = this.config.type;
    }
  }
}
