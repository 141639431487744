import { ActionState, Button, ButtonColor, ButtonSize, ButtonVariant } from './button.model';

type Value =
  | ButtonVariant
  | ButtonColor
  | ButtonSize
  | ActionState
  | string
  | string[]
  | number
  | boolean;

export const styling = (button: Button, state: ActionState) => {
  return Object.keys(button)
    .map((key) => key as keyof Button)
    .map((key) => stylingFactory(button, key, button[key] as Value, state))
    .join(' ');
};

const stylingFactory = (button: Button, key: keyof Button, value: Value, state: ActionState) => {
  const { color, mobileFullWidth } = button;

  const factory = {
    color: () => (!state ? `button-color-${value}` : `button-color-${color}-${state}`),
    size: () => `button-size-${value} ${sizeClass(value)}`,
    classList: () => (typeof value === 'object' ? value.join(' ') : ''),
    variant: () => `button-variant-${value}`,
    state: () => `button-color-${color}-${state}`,
    fullWidth: () => 'button-full-width',
    mobileFullWidth: () => (mobileFullWidth ? 'button-full-width-mobile' : ''),
    elevation: () => `mat-elevation-z${value}`,
  };

  return key in factory ? factory[key as keyof typeof factory]() : ' ';
};

const sizeClass = (value: Value) => {
  return value === ButtonSize.Medium ? 'body-default bold' : 'body-s semibold';
};
