import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '@shared/services/resource.service';

import { CompanyLocationSerializer } from '@company/interfaces/company-location-serializer.interface';
import { environment } from '@env/environment';
import { Location } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class CompanyLocationService extends ResourceService<Location> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.api.core, 'location', new CompanyLocationSerializer());
  }
}
