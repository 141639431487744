import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { captureException, init } from '@sentry/browser';
import { AppConfig } from '@config/app.config';
import { AppVersion } from '@shared/models/app-version.model';

@Injectable({ providedIn: 'root' })
export class SentryService {
  captureException(error: Error): void {
    if (shouldCaptureException(error)) {
      this.captureSentryException(error, 'error');
    }
  }

  captureWarning(error: Error): void {
    if (shouldCaptureException(error)) {
      this.captureSentryException(error, 'warning');
    }
  }

  private captureSentryException(error: any, level: 'error' | 'warning'): void {
    const errorInstance = error instanceof Error ? error : new Error(this.parseMessage(error));
    captureException(errorInstance, { level });
  }

  private parseMessage(error: any): string {
    return error?.message ? error.message : JSON.stringify(error);
  }
}

init({
  dsn: AppConfig.sentry.dsn,
  environment: environment.name,
  ignoreErrors: ['Non-Error exception captured'],
  // [Postponed due to baggage and Cors issue]
  // integrations: [
  //   new BrowserTracing({
  //     tracePropagationTargets: [environment.sentry.target, /^\//],
  //   }) as Integration,
  // ],
  // tracesSampleRate: environment.sentry.tracesSampleRate, // 0.2,
  release: stripVersion(environment.version),
});

const shouldCaptureException = (error: Error): boolean => {
  const isDevEnvironment = ['dev', 'qa'].includes(environment.name);
  if (isDevEnvironment) {
    // we didn't configure sentry for dev/qa environments, so we need to display the error
    console.error(`Error captured by sentry:\n`, error);
  }
  return !isDevEnvironment;
};

function stripVersion(version: AppVersion) {
  const dash = version.indexOf('-');
  if (dash != -1) {
    const build = version.indexOf('.', dash);
    return version.substring(0, build != -1 ? build : version.length);
  }
  return version;
}
