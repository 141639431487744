import { ActionState } from '../button/button.model';

export type FeedbackClass = `feedback-${ActionState}`;
export const iconClassMapping: { [state in ActionState]?: string } = {
  [ActionState.Success]: 'check_circle',
  [ActionState.Info]: 'info',
  [ActionState.Warning]: 'error_outline',
  [ActionState.Error]: 'cancel',
};

export const createFeedbackClass = (state: ActionState): FeedbackClass =>
  `feedback-${state}` as FeedbackClass;
