import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { legalEntityGuardActions } from '@store/actions';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// JMP-4593: when entering legal-entity guard we want to refresh the account to check if legal-entity was recently activated
// we're using a guard because we want this check on every route change, but it doesn't actually protect the route
export class ActiveLegalEntityGuard {
  private readonly store = inject(Store);

  canActivate(): Observable<boolean> {
    // an effect on this action will trigger a refresh of the account if legal-entity is NOT active
    this.store.dispatch(legalEntityGuardActions.canActivate());

    return of(true);
  }
}
