import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationStatus } from '@mkp/application/models';
import { createActionGroup, props } from '@ngrx/store';

export const applicationStatusApiActions = createActionGroup({
  source: 'Application Status Api',
  events: {
    'Application Statuses Loaded Success': props<{ applicationStatuses: ApplicationStatus[] }>(),
    'Application Statuses Loaded Failure': props<{ errorMessage: HttpErrorResponse }>(),
  },
});
