import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { Country } from './country.dto';

@Injectable({
  providedIn: 'root',
})
export class CountryResource {
  constructor(private readonly httpClient: HttpClient) {}

  fetchCountry(): Observable<Country> {
    return this.httpClient.get<Country>(`${environment.url}/country`, { observe: 'response' }).pipe(
      map(({ headers }) => headers.get('country') as Country),
      catchError(() => of(Country.Others))
    );
  }
}
