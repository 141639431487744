export enum DisplayOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export enum DisplayType {
  Mobile = 'mobile',
  TabletPortrait = 'tablet_portrait',
  TabletLandscape = 'tablet_landscape',
  Desktop = 'desktop',
  Wide = 'wide',
}

export enum DisplayInterface {
  visitor,
  user,
  asVisitor,
  asUser,
}

/** @Note make interface instead of class */
export class Display {
  orientation: DisplayOrientation | string = DisplayOrientation.Portrait;
  type: DisplayType | string = DisplayType.Mobile;
  interface: DisplayInterface | string = DisplayInterface.visitor;
  size = {
    width: 0,
    height: 0,
  };
}
