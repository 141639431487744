import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import * as fromCart from '@cart/store/reducers';
import * as fromCompany from '@company/store/reducers';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromProductOptions from '@product-options/store/reducers';
import { fromUser } from '@user/store/reducers';
import * as fromBenefits from './benefit.reducer';
import * as fromLocation from './location.reducer';
import * as fromSettings from './settings.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export interface State {
  routerReducer: fromRouter.RouterReducerState;
  [fromSettings.featureKey]: fromSettings.State;
  [fromBenefits.featureKey]: fromBenefits.BenefitsState;
  [fromLocation.featureKey]?: fromLocation.State;
  [fromUser.featureKey]?: fromUser.State;
  [fromCompany.featureKey]?: fromCompany.CompanyState;
  [fromProductOptions.featureKey]?: fromProductOptions.State;
  cartState?: fromCart.CartState;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  [fromSettings.featureKey]: fromSettings.reducer,
  [fromBenefits.featureKey]:
    fromBenefits.BenefitsReducer as ActionReducer<fromBenefits.BenefitsState>,
  [fromLocation.featureKey]: fromLocation.reducer,
  [fromCompany.featureKey]: fromCompany.reducer as ActionReducer<
    fromCompany.CompanyState | undefined
  >,
  [fromUser.featureKey]: fromUser.reducer,
};

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }

    const { params, data } = state;

    return { url, queryParams, params, data };
  }
}

export const getBenefits = createFeatureSelector<fromBenefits.BenefitsState>(
  fromBenefits.featureKey
);
