import { LegalEntityDto } from './legal-entity.dto';
import { isLegalEntityState } from './legal-entity.helpers';
import { LegalEntity, LegalEntityState } from './legal-entity.model';

// just in case we find an unknown value, should not happen
const DEFAULT_LEGAL_ENTITY_STATE = LegalEntityState.InVerification;

export const mapLegalEntityDtosToLegalEntities = (dtos: LegalEntityDto[]) =>
  dtos.map((dto: LegalEntityDto) => mapLegalEntityDtoToLegalEntity(dto));

export const mapLegalEntityDtoToLegalEntity = (dto: LegalEntityDto): LegalEntity => ({
  id: dto.id,
  externalSource: dto.externalSource,
  externalId: dto.externalId,
  name: dto.name,
  state: getLegalEntityState(dto.state),
  employeeCountFrom: dto.employeeCountFrom,
  employeeCountTo: dto.employeeCountTo,
  industry: {
    de: dto._embedded.industry.name.de,
    en: dto._embedded.industry.name.en,
    fr: dto._embedded.industry.name.fr,
  },
  claimed: dto.claimed,
  industryId: dto.industryId,
  address: dto.address, // no mapping for address for now, but we could add one in the future
  createdBy: dto.createdBy,
  _version: dto._version,
  _embedded: dto._embedded,
  createdAt: dto.createdAt,
  updatedAt: dto.updatedAt,
  updatedBy: dto.updatedBy,
});

const getLegalEntityState = (state: string): LegalEntityState => {
  if (!isLegalEntityState(state)) {
    console.error(`Unknown Legal Entity State: ${state}`);
    return DEFAULT_LEGAL_ENTITY_STATE;
  }

  return state;
};
