import { CreditRedemptionViewModel } from '@mkp/shared/data-access';
import { ErrorState } from '@mkp/shared/util-state';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CreditStatusActions = createActionGroup({
  source: 'Credit Status',
  events: {
    'Load Credits': emptyProps(),
    'Load Credits Success': props<{ credits: CreditRedemptionViewModel[] }>(),
    'Load Credits Failed': props<{ error: ErrorState }>(),
    'Reset Credits': emptyProps(),
    'Reset Credits Success': emptyProps(),
    'Reset Credits Failed': props<{ error: ErrorState }>(),
  },
});
