import { sortMetadataDictionary } from '@mkp/metadata/data-access';
import { createSelector } from '@ngrx/store';
import * as fromMetadatas from './metadata.reducer';

import { LoadingState } from '@mkp/shared/util-state';
import { selectors } from './metadata.reducer';

export const {
  selectEntities,
  selectAll,
  selectTotal,
  selectLoaded,
  selectFeatureState: selectMetadatasFeatureState,
} = selectors;

export const selectMetadatasLoaded = createSelector(
  selectMetadatasFeatureState,
  (state) => state?.fetchState === LoadingState.LOADED
);

export const getMetadatasLoading = createSelector(
  selectMetadatasFeatureState,
  (state) => state?.fetchState === LoadingState.LOADING
);

export const selectMetadatasState = createSelector(
  selectMetadatasFeatureState,
  (state) => state ?? fromMetadatas.initialState
);

export const selectMetadatasEntities = createSelector(
  selectMetadatasFeatureState,
  (state) => state.metadatasList
);

export const selectSortedMetadataEntities = createSelector(
  selectMetadatasEntities,
  sortMetadataDictionary
);
