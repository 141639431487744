import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { vacancyExistsGuardActions } from '@vacancy/store/actions';
import { vacancyApiActions } from '@vacancy/store/actions/vacancy-api.actions';
import { getVacancyById } from '@vacancy/store/selectors/vacancy.selectors';
import { Observable, filter, map, race, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VacancyExistsGuard {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<true | UrlTree> {
    const vacancyId: string = route.params['id'];
    this.store.dispatch(vacancyExistsGuardActions.canActivate({ vacancyId }));

    return this.hasVacancy(vacancyId).pipe(
      map((vacancyExists) => vacancyExists || this.router.parseUrl(AppConfig.routes.error404)),
      take(1)
    );
  }

  private hasVacancy(vacancyId: string): Observable<boolean> {
    return race(this.hasVacancyInStore(vacancyId), this.hasVacancyInApi(vacancyId));
  }

  private hasVacancyInStore(vacancyId: string): Observable<boolean> {
    return this.store.select(getVacancyById(vacancyId)).pipe(
      filter(Boolean),
      map(() => true)
    );
  }

  private hasVacancyInApi(vacancyId: string): Observable<boolean> {
    return race(
      this.actions$.pipe(
        ofType(vacancyApiActions.loadVacancyFromGuardSuccess),
        filter(({ vacancy }) => vacancy.id === vacancyId),
        map(() => true)
      ),
      this.actions$.pipe(
        ofType(vacancyApiActions.loadVacancyFromGuardFailure),
        filter((action) => action.vacancyId === vacancyId),
        map(() => false)
      )
    );
  }
}
