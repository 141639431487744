import { UserDisplayType } from '@mkp/user/data-access';
import { User } from '@user/shared/models';
export interface LayoutElements {
  hasBorders: boolean;
  hasNavigation: boolean;
  hasTopHeader: boolean;
  hasFooter: boolean;
  isNavigationCollapsed: boolean | undefined;
  normalDocumentFlow: boolean;
}

export interface TopHeader {
  hasTopHeader: boolean;
  isLight: boolean;
  leftBoxContent?: HeaderItem[];
  rightBoxContent?: HeaderItem[];
  name: string | undefined
}

export interface HeaderItem {
  type: HeaderItemType;
  options?: any;
}

export interface UserMenuHeaderItemOptions {
  disabledOptions: 'support_center'[];
}

export type HeaderItemType =
  'one_logo' |
  'burger_menu' |
  'dashboard_link' |
  'header_search' |
  'go_back' |
  'logo_divider' |
  'login_link' |
  'url_logos' |
  'platform_logos' |
  'selected_workspace' |
  'customer_platform_logo' |
  'lang_menu' |
  'user_menu'
  ;

export interface LayoutInputParams {
  url: string;
  isMobile: boolean;
  isTablet: boolean,
  user: User | null | undefined
}

type LayoutUserDisplayTypeMapping = Record<UserDisplayType, string>;

const DISPLAY_TYPE_CLASS: LayoutUserDisplayTypeMapping = {
  [UserDisplayType.CustomerJobs]: 'display-type-jobs',
  [UserDisplayType.CustomerJobUp]: 'display-type-jobup',
  [UserDisplayType.CustomerJobScout24]: 'display-type-jobscout24',
  [UserDisplayType.CustomerService]: '',
  [UserDisplayType.CustomerJobcloud]: ''
  
};

export const getLayoutUserDisplayTypeClass = (userDisplayType: UserDisplayType) =>
  DISPLAY_TYPE_CLASS[userDisplayType];
