import { ApplicationTab, EmailContent } from '@mkp/application/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const applicationPageActions = createActionGroup({
  source: 'Applications Page',
  events: {
    Opened: props<{ tab: ApplicationTab; limit: number; offset: 0 }>(),
    'Load More Applications': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Load One More Application': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Load More Applications To Find Route Application': props<{
      tab: ApplicationTab;
      limit: number;
      offset: number;
    }>(),
    'Decline Application With Email': props<{
      applicationId: string;
      _version: string;
      declineStatusId: string;
      emailContent: EmailContent;
    }>(),
    'Decline Application Without Email': props<{
      applicationId: string;
      _version: string;
      declineStatusId: string;
    }>(),
    'Delete Application With Email': props<{
      applicationId: string;
      applicationFullName: string;
      emailContent: EmailContent;
    }>(),
    'Delete Application Without Email': props<{
      applicationId: string;
      applicationFullName: string;
    }>(),
    'Tab Changed': props<{ tab: ApplicationTab; limit: number; offset: 0 }>(),
    'User Clicked On Status In Dropdown': props<{
      applicationId: string;
      statusId: string;
      _version: string;
    }>(),
    'User Clicked On Status In Action Button': props<{
      applicationId: string;
      statusId: string;
      _version: string;
    }>(),
    'View Document': props<{
      pdfUrl: string;
      documentId: string;
      applicationId: string;
      originalUrl: string;
    }>(),
    'User Select Application': props<{ applicationId: string }>(),
    'Select Application On Change': props<{
      applicationId: string | undefined;
      changedApplicationWasLast: boolean;
    }>(),
    'Select First Application': props<{ applicationId: string | undefined }>(),
    'Select Route Application': props<{ applicationId: string }>(),
    'Download Document': props<{
      downloadUrl: string;
      documentId: string;
      applicationId: string;
      documentName: string;
    }>(),
    'Application Refresh Status Changed': emptyProps(),
  },
});
