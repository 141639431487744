import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { AppConfig, LocalStorageKeys } from '@app/config/app.config';
import { authenticatedRoutes, visitorUrls } from '@mkp/shared/routes';

@Injectable({ providedIn: 'root' })
export class RedirectService {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly window = inject(Window);
  private readonly destroyRef = inject(DestroyRef);

  // This service redirects the user that were trying to access an authenticated route while being logged-off
  // Casee 1 - redirect to login
  // For example: user is not logged-in but tries to access /vacancy
  // RedirectService will
  //  - save the target route `/vacancy` in localstorage
  //  - navigate to /login
  // after logging-in, a router.effect will
  // - read the target route in localstorage
  // - clear the localstorage value
  // - navigate to the target route
  // Case 2 - redirect to magic link expired
  // If user is trying to login with a magic link and the link that is expired or invalid
  // RedirectService will redirect to /magic-link-expired
  handleRedirect(): void {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (isPasswordlessLoginLinkExpired(params.error_description)) {
        this.router.navigate([
          AppConfig.routes.passwordlessLogin,
          AppConfig.routes.passwordlessLoginLinkExpired,
        ]);
      } else if (isRedirectablePath(this.window.location.pathname)) {
        this.redirectToPasswordlessLogin();
      }
    });
  }

  private redirectToPasswordlessLogin(): void {
    localStorage.setItem(
      LocalStorageKeys.redirectUri,
      `${this.window.location.pathname}${this.window.location.search}`
    );
    this.router.navigate([AppConfig.routes.passwordlessLogin]);
  }
}
const exceptionPaths = ['**', 'logout', ...Object.values(visitorUrls)];
const isRedirectablePath = (path: string): boolean => {
  const redirectablePaths = getRouteFirstFragments(authenticatedRoutes)
    .filter((path) => !exceptionPaths.includes(path))
    .map((path) => `/${path}`);

  // check if the path starts with one of the first fragment of the redirectable routes
  return redirectablePaths.some((redirectablePath) => path.startsWith(redirectablePath));
};

const getRouteFirstFragments = (routes: Routes): string[] =>
  routes.reduce(
    (acc: string[], route: Route) => [
      ...acc,
      ...(route.path === ''
        ? // when no path: look for children
          route.children
          ? // recursively get the paths of the children
            getRouteFirstFragments(route.children)
          : // no path and no children: it's the dashboard, ignore it
            []
        : // otherwise get the path of the route
          [route.path as string]),
    ],
    []
  );

const getRouteRegexps = (routes: string[]): RegExp[] =>
  routes.map((route) => new RegExp(`^/${route}(?:/[\\w-]*)?$`));

export const passwordlessLoginLinkExpiredErrors = Object.freeze(
  new Set([
    'Wrong email or verification code.',
    "You've reached the maximum number of attempts. Please try to login again.",
    'The verification code has expired. Please try to login again.',
  ])
);

const isPasswordlessLoginLinkExpired = (errorDescription: string): boolean => {
  return passwordlessLoginLinkExpiredErrors.has(errorDescription);
};
