import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FeatureFlags } from '@mkp/debug/state';

export const debugActions = createActionGroup({
  source: 'Debug',
  events: {
    'Toggle Editable Content': emptyProps(),
    'Force Refresh': emptyProps(),
    'Toggle Feature Flag': props<{ featureFlag: FeatureFlags; value: boolean }>(),
  },
});
