import { Injectable, inject } from '@angular/core';
import { Observable, filter, of, switchMap, tap } from 'rxjs';
import { AssetCachingService } from './asset-caching.service';
import { AssetResource } from './asset.resource';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private readonly assetCachingService = inject(AssetCachingService);
  private readonly assetResource = inject(AssetResource);

  getAssetWithCache(assetName: string): Observable<string> {
    return of(this.assetCachingService.getFromCache(assetName)).pipe(
      switchMap((svg) =>
        svg
          ? of(svg)
          : this.assetResource.loadAsset(assetName).pipe(
              filter(Boolean),
              tap((svg) => this.assetCachingService.setInCache(assetName, svg))
            )
      )
    );
  }
}
