import { Vacancy } from '@app/features/vacancy/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Location } from '@shared/models';

export const vacancyEditActions = createActionGroup({
  source: 'Vacancy Edit Page',
  events: {
    Enter: emptyProps(),
    'Create Location': props<{ location: Location }>(),
    'Delete Location': props<{ locationId: string }>(),
    'Create Vacancy': props<{ vacancy: Vacancy }>(),
    'Update Publication Ai Usage Consent': props<{ consent: boolean }>(),
  },
});
