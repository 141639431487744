import { inject, Injectable } from '@angular/core';
import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { AccountMembershipDto } from './account-membership.dto';
import { filter as rxFilter, map, Observable } from 'rxjs';
import { AccountMembershipMapper } from './account-membership.mapper';
import { AccountMembership, AccountMembershipPatch } from './account-membership.model';
import { QueryOptions } from '@shared/models';

interface PaginatedAccountMemberships {
  accountMemberships: AccountMembership[];
  _links: any;
  filter: string;
  totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class AccountMembershipResource extends BaseHttpResource<
  AccountMembershipDto,
  CoreListEnvelop<AccountMembershipDto>
> {
  private mapper = inject(AccountMembershipMapper);
  private ADMIN_ROLE = 'ACCOUNT_MEMBERSHIP_ROLE_ADMIN';
  constructor() {
    super('account-membership');
  }

  /**
   * Creates a new account membership with admin role
   * and active state.
   */
  createAccountMembership(accountId: string, userId: string): Observable<AccountMembership> {
    const membership = {
      accountId,
      userId,
      role: this.ADMIN_ROLE,
      state: 'ACTIVE',
    } as AccountMembershipDto;
    return this.add(membership).pipe(map((dto) => this.mapper.fromJson(dto)));
  }

  /**
   * Returns a list of account memberships and maps it to AccountMembership model.
   */
  public listAccountMemberships(query: QueryOptions): Observable<AccountMembership[]> {
    const options = query || { limit: 25 };
    return this.getWithQuery(options).pipe(
      map(({ _embedded }) => _embedded),
      rxFilter(Boolean),
      map(({ results: membershipDto }) => membershipDto.map((dto) => this.mapper.fromJson(dto)))
    );
  }

  /**
   * Returns a list of account memberships and maps it to AccountMembership model
   * with pagination details included.
   */
  public listPaginatedAccountMemberships(
    query: QueryOptions
  ): Observable<PaginatedAccountMemberships> {
    const options = query || { limit: 25 };
    return this.getWithQuery(options).pipe(
      map(({ _embedded, filter, _links, totalCount }) => ({
        accountMemberships: _embedded.results.map((dto) => this.mapper.fromJson(dto)),
        _links,
        filter,
        totalCount,
      }))
    );
  }

  public patchAccountMembershipState(
    accountMembershipPatch: AccountMembershipPatch
  ): Observable<AccountMembership> {
    return this.update(
      accountMembershipPatch.id,
      this.mapper.toJson(accountMembershipPatch),
      'patch'
    ).pipe(map((accountMembershipDto) => this.mapper.fromJson(accountMembershipDto)));
  }
}
