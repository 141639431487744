import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import {
  featureMapping,
  Features,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ProductPlatformOther,
} from '@mkp/shared/util-product';
import { Store } from '@ngrx/store';
import { selectInterfaceLanguage } from '@store/selectors';
import { ProductTooltipComponent } from '../product-tooltip/product-tooltip.component';

const platformLogoMapping: { [key in ProductOfferingPlatform]: string } = {
  [ProductPlatformGroup.Jobs]: 'jobs-ch.svg',
  [ProductPlatformGroup.JobScout24]: 'jobscout24-logo.svg',
  [ProductPlatformGroup.JobUp]: 'jobup-logo.svg',
  [ProductPlatformOther.JobWinner]: 'jobwinner-logo-color.svg',
  [ProductPlatformOther.TopJobsAlpha]: 'jobs-ch.svg',
};

@Component({
  selector: 'mkp-product-platform-cell',
  standalone: true,
  imports: [NgIf, ProductTooltipComponent],
  templateUrl: './product-platform-cell.component.html',
  styleUrl: './product-platform-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPlatformCellComponent {
  readonly store = inject(Store);
  readonly logosUrl = 'assets/logos/';
  readonly language$ = this.store.select(selectInterfaceLanguage);

  @Input()
  set platform(platform: Features) {
    this.tooltipText = featureMapping[platform]?.description;
    this.feature = platform;
    this.isJobScout24 = platform === ProductPlatformGroup.JobScout24;
  }
  @Input() showTooltip = false;

  platformLogoMapping = platformLogoMapping;
  feature!: Features;
  tooltipText?: string;
  isJobScout24 = false;
}
