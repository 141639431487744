import { DictionaryNum } from '@ngrx/entity';
import { Currencies } from '@shared/enums';
import {
  Features,
  PRODUCT_FEATURES,
  ProductFeatures,
} from '../constants/feature-per-product.constant';
import { PRODUCT_TRANSLATIONS, ProductDescription } from '../constants/products.constants';
import { Price } from '../models/price.model';
import {
  CalendarFeature,
  PRODUCT_CALENDAR_PERIOD_MAP,
  PRODUCT_OFFERING_GROUP_MAP,
  PRODUCT_OFFERING_PLATFORMS,
  ProductOfferingPlatform,
  ProductPlatformGroup,
} from '../models/product-offering-feature.model';
import { Product, ProductDto, ProductFeature, Variant } from '../models/product.model';

interface PublicationCalendarBenefit {
  id: Features;
  translation: string;
  period: number; // number of days
}

const referenceQuantity = 1 as const;

export const isCalendarFeature = (feature: Features): feature is CalendarFeature =>
  PRODUCT_CALENDAR_PERIOD_MAP[feature as CalendarFeature] !== undefined;

export function isCalendarProductFeature(productFeature: ProductFeature): boolean {
  return isCalendarFeature(productFeature.id);
}

export const isPlatformFeature = (
  productFeature: ProductFeature
): productFeature is ProductFeature & {
  id: ProductOfferingPlatform;
} => PRODUCT_OFFERING_PLATFORMS.includes(productFeature.id as ProductOfferingPlatform);

export const getFirstVariant = (product: Pick<Product, 'variants' | 'code'>): Variant => {
  const variant =
    product.variants &&
    Object.values(product.variants).find(
      (variant: Variant) => variant.code === product.code + '-variant-0'
    );

  if (!product.variants || !variant) {
    throw new Error(`Variant not found for product code ${product.code}`);
  }

  return variant;
};
export const getNumberOfVariants = (product: Product | ProductDto): number =>
  product.variants && Object.values(product.variants).length;

/**
 * @desc !! it should not be used for displaying prices, use getReferencePrice instead
 * @param product
 */
export const getOriginalPrice = (product: Product | ProductDto | undefined): number => {
  const variant: Variant | undefined = product && getFirstVariant(product);
  return variant ? Number(variant.originalPrice.current) : 0;
};

export const getReferencePrice = (product: Product | ProductDto | undefined): number => {
  return getPriceForQuantity(product as ProductDto, referenceQuantity).current;
};

export const getCurrency = (product: ProductDto): Currencies =>
  getFirstVariant(product).originalPrice.currency as Currencies;

export const getPricesPerQuantity = (
  product: ProductDto
): {
  quantity: number;
  price: Price;
}[] =>
  Object.entries<Price>(getFirstVariant(product).prices).map(([quantity, price]) => ({
    quantity: Number(quantity),
    price,
  }));

export const getDistinctPricesPerQuantity = (product: ProductDto): DictionaryNum<Price> =>
  Object.values(
    getPricesPerQuantity(product).reduceRight(
      (acc, { quantity, price }) => ({
        ...acc,
        [price.current]: quantity,
      }),
      {} as Record<number, number>
    )
  ).reduceRight(
    (acc, quantity) => ({
      ...acc,
      [quantity]: getPriceForQuantity(product, quantity),
    }),
    {}
  );

export const getPriceForQuantity = (
  product: Pick<Product, 'variants' | 'code'>,
  quantity: number
) => {
  const variant = getFirstVariant(product);
  const selectedQuantity = Object.keys(variant.prices)
    .map(Number)
    .filter((value) => value <= quantity)
    .sort((a, b) => b - a)[0];
  return variant.prices[selectedQuantity];
};

export const getPercentDiscountForQuantity = (product: ProductDto, quantity: number) => {
  const price = getPriceForQuantity(product, quantity);
  const priceForOne = getPriceForQuantity(product, 1);

  return 100 - (price.current / priceForOne.current) * 100;
};

export const getProductDescription = ({ code }: ProductDto): ProductDescription => {
  return PRODUCT_TRANSLATIONS[code];
};

export const getPartialFeatures = ({ code }: ProductDto): Partial<ProductFeature>[] => {
  const productFeatures = PRODUCT_FEATURES.find(
    ({ productCode }: ProductFeatures) => productCode === code
  );

  if (!productFeatures) {
    throw new Error(`Product code ${code} not found in PRODUCT_FEATURES`);
  }

  return productFeatures.features.map(
    (feature: Features): ProductFeature => ({
      id: feature,
    })
  ) as Partial<ProductFeature>[];
};

export const getCalendarBenefit = (
  productFeatures: ProductFeature[]
): PublicationCalendarBenefit => {
  const { id, translation, title } = productFeatures.find(
    isCalendarProductFeature
  ) as ProductFeature;
  return {
    id,
    period: (PRODUCT_CALENDAR_PERIOD_MAP as Record<string, number>)[id],
    translation: (translation || title) as string,
  };
};

export const sortByPriceDesc = (p1: Product, p2: Product): number =>
  getOriginalPrice(p1) < getOriginalPrice(p2) ? 1 : -1;

export const isPaid = (product: Product): boolean => getOriginalPrice(product) > 0;
export const isFromPlatform = (product: Product, platform: ProductPlatformGroup): boolean =>
  PRODUCT_OFFERING_GROUP_MAP[platform].includes(product.code);

export const getPaidProductsOrderedByPrice =
  (platform: ProductPlatformGroup) =>
  (products: Product[] | null): Product[] | undefined =>
    products
      ?.filter((product) => isFromPlatform(product, platform) && isPaid(product))
      .sort(sortByPriceDesc);

export const getQuantityDiscount = (product: ProductDto, quantity: number): number => {
  const percent = getPercentDiscountForQuantity(product, quantity);
  return Math.round(percent);
};
