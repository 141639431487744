import { createAction, props } from '@ngrx/store';
import { Vacancy } from '@vacancy/models/vacancy.model';

export const ActionTypes = {
  GET_VACANCY: '[Vacancy] Get Vacancy',
  GET_VACANCY_WITH_ACCOUNT: '[Vacancy] Get Vacancy With Account',
  GET_VACANCY_SUCCEED: '[Vacancy] Get Vacancy Succeed',
  GET_VACANCY_FAILED: '[Vacancy] Get Vacancy Failed',

  CREATE_VACANCY_SUCCEED: '[Vacancy] Create Vacancy Succeed',
  CREATE_VACANCY_FAILED: '[Vacancy] Create Vacancy Failed',
};

/***** CREATE ****/

export const CreateVacancySuccess = createAction(
  ActionTypes.CREATE_VACANCY_SUCCEED,
  props<{ vacancy: Vacancy }>()
);
export const CreateVacancyFailure = createAction(
  ActionTypes.CREATE_VACANCY_FAILED,
  props<{ error: { message: string } }>()
);

/***** GET SINGLE  ****/
export const GetVacancy = createAction(ActionTypes.GET_VACANCY, props<{ vacancyId: string }>());
export const GetVacancySuccess = createAction(
  ActionTypes.GET_VACANCY_SUCCEED,
  props<{ vacancy: Vacancy }>()
);
export const GetVacancyFailure = createAction(
  ActionTypes.GET_VACANCY_FAILED,
  props<{ vacancyId: string; error: { message: string } }>()
);
