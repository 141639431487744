import { Cart } from '@app/features/cart/models/cart.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Vacancy } from '@vacancy/models/vacancy.model';

export const NotificationGatewayActions = createActionGroup({
  source: 'Notification Gateway',
  events: {
    'Unverified Checkout Success': props<{
      vacancy: Vacancy;
      cart: Cart;
    }>(),
    'Unverified Checkout Failure': emptyProps(),
    'Salary Partnership Success': emptyProps(),
    'Salary Partnership Failure': emptyProps(),
  },
});
