import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as versionEffects from './version.effects';
import { versionFeature } from './version.reducer';

const shouldUseServiceWorker = () => environment.name !== 'dev';

@NgModule({
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: shouldUseServiceWorker(),
    }),
    StoreModule.forFeature(versionFeature),
    EffectsModule.forFeature(shouldUseServiceWorker() ? [versionEffects] : []),
  ],
})
export class VersionStateModule {}
