import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { filter, map, take } from 'rxjs';

export const boUserGuardFactory = (redirection: string) => {
  return () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(selectIsBoUser).pipe(
      filter((isBoUser) => isBoUser !== null),
      take(1),
      map((isBoUser) => isBoUser || router.parseUrl(redirection))
    );
  };
};
