import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EntityFeatureState, getInitialEntityFeatureState } from './entity-feature-state';
import { createEntityFeatureUpdaters, EntityFeatureUpdaters } from './entity-feature-updaters';
import { createEntityFeatureSelectors, EntityFeatureSelectors } from './entity-feature-selectors';

type ExtractEntity<FeatureState> =
  FeatureState extends EntityFeatureState<infer Entity, unknown> ? Entity : never;
type ExtractError<FeatureState> =
  FeatureState extends EntityFeatureState<unknown, infer Error> ? Error : never;

export interface EntityFeature<
  AppState extends object,
  FeatureKey extends string,
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error,
> {
  featureKey: FeatureKey;
  initialState: FeatureState;
  adapter: EntityAdapter<Entity>;
  updaters: EntityFeatureUpdaters<FeatureState, Entity, Error>;
  selectors: EntityFeatureSelectors<AppState, FeatureState, Entity, Error>;
}

export function createEntityFeature<
  Entity extends { id: string },
  FeatureKey extends string,
  Error = Record<string, unknown>,
>(
  featureKey: FeatureKey
): EntityFeature<object, FeatureKey, EntityFeatureState<Entity, Error>, Entity, Error>;

export function createEntityFeature<
  FeatureState extends EntityFeatureState<Entity, Error>,
  FeatureKey extends string,
  AppState extends object = object,
  Entity extends { id: string } = ExtractEntity<FeatureState>,
  Error = ExtractError<FeatureState>,
>(
  featureKey: FeatureKey,
  additionalState?: Omit<FeatureState, keyof EntityFeatureState<Entity, Error>>
): EntityFeature<AppState, FeatureKey, FeatureState, Entity, Error>;

export function createEntityFeature<
  AppState extends object,
  FeatureKey extends string,
  FeatureState extends EntityFeatureState<Entity, Error>,
  Entity extends { id: string },
  Error,
>(
  featureKey: FeatureKey,
  additionalState?: Omit<FeatureState, keyof EntityFeatureState<Entity, Error>>
): EntityFeature<AppState, FeatureKey, FeatureState, Entity, Error> {
  const adapter = createEntityAdapter<Entity>();
  const initialState: FeatureState = {
    ...getInitialEntityFeatureState(adapter),
    ...additionalState,
  } as FeatureState;

  return {
    featureKey,
    initialState,
    adapter,
    updaters: createEntityFeatureUpdaters<FeatureState, Entity, Error>(initialState, adapter),
    selectors: createEntityFeatureSelectors<AppState, FeatureState, Entity, Error>(
      featureKey,
      adapter
    ),
  };
}
