import {
  FILTER_COMBINE,
  FILTER_OPERATORS,
} from '../services/pagination/constants/filter-operators.constants';

export const HIGH_LIMIT = 400;

export interface QueryOptions {
  offset?: number;
  rows?: number;
  term?: string;
  limit?: number;
  sort?: string;
  filter?: string;
  maxRetry?: number;
  propagateError?: boolean;
}

const filterRegex = (filterEnum: typeof FILTER_COMBINE | typeof FILTER_OPERATORS): string =>
  `/${Object.values(filterEnum)
    .map((filter) => `(${filter})`)
    .join('|')}/`;

// these options should not be sent through the queryString
const internalOptions: (keyof QueryOptions)[] = ['maxRetry', 'propagateError'];

export const toQueryString = (queryOptions: QueryOptions): string =>
  Object.entries(queryOptions)
    .filter(([, value]) => value != null)
    .filter(([key]) => !internalOptions.includes(key as keyof QueryOptions))
    .map(([key, value]) =>
      key === 'filter'
        ? `${key}=${encodeFilterValue(value)}`
        : `${key}=${encodeURIComponent(value)}`
    )
    .join('&');

const encodeFilterElement = (filterValue: string): string => {
  return filterValue === '\\0' ? '\\0' : encodeURIComponent(filterValue);
};
// We need to split the url in order to get the parts we need encode/not encode
const encodeFilterValue = (filter: string): string => {
  const stringConditions = filter.split(filterRegex(FILTER_COMBINE)).filter(Boolean) as [
    string,
    string,
    string,
  ];

  // filtering out the separators based on filter operators enum
  const conditions = stringConditions.map((stringCondition) =>
    stringCondition.split(filterRegex(FILTER_OPERATORS)).filter(Boolean)
  ) as [[string, string, string] | [string]];

  // deducing which of the filtered to be encoded using encodeFilterElement method
  const encodedConditions = conditions.map((condition) =>
    condition.length === 1 ? condition.map(encodeURIComponent) : condition.map(encodeFilterElement)
  ) as [[string, string, string] | [string]];

  return encodedConditions.map((encodedCondition) => encodedCondition.join('')).join('');
};
