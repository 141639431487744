import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';
import { InputWrapperModule } from '../input-wrapper/input-wrapper.module';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [
    InputWrapperModule,
    CommonModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    DataTestDirective,
  ],
  exports: [SelectComponent],
  declarations: [SelectComponent],
})
export class SelectModule {}
