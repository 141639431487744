import { Event, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';

export const isNavigationEnd = (event: Event): event is NavigationEnd =>
  event instanceof NavigationEnd;

export const isNavigationStart = (event: Event): event is NavigationStart =>
  event instanceof NavigationStart;

export const isNavigationError = (event: Event): event is NavigationError =>
  event instanceof NavigationError;
