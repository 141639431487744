import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpOptions } from '@apollo/client/core';
import { Serializer } from '@shared/interfaces/serializer.interface';
import { ApiListEnvelop, getEmptyApiListEnvelop } from '@shared/models/api-list-envelop.model';
import { QueryOptions, toQueryString } from '@shared/models/query-options.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * @deprecated
 * Use BaseHttpResource (libs/shared/data-access) instead
 */
export class ResourceService<T> {
  constructor(
    public httpClient: HttpClient,
    public url: string,
    public endpoint: string,
    public serializer?: Serializer
  ) {}

  public create(item: T): Observable<T> {
    return this.httpClient
      .post<T>(
        `${this.url}/${this.endpoint}`,
        this.serializer ? this.serializer.toJson(item as T) : (item as T)
      )
      .pipe(map((data) => (this.serializer ? (this.serializer.fromJson(data) as T) : (data as T))));
  }

  // FIXME not working on change status. Request no reading JSON
  public createRead(id: string, item: unknown): Observable<T> {
    return this.httpClient.post<T[]>(`${this.url}/${this.endpoint}/${id}`, new Object(item)).pipe(
      map(([data]: unknown[]) => {
        // [FIXME TO UPDATE ON BULK ACTIONS] need to be an array of ids
        return this.serializer ? (this.serializer.fromJson(data) as T) : (data as T);
      })
    );
  }

  public createList(item: T): Observable<T> {
    // TODO serialize this
    return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, item).pipe(map((data) => data));
  }

  public updatePutMethod(item: Partial<T> & { id: string }): Observable<T> {
    return this.httpClient
      .put<T>(
        `${this.url}/${this.endpoint}/${item.id}`,
        this.serializer ? this.serializer.toJson(item) : (item as Partial<T>)
      )
      .pipe(map((data) => (this.serializer ? (this.serializer.fromJson(data) as T) : (data as T))));
  }

  public patchValue<T>(item: Record<string, unknown>, queryOptions?: QueryOptions): Observable<T> {
    const params = queryOptions ? `?${toQueryString(queryOptions)}` : '';
    const options = this.getOptions(queryOptions);

    const url = `${this.url}/${this.endpoint}/${String(item['id'])}${params}`;
    return this.httpClient
      .patch<T>(url, item, options)
      .pipe(map((data) => (this.serializer ? (this.serializer.fromJson(data) as T) : (data as T))));
  }

  public read(id: string): Observable<T> {
    return this.httpClient
      .get(`${this.url}/${this.endpoint}/${id}`)
      .pipe(map((data) => (this.serializer ? (this.serializer.fromJson(data) as T) : (data as T))));
  }

  public readArray(id: string): Observable<T> {
    // TODO serialize this
    return this.httpClient
      .get<T>(`${this.url}/${this.endpoint}/${id}`)
      .pipe(map((data: T) => data));
  }

  public uniq(): Observable<T> {
    return this.httpClient
      .get(`${this.url}/${this.endpoint}`)
      .pipe(map((data) => (this.serializer ? (this.serializer.fromJson(data) as T) : (data as T))));
  }

  public list(queryOptions?: QueryOptions): Observable<ApiListEnvelop<T>> {
    const params = queryOptions ? `?${toQueryString(queryOptions)}` : '';
    const options = this.getOptions(queryOptions);

    return this.httpClient
      .get<ApiListEnvelop<T>>(`${this.url}/${this.endpoint}${params}`, options)
      .pipe(
        catchError((error: unknown) =>
          queryOptions?.propagateError ? throwError(() => error) : of(getEmptyApiListEnvelop<T>())
        )
      );
  }

  public listProxy(queryOptions?: QueryOptions): Observable<T[]> {
    const params = queryOptions ? `?${toQueryString(queryOptions)}` : '';
    const options = this.getOptions(queryOptions);

    return this.httpClient
      .get<T[]>(`${this.url}/${this.endpoint}${params}`, options)
      .pipe(
        catchError((error: unknown) =>
          queryOptions?.propagateError ? throwError(() => error) : of([])
        )
      );
  }

  public delete(id: string) {
    return this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`);
  }

  private getOptions(queryOptions?: QueryOptions): HttpOptions {
    // the maxRetry header will be intercepted and removed before reaching the API
    return queryOptions?.maxRetry != null
      ? ({
          headers: new HttpHeaders({ maxRetry: `${queryOptions.maxRetry}` }),
        } as unknown as HttpOptions)
      : {};
  }
}
