import { LegalEntityUpdatePayload } from '@mkp/legal-entity/data-access';
import { createActionGroup, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models';

export const claimManagementActions = createActionGroup({
  source: 'Claim Management Page',
  events: {
    Load: props<{ query: QueryOptions }>(),
    'Load More': props<{ query: QueryOptions }>(),
    'Select Legal Entity': props<{ legalEntityId: string }>(),
    'Load Account From Selected Legal Entity': props<{ legalEntityId: string }>(),
    'Update Legal Entity Status': props<LegalEntityUpdatePayload>(),
  },
});
