<div
  class="card"
  [class.best-value-card-background]="isBestValue"
  [class]="isBestValue ? activePlatformGroup + '-border' : ''"
>
  <section class="card-details">
    <span class="product-title title-xs">{{ product.name | translate }}</span>
    <mkp-product-calendar-label
      *ngIf="calendarFeature"
      [productCode]="product.code"
      [title]="calendarFeature.translation"
    />
  </section>
  <span class="product-price title-xs">
    <span class="nobold">{{ product.price.currency }} </span>
    {{ product.price.current / 100 | currency: ' ' : 'code' : '1.0-0' : 'fr-CH' }}
  </span>
  <ui-button
    *ngIf="!isReadMode"
    class="select-btn"
    [loading]="isLoading"
    [config]="selectConfig"
    (click)="handleSelectedProduct.emit(product.code)"
    mkpDataTest="select-cta-sticky-header-btn"
  >
    {{ 'PRODUCT_OFFERING.SELECT_PLAN' | translate }}
  </ui-button>
</div>
