import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectGdprFunctional, selectGdprIsLoaded } from '@mkp/gdpr/state';
import { gdprModalActions } from '@mkp/gdpr/state/actions';
import { GdprModalService } from '@mkp/gdpr/ui';
import { LanguageIso } from '@mkp/shared/data-access';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { WindowWithZendesk, ZenDeskActions, ZenDeskCommands } from './zendesk.model';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';

/**
 * Service to interact with Zendesk widget.
 * @desc https://jobcloud.atlassian.net/wiki/spaces/CORE/pages/84849623279/Zendesk+Implementation
 */
@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  private readonly window = inject(Window) as WindowWithZendesk;
  private readonly store = inject(Store);
  private readonly destroy = inject(DestroyRef);

  private readonly gdprModalService = inject(GdprModalService);
  private readonly isInitialized$ = new BehaviorSubject<boolean>(false);
  private isInitializing = false;

  constructor() {
    this.listenToConsentChange();
  }

  private listenToConsentChange() {
    this.store
      .select(selectGdprIsLoaded)
      .pipe(
        filter(Boolean),
        switchMap(() =>
          combineLatest([
            this.store.select(selectGdprFunctional),
            this.store.select(selectIsBoUser),
          ]).pipe(
            takeUntilDestroyed(this.destroy),
            tap(([functional, isBoUser]) => {
              if (!isBoUser && functional) {
                this.initializeZendeskIfNeeded$()
                  .pipe(tap(() => this.enableCookies()))
                  .subscribe();
              } else {
                this.disableCookies();
              }
            })
          )
        )
      )
      .subscribe();
  }

  private initializeZendeskIfNeeded$(): Observable<boolean> {
    return this.isInitialized$.pipe(
      take(1),
      switchMap((isInitialized) => {
        if (isInitialized) return of(true);
        if (this.isInitializing) return this.isInitialized$.pipe(filter(Boolean), take(1));

        this.isInitializing = true;
        return this.initializeZenDesk();
      })
    );
  }

  private initializeZenDesk() {
    return from(
      new Promise<boolean>((resolve) => {
        if (this.window.zE) {
          this.setInitialized(resolve, true);
        } else if (typeof this.window.initZendesk === 'function') {
          this.window.initZendesk();
          const checkZendesk = setInterval(() => {
            if (this.window.zE) {
              clearInterval(checkZendesk);
              this.setInitialized(resolve, true);
            }
          }, 100);
        } else {
          console.error('Zendesk initialization function not found');
          this.setInitialized(resolve, false);
        }
      })
    ).pipe(
      catchError((error) => {
        console.error('Failed to initialize Zendesk:', error);
        this.isInitializing = false;
        return of(false);
      })
    );
  }

  private setInitialized(resolve: (value: boolean) => void, value: boolean) {
    this.isInitialized$.next(value);
    this.isInitializing = false;
    resolve(value);
  }

  openWidget(): void {
    const openWidget = () => this.getZendesk()(ZenDeskActions.Messenger, ZenDeskCommands.Open);

    this.store
      .select(selectGdprFunctional)
      .pipe(
        take(1),
        switchMap((functional) =>
          functional
            ? this.initializeZendeskIfNeeded$().pipe(tap(openWidget))
            : this.gdprModalService
                .openGdprFunctionalDialog(gdprModalActions.setGdprFromZendeskPlaceholder)
                .pipe(
                  switchMap((result) =>
                    result?.functional
                      ? this.store.select(selectGdprFunctional).pipe(
                          filter(Boolean),
                          take(1),
                          switchMap(() => this.initializeZendeskIfNeeded$()),
                          tap(openWidget)
                        )
                      : of(false)
                  )
                )
        )
      )
      .subscribe();
  }

  private getZendesk() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.window.zE || (() => {});
  }

  private enableCookies() {
    this.getZendesk()(ZenDeskActions.Set, ZenDeskCommands.Cookies, true);
  }

  private disableCookies() {
    this.getZendesk()(ZenDeskActions.Set, ZenDeskCommands.Cookies, false);
  }

  closeWidget() {
    this.getZendesk()(ZenDeskActions.Messenger, ZenDeskCommands.Close);
  }

  // see locales https://support.zendesk.com/api/v2/locales/public.json
  setLocale(locale: LanguageIso) {
    this.getZendesk()(ZenDeskActions.Set, ZenDeskCommands.Locale, locale.toLowerCase());
  }

  login(zendeskJWT: string) {
    this.getZendesk()(
      ZenDeskActions.Messenger,
      ZenDeskCommands.LoginUser,
      function (callback: CallableFunction) {
        callback(zendeskJWT);
      }
    );
  }
}
