import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractProductDisplayService,
  ProductDisplayReadOnlyService,
} from '@mkp/product/feature-product-display';

@Component({
  selector: 'mkp-products',
  templateUrl: 'products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractProductDisplayService,
      useClass: ProductDisplayReadOnlyService,
    },
  ],
})
export class ProductsComponent {
  @Input() showPlatformSwitch = false;
  @Input() showStickyHeader = false;
}
