import { EntityAdapter, EntityState } from '@ngrx/entity';
import { ApiLink } from '@mkp/shared/data-access';
import { IGraphQLPagination } from '@shared/modules/graphql/interfaces/graphql-pagination.interface';
import { FetchState, LoadingState } from '../fetch-state';

export interface EntityFeatureState<Entity, Error> extends EntityState<Entity> {
  readonly ids: string[];
  readonly selectedId: string | null;
  readonly fetchState: FetchState<Error>;
  readonly totalCount: number | null;
  readonly _links: Record<string, ApiLink> | null;
  readonly filter: string | null;
  readonly gqlPagination: IGraphQLPagination | null;
}

export function getInitialEntityFeatureState<Entity, Error>(
  adapter: EntityAdapter<Entity>
): EntityFeatureState<Entity, Error> {
  return adapter.getInitialState({
    ids: [],
    selectedId: null,
    fetchState: LoadingState.INIT,
    totalCount: null,
    _links: null,
    filter: null,
    gqlPagination: null,
  });
}
