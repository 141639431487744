import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { MessageError, isHttpStatusError, isMessageError } from '@core/models';
import { ActionState, SnackbarService } from '@mkp/shared/ui-library';
import { UnsubscribePageActions } from '@mkp/user/feature-unsubscribe/actions';
import { vacancyEditActions } from '@mkp/vacancy/feature-vacancy-edit/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectRouteParam } from '@store/selectors';
import { UserApi } from '@shared/core-api/apis';
import { QueryOptions } from '@shared/models';
import { languageEffectsActions } from '@store/actions';
import { userApiActions, userDetailsActions, userListActions } from '@user/store/actions';
import { selectLoggedInUser, selectUserById } from '@user/store/selectors/user.selectors';
import { authApiActions } from '@mkp/auth/actions';
import {
  OperatorFunction,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class UserEffects {
  readonly fetchLoggedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authApiActions.fetchAccessTokenSuccess),
      switchMap(({ actorId }) =>
        this.userApi.readAndMap(actorId).pipe(
          map((user) => userApiActions.readLoggedInUserSuccess({ user })),
          catchError(
            (error: unknown) =>
              isUserError(error) && of(userApiActions.readLoggedInUserFailure({ error }))
          )
        )
      )
    )
  );

  readonly listUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userListActions.init),
      this.fetchUsers(userApiActions.listUsersSuccess, userApiActions.listUsersFailure)
    )
  );

  readonly initUserDetailsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userDetailsActions.initUserDetailsPage),
      concatLatestFrom(() => this.store.select(selectRouteParam('id'))),
      map(([, id]) => id),
      filter(Boolean),
      concatLatestFrom((id) => this.store.select(selectUserById(id))),
      filter(([, user]) => !user),
      map(([id]) => ({ query: { filter: `id==${id}` } })),
      this.fetchUsers(userApiActions.listUsersSuccess, userApiActions.listUsersFailure)
    )
  );

  readonly loadMoreUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userListActions.loadMore),
      this.fetchUsers(userApiActions.loadMoreUsersSuccess, userApiActions.loadMoreUsersFailure)
    )
  );

  readonly updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userDetailsActions.updateUser,
        userDetailsActions.updateUserProfile,
        languageEffectsActions.appLanguageSwitchedForUser
      ),
      switchMap(({ updateUser, notification, redirectUrl }) => {
        return this.userApi.patch(updateUser).pipe(
          tap(() => {
            if (notification) {
              this.notificationService.show(notification);
            }
          }),
          map((user) => userApiActions.updateUserSuccess({ id: user.id, changes: user })),
          tap(() => {
            if (redirectUrl) {
              this.router.navigate([redirectUrl]);
            }
          }),
          catchError((error: unknown) => {
            return isUserError(error) && of(userApiActions.updateUserFailure({ error }));
          })
        );
      })
    )
  );

  readonly resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userDetailsActions.resetPassword),
        switchMap(({ email, notification }) => {
          return this.userApi.resetPassword(email).pipe(
            tap(() => this.notificationService.show(notification)),
            map(() => userApiActions.resetPasswordSuccess()),
            catchError((error: unknown) => {
              this.notificationService.show('USER_ACCOUNT.USER.ERROR.RESET_PASSWORD', {
                state: ActionState.Error,
              });
              return isUserError(error) && of(userApiActions.resetPasswordFailure({ error }));
            })
          );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  readonly unsubscribe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnsubscribePageActions.unsubscribeLoggedInUser),
        switchMap(() => this.store.select(selectLoggedInUser).pipe(filter(Boolean), take(1))),
        switchMap((user) => {
          return this.userApi
            .patch(
              { id: user.id, settings: { marketingConsent: false }, _version: user._version },
              1
            )
            .pipe(
              tap(() => this.notificationService.show('UNSUBSCRIBE.SUCCESS.NOTIFICATION')),
              map((user) => userApiActions.updateUserSuccess({ id: user.id, changes: user })),
              catchError((error: unknown) => {
                if (isHttpStatusError(error) && error.status === 304) {
                  // 304 is not an error in this case, it just means the user was already unsubscribed
                  return of(userApiActions.updateUserSuccess({ id: user.id, changes: user }));
                }

                this.notificationService.show('UNSUBSCRIBE.FAILURE.NOTIFICATION', {
                  state: ActionState.Error,
                });
                return isUserError(error) && of(userApiActions.updateUserFailure({ error }));
              })
            );
        })
      ),
    { useEffectsErrorHandler: false }
  );

  updateChatGptContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vacancyEditActions.updatePublicationAiUsageConsent),
      withLatestFrom(this.store.select(selectLoggedInUser)),
      filter(([, user]) => !!user),
      switchMap(([{ consent }, user]) =>
        this.userApi
          .patch({
            id: (user as User).id,
            _version: (user as User)._version,
            settings: {
              ...user.settings,
              publicationAiUsageConsent: consent,
            },
          })
          .pipe(
            map((user) => userApiActions.updateUserSuccess({ id: user.id, changes: user })),
            catchError((error: unknown) => {
              return isUserError(error) && of(userApiActions.updateUserFailure({ error }));
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userApi: UserApi,
    private notificationService: SnackbarService,
    private router: Router,
    private store: Store
  ) {}

  private fetchUsers(
    successAction: FetchUsersSuccessActionCreator,
    failureAction: FetchUsersFailureActionCreator
  ): OperatorFunction<{ query: QueryOptions }, FetchUsersAction> {
    return switchMap(({ query }) => {
      const options = query || { limit: 25 };
      return this.userApi.listAndMap(options).pipe(
        map(({ _embedded, _links, totalCount, filter }) =>
          successAction({
            users: _embedded.results,
            _links,
            totalCount,
            filter,
          })
        ),
        catchError((error: unknown) => isUserError(error) && of(failureAction({ error })))
      );
    });
  }
}

type FetchUsersSuccessActionCreator =
  | typeof userApiActions.listUsersSuccess
  | typeof userApiActions.loadMoreUsersSuccess;
type FetchUsersFailureActionCreator =
  | typeof userApiActions.listUsersFailure
  | typeof userApiActions.loadMoreUsersFailure;
type FetchUsersAction = ReturnType<FetchUsersSuccessActionCreator | FetchUsersFailureActionCreator>;

const isUserError = (error: unknown): error is MessageError => isMessageError(error, 'UserEffects');
