import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { BenefitService } from '@shared/services/benefits/benefits.service';
import { ApiListEnvelop, Benefit, HIGH_LIMIT } from '@shared/models';
import { ActionTypes } from '@store/actions/benefits.actions';

import * as actions from '@store/actions/benefits.actions';

@Injectable()
export class BenefitsEffects {
  getBenefitsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.GET_BENEFITS_LIST),
        switchMap(() =>
          this.service.list({ offset: 0, limit: HIGH_LIMIT }).pipe(
            map((response: ApiListEnvelop<Benefit>) => {
              const { results } = response._embedded;
              const benefits = results.map(({ id, name }) => ({ id, name }));
              return new actions.GetBenefitsListSuccess(benefits as Benefit[]);
            }),
            catchError((error: unknown) =>
              of(new actions.GetBenefitsListFailure({ payload: error }))
            )
          )
        )
      ),
    { useEffectsErrorHandler: false }
  );

  constructor(
    private actions$: Actions,
    private service: BenefitService
  ) {}
}
