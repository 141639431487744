import { Pipe, PipeTransform } from '@angular/core';
import { ProductCode, getPerformanceKey } from '@mkp/shared/util-product';
@Pipe({
  name: 'performanceReachPipe',
  pure: true,
  standalone: true,
})
export class PerformanceReachPipe implements PipeTransform {
  transform(code: ProductCode): string {
    return getPerformanceKey(code);
  }
}
