import { createFetchStateSelectors, FetchState, LoadingState } from '@mkp/shared/util-state';
import { Benefit } from '@shared/models/benefit.model';
import * as formBenefitsActions from '@store/actions/benefits.actions';

export const featureKey = 'benefitsState';

/***** actions  ****/

/**** Models ****/

export interface BenefitsState {
  benefitsList: { [k: string]: Benefit } | null;
  fetchState: FetchState;
}

/***** initial state  ****/
export const initialState: BenefitsState = {
  benefitsList: null,
  fetchState: LoadingState.INIT,
};

/***** methods  ****/
const GetBenefitsList = (state: BenefitsState) => {
  return {
    ...state,
    fetchState: LoadingState.LOADING,
  };
};

const GetBenefitsListSuccess = (
  state: BenefitsState,
  action: formBenefitsActions.GetBenefitsListSuccess
) => {
  /**use entity pattern ***/
  const { payload } = action;
  const benefits = payload.reduce((benefits: { [k: string]: Benefit }, benefit: Benefit) => {
    return {
      ...benefits,
      [benefit.id]: benefit,
    };
  }, {});

  // Update profile
  return {
    ...state,
    fetchState: LoadingState.LOADED,
    benefitsList: benefits,
  };
};

const GetBenefitsFailure = (
  state: BenefitsState,
  action: formBenefitsActions.GetBenefitsListFailure
) => {
  return {
    ...state,
    fetchState: { error: action.payload },
  };
};

/***** reducer  ****/
export function BenefitsReducer(
  state = initialState,
  action: formBenefitsActions.Actions
): BenefitsState {
  switch (action.type) {
    case formBenefitsActions.ActionTypes.GET_BENEFITS_LIST: {
      return GetBenefitsList(state);
    }
    case formBenefitsActions.ActionTypes.GET_BENEFITS_LIST_FAILED: {
      return GetBenefitsFailure(state, action);
    }
    case formBenefitsActions.ActionTypes.GET_BENEFITS_LIST_SUCCEED: {
      return GetBenefitsListSuccess(state, action);
    }

    default:
      return state;
  }
}

export const { selectLoading, selectLoaded } = createFetchStateSelectors<BenefitsState>();

export const getBenefitsEntities = (state: BenefitsState) =>
  selectLoaded(state) ? state.benefitsList : null;
export const getBenefitsLoading = (state: BenefitsState) =>
  selectLoaded(state) ? selectLoading(state) : selectLoading(initialState);
export const getBenefitsLoaded = (state: BenefitsState) =>
  selectLoaded(state) ? selectLoaded(state) : selectLoaded(initialState);
