import { createAction, props } from '@ngrx/store';
import { CreateUser, UpdateUser } from '@user/shared/models';

export const updateUserProfile = createAction(
  `[User Details] Update User Profile`,
  props<{ updateUser: UpdateUser; notification: string | false; redirectUrl?: string }>()
);

export const updateUser = createAction(
  `[User Details] Update User`,
  props<{ updateUser: UpdateUser; notification: string | false; redirectUrl?: string }>()
);

export const createUser = createAction(
  `[User Details] Create User`,
  props<{ createUser: CreateUser; notification: string | false; redirectUrl?: string }>()
);

export const resetPassword = createAction(
  `[User Details] Reset Password`,
  props<{ email: string; notification: string }>()
);

export const initUserDetailsPage = createAction(`[User Details] Init User Details Page`);
