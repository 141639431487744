import { NgModule } from '@angular/core';
import { CartModule } from '@cart/cart.module';
import { PublicationFeatureCreditStatusModule } from '@mkp/publication/feature-credit-status';
import { VacancyStateModule } from '../vacancy/store';
import { ProductOptionsStateModule } from './store/product-options-state.module';

@NgModule({
  imports: [
    PublicationFeatureCreditStatusModule,
    CartModule,
    ProductOptionsStateModule,
    VacancyStateModule,
  ],
})
export class ProductOptionsModule {}
