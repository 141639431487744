import { CommonModule, registerLocaleData } from '@angular/common';
import frCH from '@angular/common/locales/fr-CH';
import { NgModule } from '@angular/core';
import { ProductsComponent } from '@features/products/components';
import { ProductsRoutingModule } from '@features/products/products-routing.module';
import { ProductComparisonTableComponent } from '@mkp/product/feature-product-comparison-table';
import {
  ProductDisplayButtonSwitchOfferingsComponent,
  ProductDisplayCardListComponent,
  ProductDisplayReadOnlyComponent,
} from '@mkp/product/feature-product-display';
import { ProductTableComponent } from '@mkp/product/feature-product-table';
import { AssetComponent } from '@mkp/shared/ui-asset';
import { ButtonComponent } from '@mkp/shared/ui-library';
import { ProductOptionsModule } from '@product-options/product-options.module';

registerLocaleData(frCH, 'fr-CH');

@NgModule({
  imports: [
    CommonModule,
    AssetComponent,
    ButtonComponent,
    ProductOptionsModule,
    ProductsRoutingModule,
    AssetComponent,
    ButtonComponent,
    ProductComparisonTableComponent,
    ProductDisplayButtonSwitchOfferingsComponent,
    ProductDisplayCardListComponent,
    ProductDisplayButtonSwitchOfferingsComponent,
    ProductTableComponent,
    ProductDisplayReadOnlyComponent,
  ],
  declarations: [ProductsComponent],
  exports: [ProductsComponent],
})
export class ProductsModule {}
