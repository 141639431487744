import { Vacancy } from '@vacancy/models/vacancy.model';

export class VacancySerializer {
  static fromJson(
    json: Vacancy & {
      videos: { url: string }[]; // todo: remove this when we have a correct VacancyDto
    }
  ): Vacancy {
    return {
      _embedded: json._embedded,
      _version: json._version,
      accountId: json.accountId,
      address: json.address,
      applicationCount: json.applicationCount,
      benefits: json.benefits,
      companyName: json.companyName,
      createdAt: json.createdAt,
      createdBy: json.createdBy,
      description: json.description,
      employmentGradeFrom: json.employmentGradeFrom,
      employmentGradeTo: json.employmentGradeTo,
      experience: json.experience,
      externalSource: json.externalSource,
      headerMediaApiId: json.headerMediaApiId,
      id: json.id,
      jobPosition: json.jobPosition,
      languageRequirements: json.languageRequirements,
      locationId: json.locationId,
      logoMediaApiId: json.logoMediaApiId,
      videoUrl: json.videos?.[0]?.url ?? '',
      metadataApiEducationLevelId: json.metadataApiEducationLevelId,
      metadataApiEmploymentPositionId: json.metadataApiEmploymentPositionId,
      metadataApiEmploymentTypeId: json.metadataApiEmploymentTypeId,
      newApplicant: json.newApplicant,
      salaryFrom: json.salaryFrom,
      salaryTo: json.salaryTo,
      salaryUnit: json.salaryUnit,
      showSalary: json.showSalary,
      skillRequirements: json.skillRequirements,
      updatedAt: json.updatedAt,
      updatedBy: json.updatedBy,
    };
  }

  static toJson(vacancy: Vacancy): { [key: string]: unknown } {
    return {
      _embedded: vacancy._embedded,
      _version: vacancy._version,
      accountId: vacancy.accountId,
      address: vacancy.address,
      benefits: vacancy.benefits,
      description: vacancy.description,
      employmentGradeFrom: vacancy.employmentGradeFrom,
      employmentGradeTo: vacancy.employmentGradeTo,
      experience: vacancy.experience,
      headerMediaApiId: vacancy.headerMediaApiId,
      id: vacancy.id,
      jobPosition: vacancy.jobPosition,
      languageRequirements: vacancy.languageRequirements,
      locationId: vacancy.locationId,
      logoMediaApiId: vacancy.logoMediaApiId,
      videos: vacancy.videoUrl ? [{ url: vacancy.videoUrl }] : null,
      metadataApiEducationLevelId: vacancy.metadataApiEducationLevelId,
      metadataApiEmploymentPositionId: vacancy.metadataApiEmploymentPositionId,
      metadataApiEmploymentTypeId: vacancy.metadataApiEmploymentTypeId,
      salaryFrom: vacancy.salaryFrom,
      salaryTo: vacancy.salaryTo,
      salaryUnit: vacancy.salaryUnit,
      showSalary: vacancy.showSalary,
      skillRequirements: vacancy.skillRequirements,
    };
  }
}
