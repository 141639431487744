import { FILTER_COMBINE } from './filter-operators.constants';

export const paginationConfig = {
  isCumulative: false,
  initialLimit: 10,
  limit: 10,
  cursor: 0,
  filterSeparator: FILTER_COMBINE.And,
  sortSeparator: FILTER_COMBINE.And,
};
