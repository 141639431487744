import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LabelClass, LabelConfig, createClass } from './label.config';

@Component({
  selector: 'ui-label',
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LabelComponent implements OnChanges {
  @Input({ required: true }) config!: LabelConfig;
  @HostBinding('class') className!: LabelClass;

  ngOnChanges({ config }: SimpleChanges): void {
    if (config) {
      this.className = createClass(this.config.color);
    }
  }
}
