import { inject, Injectable } from '@angular/core';
import { CompanyResource } from '@mkp/company/data-access';
import { CompanyApiActions } from '@mkp/company/state/actions';
import { FeatureDashboardActions } from '@mkp/user/feature-dashboard/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, exhaustMap, filter, map, of, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCompanies, selectSelectedCompanyId } from '@company/store/selectors';
import { CompanyProfile } from '@company/models';
import { legalEntityDetailsActions } from '@mkp/legal-entity/actions';

@Injectable()
export class CompanyEffects {
  private readonly actions$ = inject(Actions);
  private readonly companyResource = inject(CompanyResource);
  private readonly store = inject(Store);

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureDashboardActions.enter),
      switchMap(() =>
        combineLatest([
          this.store.select(selectCompanies).pipe(filter((companies) => companies.length > 0)),
          this.store.select(selectSelectedCompanyId).pipe(filter(Boolean)),
        ]).pipe(take(1))
      ),
      exhaustMap(([companies, selectedId]) =>
        this.companyResource.getWithQuery(getCompanyIdsFilter(companies)).pipe(
          map(({ _embedded }) => _embedded.results),
          map((companies) => CompanyApiActions.loadCompaniesSuccess({ companies, selectedId })),
          catchError((error) => of(CompanyApiActions.loadCompaniesFailed({ error })))
        )
      )
    )
  );

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyApiActions.loadCompanies),
      exhaustMap(({ query }) =>
        this.companyResource.getWithQuery(query).pipe(
          map(({ _embedded }) => _embedded.results),
          map((companies) => CompanyApiActions.loadCompaniesSuccess({ companies })),
          catchError((error) => of(CompanyApiActions.loadCompaniesFailed({ error })))
        )
      )
    )
  );

  loadCompaniesOnDetailsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(legalEntityDetailsActions.pageEntered),
      exhaustMap(({ legalEntityId }) =>
        this.companyResource.getWithQuery({ filter: `legalEntity.id==${legalEntityId}` }).pipe(
          map(({ _embedded }) => _embedded.results),
          map((companies) => legalEntityDetailsActions.companiesLoadSuccess({ companies })),
          catchError((error) => of(legalEntityDetailsActions.companiesLoadFailure({ error })))
        )
      )
    )
  );
}

function getCompanyIdsFilter(companies: CompanyProfile[]) {
  return { filter: companies.map(({ id }) => `id==${id}`).join(',') };
}
