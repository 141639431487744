import { createAction, props } from '@ngrx/store';
import { ProductSummary } from '@features/product-summary/models/product-summary.model';
import { CartCheckout } from '@cart/models/cart-checkout.model';
import { Cart } from '@cart/models/cart.model';

export const loadProductSummarySuccess = createAction(
  '[Product Summary API] Product summary received succesfully',
  props<{ productSummary: ProductSummary }>()
);
export const loadProductSummaryFailure = createAction(
  '[Product Summary API] Error when receiving the product summary',
  props<{ error: { message: string } }>()
);

export const productCheckoutSuccess = createAction(
  '[Product Summary API] Product checkout completed succesfully',
  props<{ cartCheckout: CartCheckout; cart: Cart }>()
);

export const productCheckoutFailure = createAction(
  '[Product Summary API] Error on checkout',
  props<{ error: { message: string } }>()
);
