import { Injectable } from '@angular/core';
import { SEOService } from '@core/services';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { TranslateService } from '@ngx-translate/core';
import { languageEffectsActions } from '@store/actions';
import { concatMap, merge, Observable, startWith, tap } from 'rxjs';

@Injectable()
export class SeoEffects implements OnRunEffects {
  private readonly languageChanged$ = merge(
    this.translateService.onDefaultLangChange,
    this.translateService.onLangChange
  );

  readonly updateTitle$ = createEffect(
    () => {
      return merge(this.actions$.pipe(ofType(routerNavigatedAction)), this.languageChanged$).pipe(
        tap(() => this.seoService.updateTitle())
      );
    },
    { dispatch: false }
  );

  readonly updateMetaTags$ = createEffect(
    () => {
      return this.languageChanged$.pipe(
        startWith(null),
        tap(() => this.seoService.updateMetaTags())
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly translateService: TranslateService,
    private readonly seoService: SEOService
  ) {}

  ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    // run seo effects when application language is initialized
    return this.actions$.pipe(
      ofType(
        languageEffectsActions.appLanguageInitForUser,
        languageEffectsActions.appLanguageInitForVisitor
      ),
      concatMap(() => resolvedEffects$)
    );
  }
}
