import { paginationConfig } from '@app/shared/services/pagination/constants/pagination.config';
import { createEntityFeature, EntityFeatureState } from '@mkp/shared/util-state';
import { UnsubscribePageActions } from '@mkp/user/feature-unsubscribe/actions';
import { Action, createReducer, on } from '@ngrx/store';
import { languageEffectsActions } from '@store/actions';
import { User } from '@user/shared/models';
import { userApiActions, userDetailsActions, userListActions } from '@user/store/actions';
import { authApiActions } from '@mkp/auth/actions';

export interface State extends EntityFeatureState<User, { message: string }> {
  loggedInId: User['id'] | null;
  list: User['id'][];
  listSize: number;
}

const {
  featureKey,
  initialState: initialFeatState,
  updaters,
  selectors,
  adapter,
} = createEntityFeature<State, 'userState'>('userState');
const initialState: State = {
  ...initialFeatState,
  loggedInId: null,
  list: [],
  listSize: paginationConfig.initialLimit,
};

const _reducer = createReducer(
  { ...initialState },
  on(userListActions.reset, (state) => ({ ...state, list: state.list.slice(0, state.listSize) })),
  on(userApiActions.readLoggedInUserSuccess, (state, { user: entity }) => ({
    ...updaters.getOneSuccess(state, { entity }),
    loggedInId: entity.id,
  })),
  on(userApiActions.resetPasswordSuccess, updaters.setLoaded),
  on(
    userApiActions.listUsersFailure,
    userApiActions.loadMoreUsersFailure,
    userApiActions.updateUserFailure,
    userApiActions.createUserFailure,
    userApiActions.resetPasswordFailure,
    userApiActions.readLoggedInUserFailure,
    userApiActions.loadUsersByIdsFailure,
    updaters.setError
  ),
  on(userApiActions.listUsersSuccess, (state, { users, totalCount, _links, filter }) => ({
    ...updaters.getPaginatedListSuccess(state, { entities: users, totalCount, _links, filter }),
    list: removeDuplicates(users.map((user) => user.id)),
  })),
  on(userApiActions.loadMoreUsersSuccess, (state, { users, totalCount, _links, filter }) => ({
    ...updaters.getPaginatedListSuccess(state, { entities: users, totalCount, _links, filter }),
    list: removeDuplicates(state.list.concat(users.map((user) => user.id))),
  })),
  on(
    userListActions.init,
    userListActions.loadMore,
    userDetailsActions.updateUser,
    userDetailsActions.updateUserProfile,
    userDetailsActions.createUser,
    userDetailsActions.resetPassword,
    authApiActions.fetchAccessTokenSuccess,
    languageEffectsActions.appLanguageSwitchedForUser,
    UnsubscribePageActions.unsubscribeLoggedInUser,
    updaters.setLoading
  ),
  on(userApiActions.updateUserSuccess, (state, { id, changes }) =>
    updaters.updateSuccess(state, { updateEntity: { id, changes } })
  ),
  on(userApiActions.createUserSuccess, (state, { user }) =>
    updaters.createSuccess(state, { entity: user })
  ),
  on(userApiActions.loadUsersByIdsSuccess, (state, { users }) => {
    return adapter.upsertMany(users, { ...state });
  })
);

function reducer(state: State | undefined, action: Action): State {
  return _reducer(state, action);
}

export { featureKey, initialState, reducer, selectors };

const removeDuplicates = (array: string[]): string[] => Array.from(new Set(array));
