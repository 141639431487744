import { gql } from '@apollo/client/core';

export const ApiLinkFragments = {
  apiLink: gql`
    fragment apiLinkFields on ApiLink {
      href
      templated
      rel
      attributes {
        methods
        properties
      }
    }
  `,
};

export const CompanyApiLinkFragments = {
  companyProfileLinks: gql`
    fragment companyProfileLinksFields on CompanyApiLinks {
      self {
        ...apiLinkFields
      }
      update {
        ...apiLinkFields
      }
      list {
        ...apiLinkFields
      }
      createdBy {
        ...apiLinkFields
      }
      updatedBy {
        ...apiLinkFields
      }
    }
    ${ApiLinkFragments.apiLink}
  `,
  companyLinks: gql`
    fragment companyLinksFields on CompanyApiLinks {
      self {
        ...apiLinkFields
      }
      next {
        ...apiLinkFields
      }
      create {
        ...apiLinkFields
      }
    }
    ${ApiLinkFragments.apiLink}
  `,
};


