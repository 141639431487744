import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productAlternativeDays',
  standalone: true,
})
export class ProductAlternativeDaysPipe implements PipeTransform {
  transform(translatedString: string): string {
    return translatedString.split(' ').slice(0, 2).join(' ');
  }
}
