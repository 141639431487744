import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgFor } from '@angular/common';
import { SkeletonLoaderModule } from '@shared/modules/skeleton-loader/skeleton-loader.module';

@Component({
  selector: 'mkp-cards-skeleton',
  standalone: true,
  imports: [NgFor, SkeletonLoaderModule],
  templateUrl: './product-cards-skeleton.component.html',
  styleUrl: './product-cards-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardsSkeletonComponent {}
