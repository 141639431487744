export const FILTER_OPERATORS = {
  Equal: '==',
  NotEqual: '!=',
  Less: '<',
  LessOrEqual: '<=',
  Greater: '>',
  GreaterOrEqual: '>=',
  Contains: '=@',
  NotContains: '!@',
};

export const FILTER_COMBINE = {
  And: ';',
  Or: ',',
};
