import {
  createFeature,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { LoadingState } from '@mkp/shared/util-state';
import { authActions, authWorkspaceActions, boAuthActions } from '@mkp/auth/actions';

interface State {
  workspaceLoading: LoadingState;
}

const initialState: State = {
  workspaceLoading: LoadingState.INIT,
};

const featureKey = 'auth';

export const reducer = createReducer(
  initialState,
  on(authWorkspaceActions.workspaceResourceNotLoading, () => ({
    workspaceLoading: LoadingState.LOADED,
  })),
  on(authWorkspaceActions.workspaceResourceLoading, () => ({
    workspaceLoading: LoadingState.LOADING,
  })),
  on(authActions.workspaceSelected, boAuthActions.loadWorkspaceCompanyLoadSuccess, () => ({
    workspaceLoading: LoadingState.LOADED,
  })),
  on(
    authWorkspaceActions.workspaceResourceLoadingError,
    boAuthActions.loadWorkspaceCompanyLoadError,
    boAuthActions.loadWorkspaceAccountLoadError,
    boAuthActions.loadWorkspaceError,
    () => ({
      workspaceLoading: LoadingState.LOADED,
    })
  )
);

const feature = createFeature({
  name: featureKey,
  reducer,
});

const selectFeature = createFeatureSelector<State>(featureKey);
export const selectWorkspaceSelecting = createSelector(
  selectFeature,
  (state: State) => state.workspaceLoading
);
export const authFeature = { ...feature };
export { State as AuthState };
