import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Vacancy } from '@app/features/vacancy/models';
import {
  CreditRedemptionState,
  CreditRedemptionUtils,
  CreditRedemptionViewModel,
} from '@mkp/shared/data-access';
import { LabelComponent, LabelConfig } from '@mkp/shared/ui-library';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';

const labelTexts: { [key: string]: string } = {
  [CreditRedemptionState.Active]: 'CREDIT_REDEMPTION.ONLINE',
  [CreditRedemptionState.Inactive]: 'CREDIT_REDEMPTION.OFFLINE',
  [CreditRedemptionState.Expired]: 'CREDIT_REDEMPTION.OFFLINE',
  [CreditRedemptionState.Stopped]: 'CREDIT_REDEMPTION.OFFLINE',
  [CreditRedemptionState.Draft]: 'CREDIT_REDEMPTION.DRAFT',
  Imported: 'CREDIT_REDEMPTION.IMPORTED', // TODO: remove when we no longer have imported job ads - CDP-1098
};

const labelConfigs: { [key: string]: LabelConfig } = {
  [CreditRedemptionState.Active]: { color: 'green' },
  [CreditRedemptionState.Inactive]: { color: 'grey' },
  [CreditRedemptionState.Expired]: { color: 'grey' },
  [CreditRedemptionState.Stopped]: { color: 'grey' },
  [CreditRedemptionState.Draft]: { color: 'yellow' },
};

@Component({
  selector: 'mkp-credit-redeem-status',
  templateUrl: './credit-redeem-status.component.html',
  styleUrl: './credit-redeem-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslateModule, LabelComponent],
})
export class CreditRedemptionStatusComponent implements OnChanges {
  @Input() vacancy!: Vacancy;
  @Input() creditRedeem: CreditRedemptionViewModel | undefined;
  @Input() showCreditMessage = true;
  @Input() showLabelText = true;
  @Input() isWrapperFlex = false;

  private readonly translateService = inject(TranslateService);

  creditMessage: string | null = null;

  labelConfig = labelConfigs[CreditRedemptionState.Draft];
  labelText = labelTexts[CreditRedemptionState.Draft];

  ngOnChanges({ creditRedeem }: SimpleChanges) {
    if (creditRedeem) {
      this.creditMessage =
        shouldDisplayTimeleft(this.creditRedeem) || shouldDisplayLastOnline(this.creditRedeem)
          ? `${formatDate(this.creditRedeem.start)} - ${formatDate(
              this.creditRedeem.stoppedAt ?? this.creditRedeem.end
            )}`
          : this.translateService.instant(`CREDIT_REDEMPTION.CREATED_ON`, {
              day: format(new Date(this.vacancy.createdAt), 'dd.MM.yyyy'),
            });

      if (this.creditRedeem) {
        this.labelText =
          labelTexts[this.creditRedeem._state] ?? labelTexts[CreditRedemptionState.Draft];
        this.labelConfig =
          labelConfigs[this.creditRedeem._state] ?? labelConfigs[CreditRedemptionState.Draft];
      } else {
        // TODO: remove when we no longer have imported job ads - CDP-1098
        this.labelText = shouldLabelAsDraftOrImported(this.vacancy);
      }
    }
  }
}

const shouldDisplayTimeleft = (
  credit: CreditRedemptionViewModel | undefined
): credit is CreditRedemptionViewModel =>
  CreditRedemptionUtils.isActive(credit) && !!credit && credit.information.timeLeft != null;

const shouldDisplayLastOnline = (
  credit: CreditRedemptionViewModel | undefined
): credit is CreditRedemptionViewModel =>
  !!credit &&
  !CreditRedemptionUtils.isActive(credit) &&
  credit.start != null &&
  (credit.end != null || credit.stoppedAt != null);

const formatDate = (dateString: string | null) =>
  dateString ? format(new Date(dateString), 'dd.MM.yyyy') : null;

// TODO: remove when we no longer have imported job ads - CDP-1098
const shouldLabelAsDraftOrImported = (vacancy: Vacancy): string =>
  CreditRedemptionUtils.isImported(vacancy)
    ? labelTexts['Imported']
    : labelTexts[CreditRedemptionState.Draft];
