import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { ProgressBarService } from '@core/services';
import { filter, Observable, take, tap } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsDefault } from '@mkp/debug/state';

export class ProgressInterceptor implements HttpInterceptor {
  private requests: HttpRequest<unknown>[] = [];

  private readonly progressBarService = inject(ProgressBarService);
  private readonly store = inject(Store);

  removeRequest(req: HttpRequest<unknown>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    this.progressBarService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requests.push(req);

    this.log(req);

    this.progressBarService.isLoading.next(true);
    // set to false if issue
    setTimeout(() => {
      this.progressBarService.isLoading.next(false);
    }, 3000);

    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        error: (err: unknown) => {
          this.removeRequest(req);
          observer.error(err);
        },
        complete: () => {
          this.removeRequest(req);
          observer.complete();
        },
      });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  private log(req: HttpRequest<unknown>) {
    this.store
      .select(selectIsDefault)
      .pipe(
        take(1),
        filter((isDefault) => isDefault),
        tap(() => {
          console.log('Request--->', req);
          console.log('No of requests--->', this.requests.length);
        })
      )
      .subscribe();
  }
}
