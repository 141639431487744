import { Account } from '@mkp/account/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const accountApiActions = createActionGroup({
  source: 'Account/API',
  events: {
    'Load Account Success': props<{ account: Account }>(),
    'Load Account Failure': props<{ error: { message: string } }>(),
    'Load Accounts Success': props<{ accounts: Account[] }>(),
    'Load Accounts Failure': props<{ error: { message: string } }>(),
    'Load Account By Legal Entity External Id Success': props<{
      account: Account;
      selectedAccountId: string;
    }>(),
    'Load Account By Legal Entity External Id Failure': props<{ error: { message: string } }>(),
    'Add Account': props<Pick<Account, 'legalEntityId' | 'name'>>(),
    'Add Account Success': props<{ account: Account }>(),
    'Add Account Failure': props<{ error: { message: string } }>(),
    'Update Account': props<Pick<Account, 'name'>>(),
    'Update Account Success': props<{ account: Account }>(),
    'Update Account Failure': props<{ error: { message: string } }>(),
    'Load User Accounts': emptyProps(),
    'Load User Accounts Success': props<{ accounts: Account[] }>(),
    'Load User Accounts Failure': props<{ error: { message: string } }>(),
  },
});
